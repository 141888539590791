<template>
  <ToastTip />

  <Dialog header="SignIn Error" v-model:visible="showErrorDialog" :style="{width: '55vw'}">
      <div class="message"> {{errorMessage}} </div>

      <template #footer>
         <div v-if="redirectToRegister">
            <Button label="Sign Me Up!" icon="pi pi-check" v-on:click="redirectUser" autofocus />
         </div>
         <div v-else>
            <Button label="Close" icon="pi pi-check" v-on:click="showErrorDialog = false" autofocus />
         </div>
      </template>
  </Dialog>

  <div class="page">
     <div class="container container-column container-center container-full">
        <div class="card" style="margin-top: 40px">
           <h1>Sign In Using Your Email</h1>
           <div class="container container-column">
              <div class="container">
                 <label for="email" class="input-label required" v-tooltip="'Required Field'">Email Addresss</label>
                 <span class="p-input-icon-left">
                    <i class="pi pi-at" />
                    <InputText id="email" type="text" v-tooltip="'Use this form if you regstered with your personal email.'" v-model="email" />
                 </span>
              </div>
   
              <div class="container">
                 <label for="password" class="input-label required" v-tooltip="'Required Field'">Password</label>
                 <span class="p-input-icon-left">
                    <i class="pi pi-key" />
                    <InputText id="password" type="password" v-tooltip="'Provide the password you provided when you created an account with us.'" v-model="password" />
                 </span>
              </div>
      
              <div class="container">
                 <Button @click="signIn" :disabled="disabled">Submit</Button>
              </div>
           </div>
        </div>
   
        <div class="container container-center container-full">
           <div class="or">Or</div>
        </div>
   
        <div class="card">
           <h1>Sign In Using Your Social Media Account</h1>
           <div class="container">
              <Button class="google p-0" aria-label="Google" v-on:click="signInWithGoogle" v-tooltip="'Use this if you registered using your GMail email.'">
                 <i class="pi pi-google px-2"></i>
                 <span class="px-3">Google</span>
              </Button>
              <Button class="facebook p-0" aria-label="Facebook" v-on:click="signInWithFacebook" v-tooltip="'Use this if you registered using your Facebook account.'">
                 <i class="pi pi-facebook px-2"></i>
                 <span class="px-3">Facebook</span>
              </Button>
           </div>
        </div>
     </div>
  </div>
</template>

<script setup>
   import { ref, onMounted, computed } from 'vue'
   import { getAuth, signInWithEmailAndPassword, FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
   import { getDownloadURL, getStorage, ref as storageRef } from "firebase/storage";
   import { useRouter } from 'vue-router'
   import { userProfileStore } from '@/stores/profile';
   import { loginStatusStore } from '@/stores/login.js'
   import { useToast } from "primevue/usetoast";
   import { RestGet } from '@/plugins/webapi';

   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import Dialog from 'primevue/dialog';

   const storage = getStorage();
   const profile = userProfileStore();
   const errorMessage = ref('')
   const email = ref('');
   const password = ref('');
   const router = useRouter() // get a reference to our vue router
   const toast = useToast();
   const showErrorDialog = ref (false);
   const redirectToRegister = ref (false);

   onMounted (() =>
   {
      profile.clear();
      loginStatusStore.logginIn = false;
      loginStatusStore.isAdmin = false;
   })
 
   //
   // Disables the submit button
   //
   const disabled = computed(() => !email.value || !password.value);
 
   //
   // This shows the error toast.
   //
   const showErrorToast = function (summary, message) 
   {
       showToast ("error", summary, message);
   }

   const showToast = (severity, summary, message) => { toast.add ({severity: severity, summary: summary, detail:message, life: 5000}); }

   const redirectUser = function ()
   {
      showErrorDialog.value = false;

      router.push ("/register");
   }
 
   //
   //
   //
   const checkLoginStatusCode = function (error)
   {
      switch (error.code)
      {
        case 'auth/account-exists-with-different-credential':
            errorMessage.value = 'Account exists with different credntials'
            break
        case 'auth/invalid-email':
            errorMessage.value = 'Invalid email format.'
            break
        case 'auth/user-not-found':
            errorMessage.value = 'It appears you have not registered with us yet. Would you like to?'
            redirectToRegister.value = true;
            break
        case 'auth/wrong-password':
            errorMessage.value = 'Email or password was incorrect, or you used another method to register with us.'
            break
        case 'auth/user-disabled':
            errorMessage.value = 'Your account has been disabled. Please contact us.'
            break
        case 'auth/internal-error':
            console.error ("Unknown error: ", error);
            errorMessage.value = "There appears to be an issue with the authentication server."
            break;
        default:
            console.error ("Unknown error: ", error);
            errorMessage.value = 'Unknown error. Please contact us.'
            break
      }
 
      showErrorDialog.value = true;
 
      console.error (error);
   }
 
   //
   // https://www.geeksforgeeks.org/how-to-send-a-user-verification-mail-in-web-and-firebase/
   //
   const signIn = function ()
   {
      const auth = getAuth();
      signInWithEmailAndPassword (auth, email.value, password.value).then (function (result)
      {
         getUserDetails (result);
      })
      .catch (function (error)
      {
         checkLoginStatusCode (error);
      });
   };
 
   const signInWithFacebook = function ()
   {
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
 
      signInWithPopup (auth, provider).then (function (result)
      {
         getUserDetails (result);
      })
      .catch (function (error)
      {
         checkLoginStatusCode (error);
      });
   };
 
   const signInWithGoogle = function ()
   {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
 
      signInWithPopup (auth, provider).then (function (result)
      {
         getUserDetails (result);
      })
      .catch (function (error)
      {
         checkLoginStatusCode (error);
      });
   };
 
   const getUserDetails = function (user_profile)
   {
      var token = user_profile.user.accessToken;
 
      profile.clear();
 
      var url = "/customer/email/" + encodeURIComponent (user_profile.user.email);
      RestGet (url, token, {}, function (status, response)
      {
         if (status.error)
         {
            loginStatusStore.loggedIn = false;
            loginStatusStore.isAdmin = false;

            showErrorToast ("Authentication Error", "Could not talk to server. Try again later");
            return;
         }

         //
         // We have logged into firebase and gotten the user information.
         //
         loginStatusStore.loggedIn = true;

         if (status.ok)
         {
            var path = null;
            var avatarStorageRef = null;
 
            //
            // No data back, they need to be onboarded.
            //
            if (response && Object.keys(response).length === 0)
            {
               profile.create();

               profile.add ("token", token);
               profile.add ("uid", user_profile.user.uid);
               profile.add ("first_name", user_profile.user.first_name);
               profile.add ("last_name", user_profile.user.last_name);
               profile.add ("email", user_profile.user.email);
               profile.add ("refresh_token", user_profile.user.stsTokenManager.refreshToken);

               //
               // If there is a photoURL, they signed in via a social media
               // account and the payload provided the URL.
               //
               if (user_profile.user['photoURL'])
               {
                  profile.add ("avatar", user_profile.user.photoURL);
                  router.push ("/onboard");
               }
               else
               {
                  //
                  // Go fetch the default logo.
                  //
                  path = "/images/avatar.png";
                  avatarStorageRef = storageRef(storage, path)
                  getDownloadURL (avatarStorageRef, path).then ((url) =>
                  {
                     profile.add ("avatar", url);
                     router.push ("/onboard");
                  });
               }

               return;
            }

            //
            // Set up the profile.
            //
            var data = response[0];
            profile.set (data);
            profile.add ("token", token);
            profile.add ("uid", user_profile.user.uid);
            profile.add ("refresh_token", user_profile.user.stsTokenManager.refreshToken);
            loginStatusStore.isAdmin = profile.isAdmin();

console.log ("IS ADMIN: ");
profile.dump();

            if (user_profile.user.photoURL)
            {
               profile.add ("photoURL", user_profile.user.photoURL);
            }

            //
            // The users avatar: 
            //     1: If they uploaded an avatar, use that.
            //     2: If there is a photoURL in their social media account, use that.
            //     3: Otherwise, default to the default avatar
            //
            path = "/images/" + user_profile.user.uid + "/avatar";
            avatarStorageRef = storageRef(storage, path)
            getDownloadURL (avatarStorageRef, path).then ((url) =>
            {
               profile.add ("avatar", url);

               router.push ("/home?fresh=1");
               return;
            })
            .catch(() =>
            {
               //
               // If there is a social media photo, use that.
               //
               if (user_profile.user['photoURL'])
               {
                  profile.add ("avatar", user_profile.user.photoURL);

                  loginStatusStore.isAdmin = profile.isAdmin();

                  router.push ("/home?fresh=1");
                  return;
               }

               //
               // Otherwise, load the default avatar.
               //
               path = "/images/avatar.png";
               avatarStorageRef = storageRef(storage, path)
               getDownloadURL (avatarStorageRef, path).then ((url) =>
               {
                  profile.add ("avatar", url);

                  router.push ("/home?fresh=1");
                  return;
               });
            });

            return;
         }
      });
   }
</script>

<style scoped>
.required {color: red;}

.or
{
   text-align: center;
   font-size: 1.1em;
   font-weight: bold;
   font-variant: small-caps;
   margin-top: 20px;
   margin-bottom: 30px;
   display: flex;
   flex-direction: row;
   width: 50%;
}
.or:before, .or:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--header-border-color);
  margin: auto;
}
.or:before {
  margin-right: 15px
}
.or:after {
  margin-left: 15px
}

.p-button
{
   margin-right: 10px;
}
.p-button i
{
   line-height: 2.25rem;
}
.p-button.google
{
   background: linear-gradient(to left, var(--purple-600) 50%, var(--purple-700) 50%);
   background-size: 200% 100%;
   background-position: right bottom;
   transition: background-position 0.5s ease-out;
   color: #fff;
   border: 1px solid var(--purple-900);
}
.p-button.google:hover
{
   background-position: left bottom;
   background: var(--purple-700);
   box-shadow: 2px 2px 5px var(--blacke-400);
}
.p-button.google i {
    background-color: var(--purple-700);
}
.p-button.google:focus {
    box-shadow: 2px 2px 5px var(--purple-400);
}

.p-button.facebook
{
    background: linear-gradient(to left, var(--indigo-600) 50%, var(--indigo-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--indigo-800);
}
.p-button.facebook:hover
{
    background-position: left bottom;
    background: var(--indigo-700);
}
.p-button.facebook i
{
    background-color: var(--indigo-700);
}
.p-button.facebook:focus
{
    box-shadow: 0 0 0 1px var(--indigo-400);
}
.p-button.twitter
{
    background: linear-gradient(to left, var(--blue-400) 50%, var(--blue-500) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--blue-700);
}
.p-button.twitter:hover
{
    background-position: left bottom;
    background: var(--blue-500);
}
.social-media .p-button.twitter i
{
    background-color: var(--blue-700);
}
.p-button.twitter:focus
{
    box-shadow: 0 3 3 3px var(--blue-200);
}

.message
{
   white-space: pre-line;
   font-size: 1.3em;
}

</style>
