<template>
   <Dialog :header="showMore.header" v-model:visible="showMore.visible" style="width: 50vw;">
      <div class="show-more-text">{{showMore.body}}</div>

      <div v-if="showMore.attributes">
         <hr />
         <div class="show-more-attribute-header">Ingredient Properties</div>
         <div class="container container-left">
            <div v-for="(attr, idx) in showMore.attributes" v-bind:key="idx" class="show-more-attribute">
               {{attr.name}}
            </div>
         </div>
      </div>
   </Dialog>

   <div class="card card-full">
      <div class="container container-column container-align-top container-full">
         <div class="container container-column container-full">
            <div class="container container-left container-grow">
               <div class="container container-left container-grow">
                  <ShowRecipe :product="props.product" />
               </div>

               <span class="container container-left container-grow">
                  <span v-if="props.ingredient_type && props.ingredient_type.description" class="ingredient-tooltip">
                     <span class="ingredient-tooltip-text">
                        {{props.ingredient_type.tooltip}}
                        <span class="ingredient-tooltip-button" v-on:click="showIngredientTypeDetails">More...</span>
                     </span>
                  </span>
               </span>

               <div class="container container-center container-grow">
                  <Button class="p-button-text call-to-action-button" v-on:click="selected" :disabled="!enableNextStep">
                  <span class="next-step-text">Next Step &nbsp; <i class="fa-solid fa-circle-play"></i></span>
                  </Button>
               </div>
            </div>
         </div>
         <hr />

         <div class="container container-row container-full">
            <div class="container container-left container-grow">
               <IngredientFilter v-if="ingredient_list && ingredient_list.length > 0" label="Properties" :ingredients="ingredient_list" v-on:change="filterIngredients" on />
            </div>

            <div class="container container-right container-grow">
               <div class="min-max-message">
                  Select between {{props.stage.minimum_selections}} and {{props.stage.maximum_selections}} ingredients.
               </div>
            </div>
         </div>

         <div class="container container-column-wrap">
            <DataTable dataKey="id" :value="ingredients" responsiveLayout="scroll" v-model:filters="filters" filterDisplay="menu" :globalFilterFields="['premium']" rowHover >
               <Column field="name" header="Name">
                  <template #body="{data}">{{data.name}}</template>
               </Column>

               <Column field="description" header="Description">
                  <template #body="{data}">{{data.tooltip}}</template>
               </Column>

               <Column field="ingredient_subtype_id" header="Ingredient Type" :showFilterMatchModes="false">
                  <template #body="{data}">{{data.ingredient_subtype_name}}</template>
                  <template #filter="{filterModel}">
                     <Dropdown :options="subtypes" v-model="filterModel.value" placeholder="Select a Type" optionLabel="name" optionValue="id" />
                  </template>
               </Column>

               <Column field="premium" header="Premium" :showFilterMatchModes="false">
                  <template #body="{data}">{{data.premium_string}}</template>
                  <template #filter="{filterModel}">
                     <div class="container container-column">
                        <div class="container container-row container-left">
                           <RadioButton v-model="filterModel.value" value="1" />
                           <div class="filter-label">Show Premium Products Only</div>
                        </div>
               
                        <div class="container container-row container-left">
                           <RadioButton v-model="filterModel.value" value="0" />
                           <div class="filter-label">Show Regular Products Only</div>
                        </div>
                     </div>
                  </template>
               </Column>

               <Column v-for="col of sizes" :field="col.field" :header="col.header" :key="col.field"></Column>

               <Column field="actions" header="Actions">
                  <template #body="{data}">
                     <div class="container container-left">
                        <Button class="p-button-rounded action-icon" v-on:click="showIngredientDetails (data)">
                           <i class="fa-solid fa-magnifying-glass"></i>
                        </Button>

                        <Checkbox v-model="data.checked" :binary="true" v-on:click="checkMinMax (data)" :disabled="(checked >= props.stage.maximum_selections && !data.checked) || data.is_required_bool"/>
                     </div>
                  </template>
               </Column>
            </DataTable>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, defineEmits, defineProps, onMounted } from 'vue';
   import { RestGet } from '@/plugins/webapi';
   import { useRouter } from 'vue-router'
   import { recipeStore } from '@/stores/recipe';
   import { userProfileStore } from '@/stores/profile';

   import IngredientFilter from "./IngredientFilter";
   import ShowRecipe from "./ShowRecipe";
   import Button from 'primevue/button';
   import DataTable from 'primevue/datatable';
   import {FilterMatchMode } from 'primevue/api';
   import Dialog from 'primevue/dialog';
   import Column from 'primevue/column';
   import Dropdown from 'primevue/dropdown';
   import Checkbox from 'primevue/checkbox';
   import RadioButton from 'primevue/radiobutton';

   const profile = userProfileStore();
   const recipe = recipeStore();
   const router = useRouter();
   const sizes = ref ();
   const enableNextStep = ref (false);
   const ingredient_list = ref([]);
   const ingredients = ref([]);
   const showMore = ref ({visible: false})
   const checked = ref (0);
   const subtypes = ref ([]);

   const filters = ref({'display': {value: true, matchMode: FilterMatchMode.EQUALS},
                        'premium': {value: null, matchMode: FilterMatchMode.EQUALS},
                        'ingredient_subtype_id': {value: null, matchMode: FilterMatchMode.EQUALS}});

   //
   // This reads properties sent to us. In this case "inactive" will turn off all the items
   // We do this during onboarding and ordering.
   //
   const props = defineProps ({ingredient_type: Object, product: Object, product_sizes: Object, stage: Object});

   const emit = defineEmits(['next']);
   const selected = function ()
   {
      // XXXXX NOW WE NEED TO FIND ALL THE SELECTED ITEMS and emit them.
      var selectedIngredients = [];

      for (var x in ingredients.value)
      {
         if (ingredients.value[x].checked)
         {
            selectedIngredients.push (ingredients.value[x]);
         }
      }

      emit('next', props.stage, selectedIngredients);
   }

   const checkMinMax = function (record)
   {
      checked.value = 0;
      for (var x= 0; x < ingredients.value.length; x++)
      {
         if (ingredients.value[x].checked)
         {
            checked.value++;
         }
      }

      //
      // OK, this method gets called when someone checks the box, but before the variable is altered.
      // This means when the record,checked is true, they are turning off the checkbox, therefore we need to 
      // decrement. Alternativey, the opposite must be managed.
      //
      if (record.checked)
      {
         checked.value--;
      }
      else
      {
         checked.value++;
      }

      enableNextStep.value = false;
      if (checked.value >= props.stage.minimum_selections)
      {
         enableNextStep.value = true;
      }
   }

   onMounted (() =>
   {
      getIngredientList();
      setDataColumns();
   })

   //
   // This cycles through an array and looks for a value and returns the key
   //
   const getKeyByValue = function (object, value)
   {
      if (object)
      {
         for (var obj of object)
         {
            var key = Object.keys(obj).find(key => obj[key] === value);
            if (key)
            {
               return key;
            }
         }
      }
      return null;
   }

   const setDataColumns = function()
   {
      var fields = [{header: "Small", field: "small_cost"},
                    {header: "Regular", field: "regular_cost"},
                    {header: "Large", field: "large_cost"}];

      sizes.value = [];
      for (var size of fields)
      {
         if (getKeyByValue (props.product_sizes, size.header))
         {
            sizes.value.push (size);
         }
      }
   }

   const showIngredientTypeDetails = function ()
   {
      showMore.value.visible = true;
      showMore.value.header = props.ingredient_type.name;
      showMore.value.body = props.ingredient_type.description;
   }

   const showIngredientDetails = function (record)
   {
      showMore.value.visible = true;
      showMore.value.header = record.name;
      showMore.value.body = record.description;
      showMore.value.attributes = record.attribute_list;

      if (record.premium == 1)
      {
         showMore.value.header = record.name + " (Premium Product)";
      }
   }

   const filterIngredients = function(list)
   {
      //
      // Each record has a feild called 'display' and when set to false, the data table will not
      // display the record as per the filters definition above.
      // In list provided in the function call, lists all the attributes which are to be shown.
      // In each ingredient record, the record has an attribute list array of attributes for that record.
      // We need to set all the ingredients display field to false, then go through each list item looking for
      // record with that attribute. We find it, we turn on the display flag.
      //
      for (var rec in ingredients.value)
      {
         ingredients.value[rec].display = false;

         for (var item in list)
         {
            if (list[item])
            {
               for (var attr in ingredients.value[rec].attribute_list)
               {
                  if (ingredients.value[rec].attribute_list[attr].id  == item)
                  {
                     ingredients.value[rec].display = true;
                     break;
                  }
               }
            }
         }
      }
   }

   const getIngredientList = function ()
   {
      ingredients.value = [];

      var uri = "/ingredient/type/" + props.ingredient_type.id + "/product/" + props.product.id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredients.value = response;
            var tmp = {};

            checked.value = 0;
            for (var x = 0; x < ingredients.value.length; x++)
            {
               ingredients.value[x].display = true;
               ingredients.value[x].premium_string = ingredients.value[x].premium == 0 ? "No" : "Yes";
               ingredients.value[x].small_cost = "--";
               ingredients.value[x].regular_cost = "--";
               ingredients.value[x].large_cost = "--";

               ingredients.value[x].is_required_bool = false;
               if (ingredients.value[x].is_required)
               {
                  ingredients.value[x].is_required_bool = true;
                  ingredients.value[x].checked = true;
                  checked.value++;
               }

               tmp[ingredients.value[x].ingredient_subtype_name] = ingredients.value[x].ingredient_subtype_id;

               //
               // Complicated part. The cost is calculated as:
               //
               // Product Volume * Ingredient Type (%) * Ingredient Cost
               // ALL VOLUMES NEED TO BE CALCULATED IN OUNCES.
               //
               for (var z = 0; z < props.product_sizes.length; z++)
               {
                  //
                  // Convert ml to ounces to normalize.
                  //
                  var volume = props.product_sizes[z].volume;
                  if (props.product_sizes[z].measure != "oz")
                  {
                      volume = props.product_sizes[z].volume * 0.033814;
                  }

                  var cost = volume * (props.stage.percent/100) * ingredients.value[x].price;

                  if (props.product_sizes[z].name == "Small")
                  {
                     ingredients.value[x].small_cost = cost.toFixed (2);
                  }
                  else if (props.product_sizes[z].name == "Regular")
                  {
                     ingredients.value[x].regular_cost = cost.toFixed (2);
                  }
                  else if (props.product_sizes[z].name == "Large")
                  {
                     ingredients.value[x].large_cost = cost.toFixed (2);
                  }
               }
            }

            for (var w in tmp)
            {
               subtypes.value.push ({id: tmp[w], name: w});
            }

            if (ingredient_list.value.length == 0)
            {
               ingredient_list.value = ingredients.value;
            }

            //
            // Fetch the recipe and see if this stage has values. If so, set the checkmarks.
            //
            var rec = recipe.get();
            if (rec && rec.ingredients.length > 0)
            {
               for (var idx in rec.ingredients)
               {
                  if (rec.ingredients[idx].ingredient_type_code === props.ingredient_type.code)
                  {
                     for (x in rec.ingredients[idx].ingredients)
                     {
                        var ing = rec.ingredients[idx].ingredients[x];
                        for (var y = 0; y < ingredients.value.length; y++)
                        {
                           if (ing.id == ingredients.value[y].id)
                           {
                              ingredients.value[y].checked = true;
                              checked.value++;
                              break;
                           }
                        }
                     }
                  }
               }
            }

            enableNextStep.value = false;
            if (checked.value >= props.stage.minimum_selections)
            {
               enableNextStep.value = true;
            }

            //
            // If the number of checked fileds == the number of ingredients, it means the
            // user does not have the opportunity to select ingredients, so we'll jump to the
            // next page by calling the 'selected' function defined above.
            //
            if (checked.value == ingredients.value.length)
            {
               selected();
            }
         }
      });
   }

</script>

<style scoped>
   .ingredient-card
   {
      max-width: 250px;
      display: flex;
      border-radius: 10px;
      border: 1px solid var(--input-label-border-color);
      padding: 3px 10px 3px 10px;
      margin: 3px 10px 3px 10px;
   }
   .ingredient-card {font-size: .7em; }
   .ingredient-label
   {
      padding: 2px 20px 2px 20px;
      margin: 2px 5px 2px 10px;
      color: blue;
      font-weight: bold;
      font-size: 1.1em;
      color: var(--title-color);
      border-radius: 10px;
   }
   .ingredient-label:hover { background:rgba(0,0,0,0.3); }
   .ingredient-description { font-size: .8em; }

   .show-more-attribute-header
   {
      margin: 15px 5px 5px 5px;
      font-size: 1.1em;
      font-weight: bold;
      text-shadow: 2px 2px 3px #111111;
      font-variant: small-caps;
   }

   .show-more-attribute
   {
      width: auto;
      height: 25px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 8px 15px 0px 15px;
      padding: 2px 5px 2px 5px;
      text-align: center;
      font-size: 1em;
      font-weight: bold;
      text-shadow: 2px 2px 3px #111111;
   }
   .show-more-attribute:hover { background: rgba(84,80,152,.8); }

   .show-more-text
   {
      white-space: pre-line;
      font-size: 1.1em;
   }

   .ingredient-tooltip-button
   {
      width: 60px;
      height: 20px;
      border: 2px solid green;
      border-radius: 10px;
      padding: 2px 5px 2px 5px;
      font-size: .7em;
      font-weight: bold;
      margin-left: 5px;
   }
   .ingredient-tooltip-button:hover
   {
      background:rgba(0,0,0,0.3);
      cursor: pointer;
   }

   .min-max-message
   {
      max-width: 150px;
      width: 150px;
      padding: 2px 5px 2px 5px;
      text-align: right;
      font-size: .7em;
      font-weight: bold;
      margin-left: 5px;
      text-shadow: 2px 2px 4px rgba(0,0,0,.8);
   }

   .filter-label
   {
      margin: 2px 0px 2px 10px;
      font-weight: bold;
   }

   .ingredient-tooltip
   {
      display: flex;
      flex-direction: row;
   }
   .ingredient-tooltip-text
   {
      font-size: .8em;
      font-weight: bold;
   }

   .next-step-text { font-size: .7em !important; width: 120px; }

   .p-datatable
   {
      padding: .5rem;
      text-align: left;
      font-size: .8em;
      width: 100%;
   }

   .p-button-text
   {
      height: 35px;
      background: green;
      color: #FFFFFF;
      font-size: .8em;
      text-shadow: 2px 2px 2px #001200;
      box-shadow: 2px 2px 5px #000000;
   }
   .p-button-text:hover
   {
      background: #004E00 !important;
      color: #FFFFFF !important;
   }

   .action-icon
   {
      text-align: center;
      max-height: 28px;
      height: 28px;
      width: 28px;
      padding: 0px; 
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
   }
</style>
