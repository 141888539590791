<template>
   <ToastTip />

   <!-- This allows us to select an ingredient image -->
   <Dialog position="top" :header="header" v-model:visible="showImageDialog" :modal="true" dismissableMask :style="{width: '50vw'}">
      <div class="container container-full container-column">
         <div class="container container-full container-row">
            <div class="container container-column container-30">
               <div class="container icon-text-header">Current Icon</div>
               <div class="recipe-navigation-button">
                  <div class="recipe-navigation-button-icon"><img :src="default_icon"></div>
               </div>
               <div class="container icon-text-header">Selected Icon</div>
               <div v-if="selected_icon" class="recipe-navigation-button">
                  <div class="recipe-navigation-button-icon"><img :src="selected_icon"></div>
               </div>
            </div>
         
            <div class="container container-60">
               <IconSelector v-on:change="iconSelected" :icon="record.icon" :default="default_icon_list" no_header />
            </div>
         </div>

         <div class="container container-right">
            <Button label="Close" icon="pi pi-times" @click="closeImageDialog" class="p-button-text"/>
            <Button label="Save" :class="dialogClass" icon="pi pi-save" @click="saveIngredientImage" :disabled="!saveImageButton" autofocus />
         </div>
      </div>
   </Dialog>

   <!-- This edits an ingredient attributes. -->
   <Dialog position="top" :header="header" v-model:visible="showAttributeDialog" :modal="true" dismissableMask :style="{width: '50vw'}">

      <div class="container container-left container-column-wrap">
         <div v-for="(record, idx) in attributes" v-bind:key="idx">
             <ToggleButton v-model="attribute_mapping[record.id]" :onLabel="record.name" :offLabel="record.name" onIcon="pi pi-check" offIcon="pi pi-times" />
         </div>
      </div>

      <div class="container container-right">
         <Button label="Close" icon="pi pi-times" @click="closeAttributeDialog" class="p-button-text"/>
         <Button label="Save" :class="dialogClass" icon="pi pi-save" @click="saveIngredientAttributes" autofocus />
      </div>
   </Dialog>

   <!-- This edits ingredient details. -->
   <Dialog position="top" :header="header" v-model:visible="displayEdit" :modal="true" dismissableMask :style="{width: '60vw'}">
      <div class="container container-left">
         <div class="input-label">Name {{record.ingredient_type_id}}</div>
         <InputText id="name" type="text" size="55" v-model="record.name" :disabled="disabled" />
      </div>

      <div v-if="record.id" class="container container-left">
         <div class="input-label">Ingredient ID</div>
         <InputText id="name" type="text" size="5" v-model="record.id" disabled />
      </div>

      <div class="container container-left">
         <div class="input-label">Ingredient Type</div>
         <Dropdown v-model="record.ingredient_type_id" v-on:change="updateSubtpes (record)" :options="ingredient_types" optionLabel="name" optionValue="id" placeholder="Select an Ingredient Type" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Ingredient Purpose</div>
         <InputText id="purpose" type="text" style="width: 15em" v-model="record.purpose" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Ingedient Subtype</div>
         <Dropdown v-model="record.ingredient_subtype_id" :options="subtypes" optionLabel="name" optionValue="id" placeholder="Select" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Ingedient In Stock</div>
         <ToggleButton v-model="record.is_available_bool" onIcon="pi pi-check" offIcon="pi pi-times" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label" v-tooltip="'Public Facing means this product is something a client will see. When set to no, this ingredient will be only see by the formualtor.'">Public Facing</div>
         <ToggleButton v-model="record.is_public_bool" onIcon="pi pi-check" offIcon="pi pi-times" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Premium</div>
         <ToggleButton v-model="record.is_premium_bool" onIcon="pi pi-check" offIcon="pi pi-times" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Cost ($.00)</div>
         <InputText id="cost" type="number" step="0.01" style="width: 8em" v-model="record.cost" v-on:blur="calcCosts" disabled />
         <div class="input-label" style="width:280px;">Calculated from Inventory</div>
      </div>

      <div class="container container-left">
         <div class="input-label">Volume (ml)</div>
         <InputText id="volume" type="number" step="0.01" style="width: 8em" v-model="record.volume" v-on:blur="calcCosts" disabled />
         <div class="input-label" style="width:140px">Cost (ml)&nbsp;${{cost_per_ml}}</div>
         <div class="input-label" style="width:140px">Cost (oz)&nbsp;${{cost_per_oz}}</div>
      </div>

      <div class="container container-left">
         <div class="input-label">Customer Price per (oz)</div>
         <InputText ref="price" id="price" type="number" step="0.01" style="width: 8em;" v-model="record.price" v-on:blur="verifyPrice" v-tooltip="priceTooltip" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Tooltip</div>
         <InputText id="tooltip" type="text" size="50" v-model="record.tooltip" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Description</div>
         <Textarea id="description" v-model="record.description" :autoResize="true" rows="5" cols="53" :disabled="disabled"  />
      </div>

      <template #footer>
         <Button label="Close" icon="pi pi-times" @click="closeDialog" class="p-button-text"/>
         <Button :label="dialogLabel" :class="dialogClass" icon="pi pi-save" @click="saveData" autofocus :disabled="saveDisabled" />
      </template>
   </Dialog>

   <!-- This edits ingredient / product mapping. -->
   <Dialog position="top" :header="header" v-model:visible="displayMapping" :modal="true" dismissableMask :style="{width: '80vw'}">

      <div class="container container-column-wrap">
         <div v-for="(record, idx) in products" :key="idx" class="container container-row container-box-30 container-left container-align-center">
            <ToggleButton v-model="mapping[record.id]" :onLabel="record.name" :offLabel="record.name" onIcon="pi pi-check" offIcon="pi pi-times" disabled />
         </div>
      </div>

      <template #footer>
         <Button label="Close" icon="pi pi-times" @click="closeMappingDialog" class="p-button-text"/>
      </template>
   </Dialog>

   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
         <div class="container container-left">
            <Button @click="$router.go(-1)" class="p-button-sm back-button">
               <i class="fa-solid fa-circle-left"></i>&nbsp;&nbsp;Back
            </Button>
            <h1>Manage {{ingredient_type_name}}</h1>
            <div @click="openEditDialog ('new', 0)" class="call-to-action-button">
               Add Ingredient <i class="fa-solid fa-square-plus"></i>
            </div>
         </div>
    
         <DataTable :value="ingredients" responsiveLayout="scroll" v-model:filters="filters" dataKey="id">
             <Column field="name" header="Name" sortable style="min-width: 8rem">
                <template #body="{data}">{{data.name}}</template>
             </Column>

             <Column field="name" header="Count" sortable>
                <template #body="{data}">{{data.count}}</template>
             </Column>
    
             <Column field="name" header="Available" sortable>
                <template #body="{data}">{{data.is_available_string}}</template>
             </Column>
    
             <Column field="name" header="Premium" sortable>
                <template #body="{data}">{{data.is_premium_string}}</template>
             </Column>
    
             <Column field="description" header="Description">
                <template #body="{data}">{{data.description}}</template>
             </Column>
    
             <Column field="actions" header="Actions">
                <template #body="{data}">
                   <div class="container container-left">
                      <Button class="p-button-rounded action-icon" @click="openEditDialog ('edit', data.id)" v-tooltip.top="'Edit Ingredient'"><i class="fa-solid fa-cog"></i></Button>
                      <Button class="p-button-rounded action-icon" @click="openMappingDialog (data.id)" v-tooltip.top="'View Ingredient to Product Map'"><i class="fa-solid fa-location-dot"></i></Button>
                      <Button class="p-button-rounded action-icon" @click="openAttributeDialog (data)" v-tooltip.top="'Assign Attributes'"><i class="fa-solid fa-square-plus"></i></Button>
                      <Button class="p-button-rounded action-icon" @click="editInventory (data)" v-tooltip.top="'Edit Inventory'"><i class="fa-solid fa-cart-flatbed"></i></Button>
                      <Button class="p-button-rounded action-icon" @click="editImage (data)" v-tooltip.top="'Edit Inventory Image'"><i class="fa-solid fa-image"></i></Button>
                      <Button class="p-button-rounded action-icon" @click="openEditDialog ('delete', data.id)" v-tooltip.top="'Delete Ingredient'"><i class="fa-solid fa-trash"></i></Button>
                   </div>
                </template>
             </Column>
         </DataTable>
      </div>
   </div>
</template>

<script setup>
   import { ref, computed, onMounted } from "vue";
   import { RestGet, RestPost, RestPut, RestDelete } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRoute, useRouter } from 'vue-router'

   import {FilterMatchMode } from 'primevue/api';
   import ToggleButton from 'primevue/togglebutton';
   import Dropdown from 'primevue/dropdown';
   import Textarea from 'primevue/textarea';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import Dialog from 'primevue/dialog';
   import DataTable from 'primevue/datatable';
   import Column from 'primevue/column';
   import { useToast } from "primevue/usetoast";
   import AdminNavigation from './AdminNavigation.vue'
   import IconSelector from '../Lib/Selectors/IconSelector.vue'

   const toast = useToast();
   const profile = userProfileStore();
   const router = useRouter();
   const route = useRoute();

   var default_icon_list = ref ([]);
   var ingredient_type_id = ref ();
   var ingredient_type_name = ref ("");
   var showAttributeDialog = ref (false);
   var showImageDialog = ref (false);
   var attribute_mapping = ref ({});
   var mapping = ref ({});
   var dialogLabel = ref ("");
   var disabled = ref (false);
   var header = ref ("");
   var selected_icon = ref ();
   var default_icon = ref ();
   var displayMapping = ref (false);
   var saveImageButton = ref (false);
   var displayEdit = ref (false);
   var dialogAction = ref ("");
   var dialogClass = ref ("");
   var record = ref ({});
   var subtypes = ref ();
   
   const price = ref(null);
   const priceTooLow = ref (false);
   const attributes = ref();
   const ingredients = ref();
   const product_types = ref ();
   const products = ref ();
   const ingredient_types = ref ();
   const ingredient_subtypes = ref();
   const priceTooltip = ref ();
   const selectedIcon = ref ();

   var cost_per_ml = ref (0.00);
   var cost_per_oz = ref (0.00);

   const saveDisabled = computed(() => !record.value.name || !record.value.ingredient_type_id ||
                                       !record.value.tooltip || !record.value.description || priceTooLow.value);

   const filters = ref({'display': {value: true, matchMode: FilterMatchMode.EQUALS}});

   onMounted(() =>
   {
      if (route.params && route.params.id && route.params.id != 0)
      {
         ingredient_type_id.value = route.params.id;
         getProducts();
         return;
      }
   })

   const updateSubtpes = function (rec)
   {
      subtypes.value = ingredient_subtypes.value[rec.ingredient_type_id];
console.log ("SUBTYPES: ", subtypes.value);
   }

   const editInventory = function (data)
   {
      router.push ("/admin/ingredient/inventory/" + data.id);
      return;
   }

   const closeImageDialog = function ()
   {
      showImageDialog.value = false;
   }
   const editImage = function (data)
   {
      record.value = data;
      showImageDialog.value = true;
      header.value = record.value.name + " Ingredient Icon";

      default_icon.value = require ("@/assets/icons/misc/" + record.value.icon);
      selected_icon.value = default_icon.value;

      default_icon_list.value = [record.value.icon];
   }
   const iconSelected = function (obj)
   {
      selectedIcon.value = obj;
      saveImageButton.value = true;

      selected_icon.value = obj.icon;
   }
   const saveIngredientImage = function ()
   {
      showImageDialog.value = false;
     record.value.icon = selectedIcon.value.filename;

console.log ("CURRENT ICON: ", selectedIcon.value);
      saveData();
   }

   const verifyPrice = function ()
   {
      price.value.$el.style.color = ""; 
      priceTooltip.value = "";
      priceTooLow.value = false;

      if (record.value.price)
      {
         //
         // We need the parseInt otherwise the comparison measures the length of the strings. :D
         //
         if (parseInt (record.value.price) < parseInt (cost_per_oz.value))
         {
            price.value.$el.style.color = "red"; 
            priceTooltip.value = "The price to the customer is too low. It should be " + cost_per_oz.value + " minimum.";
            priceTooLow.value = true;
         }

         var p = record.value.price;
         record.value.price = parseFloat (p).toFixed(2);
      }
      else
      {
         calcCosts();
      }
   }

   const calcCosts = function ()
   {
      cost_per_ml.value = 0.00;

      if (record.value.cost > 0)
      {
         cost_per_ml.value = ((record.value.cost * 100) / record.value.volume) / 100;
      }

      cost_per_oz.value = cost_per_ml.value * 30;

      cost_per_ml.value = parseFloat (cost_per_ml.value).toFixed (3);
      cost_per_oz.value = parseFloat (cost_per_oz.value).toFixed (3);

      if (! record.value.price || record.value.price == 0)
      {
         record.value.price = (cost_per_oz.value * 5);
      }
   }

   const closeAttributeDialog = function () {showAttributeDialog.value = false;}
   const openAttributeDialog = function (rec)
   {
      showAttributeDialog.value = true;
      header.value = "Assign Attributes to " + rec.name;
      record.value = rec;
      attribute_mapping.value = {};

      getIngredientAttributes();
   }

   const getIngredientAttributes = function ()
   {
      attribute_mapping.value = {};

      var uri = "/ingredient/attribute/" + record.value.id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            for (var x = 0; x < response.length; x++)
            {
               attribute_mapping.value[response[x].attribute_id] = true;
            }
         }
      });
   }

   const saveIngredientAttributes = function ()
   {
      var json = {ingredient_id: record.value.id, attribute_list: []};
      for (var rec in attribute_mapping.value)
      {
         json.attribute_list.push (rec);
      }

      closeAttributeDialog();

      RestPost ("/ingredient/attribute/", profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var mesg = "The ingredient attributes were saved.";
            toast.add ({severity: 'success', summary: "Attributes Saved", detail: mesg, life: 3000});
         }
      });
   }

   const closeMappingDialog = function () {displayMapping.value = false;}
   const openMappingDialog = function (id)
   {
      displayMapping.value = true;
      disabled.value = false;
      dialogLabel.value = "Save";

      if (ingredients.value && ingredients.value.length > 0)
      {
         for (var x = 0; x < ingredients.value.length; x++)
         {
            if (ingredients.value[x].id == id)
            {
               record.value = ingredients.value[x];
               header.value = "Map (" + record.value.name + ") to Products";
               getMapping (record.value.id);
               break;
            }
         }
      }
   }

   const getMapping = function (id)
   {
      mapping.value = {};

      var uri = "/ingredient/mapping/" + id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            for (var x = 0; x < response.length; x++)
            {
               mapping.value[response[x].id] = true;
            }
         }
      });
   }

   const openEditDialog = function (action, id)
   {
      displayEdit.value = true;
      dialogAction.value = action;

      if (ingredients.value && ingredients.value.length > 0)
      {
         for (var x = 0; x < ingredients.value.length; x++)
         {
            if (ingredients.value[x].id == id)
            {
               record.value = ingredients.value[x];
               break;
            }
         }
      }

      if (action == "new")
      {
         header.value = "Add Ingredient";
         disabled.value = false;
         dialogLabel.value = "Save";
         dialogClass.value = "";
         cost_per_ml.value = 0.00;
         cost_per_oz.value = 0.00;
         record.value = {is_public_bool: true, 
                         is_premium_bool: false,
                         is_available_bool: true,
                         cost: 0.00,
                         volume: 0,
                         price: 0.00};
      }
      else if (action == "edit")
      {
         header.value = "Edit Ingredient";
         disabled.value = false;
         dialogLabel.value = "Edit";
         dialogClass.value = "";
         cost_per_ml.value = 0.00;
         cost_per_oz.value = 0.00;

         record.value.is_public_bool = record.value.is_public == 1 ? true : false;
         record.value.is_premium_bool = record.value.is_premium == 1 ? true : false;
         record.value.is_available_bool = record.value.is_available == 1 ? true : false;

         subtypes.value = ingredient_subtypes.value[record.value.ingredient_type_id];

         calcCosts();
      }
      else
      {
         header.value = "Delete Ingredient";
         disabled.value = true;
         dialogLabel.value = "Delete";
         dialogClass.value = "p-button-danger";
      }
   }

   //
   // This will save the data from the dialog, if needed.
   //
   const closeDialog = function () {displayEdit.value = false;}
   const saveData = function ()
   {
      closeDialog();

      var desc = record.value.description;
      if (record.value.description)
      {
         desc = record.value.description.replaceAll ("'", "");
      }

      if (! record.value.icon)
      {
         record.value.icon = "dyos.png";
      }

      var json = {name: record.value.name,
                  ingredient_type_id: record.value.ingredient_type_id,
                  ingredient_subtype_id: record.value.ingredient_subtype_id,
                  is_available: record.value.is_available_bool ? 1 : 0,
                  is_premium: record.value.is_premium_bool ? 1 : 0,
                  is_public: record.value.is_public_bool ? 1 : 0,
                  price: record.value.price,
                  icon: record.value.icon,
                  cost: record.value.cost,
                  volume: record.value.volume,
                  tooltip: record.value.tooltip,
                  purpose: record.value.purpose ? record.value.purpose : '',
                  description: desc};
      
      var mesg = "";
      var uri = ""
      if (dialogAction.value == "new")
      {
         RestPost ("/ingredient/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Data successfully saved.";
               toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});

               getIngredients();
               return;
            }
         });
      }
      else if (dialogAction.value == "delete")
      {
         uri = "/ingredient/" + record.value.id;
         RestDelete (uri, profile.token(), {}, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Record successfully deleted.";
               toast.add ({severity: 'success', summary: "Record Removed", detail: mesg, life: 3000});

               getIngredients();
               return;
            }
         });
      }
      else
      {
         uri = "/ingredient/" + record.value.id;
         RestPut (uri, profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Data successfully updated.";
               toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});

               getIngredients();
               return;
            }
         });
      }
   }

   const getProducts = function ()
   {
      products.value = {};

      RestGet ("/product/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            products.value = response;
            getProductTypes();
         }
      });
   }

   const getProductTypes = function ()
   {
      product_types.value = {};

      RestGet ("/product/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_types.value = response;
            getIngredientTypes();
         }
      });
   }

   const getIngredientTypes = function ()
   {
      ingredient_types.value = {};

      RestGet ("/ingredient/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient_types.value = response;
            
            for (var idx in ingredient_types.value)
            {
               if (ingredient_types.value[idx].id == ingredient_type_id.value)
               {
                  ingredient_type_name = ingredient_types.value[idx].name;
               }

               ingredient_types.value[idx].count = 0;
            }

            getIngredients();
         }
      });
   }

   const getIngredients = function ()
   {
      ingredients.value = [];

      RestGet ("/ingredient/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredients.value = response;


            for (var x = 0; x < ingredients.value.length; x++)
            {
               ingredients.value[x].is_premium_string = ingredients.value[x].is_premium == 0 ? "No" : "Yes";
               ingredients.value[x].is_public = ingredients.value[x].is_public == null ? 0 : ingredients.value[x].is_public;
               ingredients.value[x].is_public_string = ingredients.value[x].is_public == 0 ? "No" : "Yes";
               ingredients.value[x].is_available_string = ingredients.value[x].is_available == 0 ? "No" : "Yes";
               ingredients.value[x].is_available_bool = ingredients.value[x].is_available == 0 ? false : true;

               if (ingredients.value[x].ingredient_type_id == ingredient_type_id.value)
               {
                  ingredients.value[x].display = true; 
               }

               for (var y = 0; y < ingredient_types.value.length; y++)
               {
                  if (ingredients.value[x].ingredient_type_id == ingredient_types.value[y].id)
                  {
                     ingredients.value[x].ingredient_type = ingredient_types.value[y].name;
                     break;
                  }
               }
            }

            getIngredientSubTypes ();
         }
      });
   }

   const getIngredientSubTypes = function ()
   {
      RestGet ("/ingredient/subtype/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient_subtypes.value = {};
            for (var idx in response)
            {
               var rec = response[idx];
 
               if (! ingredient_subtypes.value[rec.ingredient_type_id])
               {
                  ingredient_subtypes.value[rec.ingredient_type_id] = [];
               }

               ingredient_subtypes.value[rec.ingredient_type_id].push (rec);
            }

            getAttributes();
         }
      });
   }

   const getAttributes = function ()
   {
      attributes.value = {};
      RestGet ("/attribute/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            attributes.value = response;
         }
      });
   }
</script>

<style scoped>
   .menu-button
   {
      height: 30px;
      font-weight: bold;
      font-face: var(--font-face);
      font-size: .8em;
      margin-top: 10px;
      background: #19BF55;
      border: 1px solid #237C43 !important;
   }
   .menu-button:hover
   {
      background: #237C43 !important;
      color: #ffffff !important;
   }

   .back-button
   {
      display: flex;
      justify-content: flex-start;
      padding: 0px 5px 0px 3px;
      font-size: .8em;
      line-height: 30px;
      height: 30px;
      width: 100px;
      font-weight: bold;
   }

   .ingredient-image
   {
      width: 50px;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex; 
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
   }
   .product-name-button
   {
      width: 225px !important;
   }

   .flagPrice {color: red; }

   .icon-text-header {font-size: 1.2em; font-weight: bold; font-variant: small-caps;}
</style>
