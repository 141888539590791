<template>
   <div class="page">
      <LeftNavigation inactive />
      <div class="content content-left">
         <div v-if="product" class="recipe-title">Craft {{product.name}} Recipe</div>

         <CraftingStages v-if="product_stages" :stages="product_stages" v-model="current_stage_idx" v-on:click="craftCB" :saving="saving" :key="craftStageCount" />

         <div v-if="!saving">
            <IngredientSelector v-if="ingredient_type" v-on:next="nextStep" :ingredient_type="ingredient_type" :product="product" :product_sizes="product_sizes" :stage="current_stage" :key="ingredientSelectorCount" />
         </div>
         <div v-else>
            <SaveRecipe v-if="saving" :product="product" />
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { useRoute, useRouter } from "vue-router";

   import { RestGet } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { recipeStore } from '@/stores/recipe';
   import LeftNavigation from './LeftNavigation.vue'
   import SaveRecipe from './Lib/SaveRecipe.vue'
   import CraftingStages from './Lib/CraftingStages.vue'
   import IngredientSelector from './Lib/IngredientSelector.vue'

   const profile = userProfileStore();
   const recipe = recipeStore();
   const router = useRouter();
   const route = useRoute();
   const ingredient_types = ref();
   const ingredient_type = ref();
   const product = ref();
   const product_sizes = ref();
   const product_id = route.params.id;
   const product_stages = ref ();
   const stages = ref ();
   const current_stage_idx = ref(0);
   const current_stage = ref({});
   const ingredientSelectorCount = ref (0);
   const craftStageCount = ref (0);
   const saving = ref (false);

   onMounted(() =>
   {
      getProductDetails();
   })

   const craftCB = function(idx)
   {
      //
      // We reset the saving flag so the ingredients selector shows up. This means someone clicked
      // on a ingredient type button, so they want to edit again.
      //
      saving.value = false;

      current_stage.value = product_stages.value[idx];
      current_stage_idx.value = idx;

      for (var x=0; x < ingredient_types.value.length; x++)
      {
         if (ingredient_types.value[x].code == current_stage.value.code)
         {
            ingredient_type.value = ingredient_types.value[x];
            break;
         }
      }

      getIngredientTypes();
   }

   const nextStep = function (stage, data)
   {
      //
      // Add the ingredient to the recipe.
      //
      recipe.addIngredient (stage.code, stage, data);

      //
      // Once done, we need to move to the recipe saver.
      //
      if (current_stage_idx.value == product_stages.value.length -1)
      {
         product_stages.value[current_stage_idx.value].visited = true;
console.log ("ZZZZZZZZ WANT TO SAVE....");
         saving.value = true;
         return;
      }

      //
      //
      //
      current_stage_idx.value++;
      current_stage.value = product_stages.value[current_stage_idx.value];
      if (current_stage_idx.value > 0)
      {
         product_stages.value[current_stage_idx.value-1].visited = true;
      }

      for (var x=0; x < ingredient_types.value.length; x++)
      {
         if (ingredient_types.value[x].code == current_stage.value.code)
         {
            ingredient_type.value = ingredient_types.value[x];
            break;
         }
      }

      ingredientSelectorCount.value++;
   }

   const getProductDetails = function ()
   {
      product.value = {};

      var uri = "/product/" + product_id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
           router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product.value = response[0];
         }

         getProductSizes();
      });

   }

   const getProductSizes = function ()
   {
      product_sizes.value = {};
      var uri = "/product/size/" + product_id;

      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
           router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_sizes.value = response;
         }

         getProductWorkflow();
      });
   }

   const getProductWorkflow = function ()
   {
      var uri = "/product/workflow/product/" + product_id;

      product_stages.value = {};
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
           router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_stages.value = response;
            current_stage_idx.value = 0;
            current_stage.value = product_stages.value[current_stage_idx.value];
         }

         getIngredientTypes();
      });
   }

   const getIngredientTypes = function ()
   {
      var uri = "/ingredient/type/";

      ingredient_types.value = {};
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
           router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient_types.value = response;

            stages.value = [];
            for (var x=0; x < ingredient_types.value.length; x++)
            {
               stages.value[x] = ingredient_types.value[x].code;

               if (ingredient_types.value[x].code == current_stage.value.code)
               {
                  ingredient_type.value = ingredient_types.value[x];
               }

               for (var y = 0; y < product_stages.value.length; y++)
               {
                  if (product_stages.value[y].code == ingredient_types.value[x].code)
                  {
                     product_stages.value[y].name = ingredient_types.value[x].name;
                     product_stages.value[y].description = ingredient_types.value[x].description;
                     product_stages.value[y].ingredient_type_id = ingredient_types.value[x].id;
                     break;
                  }
               }
            }

            //
            // Update this counter to refresh the component.
            //
            ingredientSelectorCount.value++;
            craftStageCount.value++;
         }
      });
   }
</script>

<style scoped>
   .recipe-title
   {
      font-weight: bold;
      font-variant: small-caps;
      font-size: 1.2em;
      text-shadow: 3px 3px 5px rgba(0,0,0,.5);
   }
</style>
