//
// https://vuejs.org/guide/scaling-up/state-management.html#simple-state-management-with-reactivity-api
//
import { reactive } from 'vue'

export const loginStatusStore = reactive({ 
       loggedIn: false, 
       isAdmin: false, 
       refresh: false,
       sessionTimeout: 0,
       logout()
       {
          this.loggedIn = false;
          this.isAdmin = false;
       },
 })
