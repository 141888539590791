<template>
   <div class="card card-full">
      <div class="container container-row container-align-top container-full">
         <div class="container container-left container-grow">
            <ShowRecipe v-if="props.product" :product="props.product" />
         </div>

         <div class="container container-left">
            <div class="title">Save {{props.product.name}} Recipe</div>
         </div>
      </div>

      <hr />

      <pre v-if="user" class="body">
         OK {{user.first_name}}, let's save your personally crafted recipe. Type in a unique name and we will save this to your personal recipe book. 

         You can always go to your personal recipe book, using the <span class="p-button-rounded p-button-primary recipe-button">Recipes &nbsp; <i class="fas fa-flask"></i></span> button above to re-order, share, or edit some of your existing recipes.

         If we really like it, we might even make it a recipe of the month.
      </pre>

      <div v-if="message.message">
         <div class="message" :class="{error: message.isError, warning: message.isWarning, info: message.isInfo}">
            {{message.message}}
         </div>
      </div>

      <div class="container container-column">
         <div class="container container-left">
            <div class="input-label">Recipe Name</div>
            <InputText type="text" v-model="record.name" size="50" />
            <Button class="p-button-text call-to-action-button" v-on:click="save" :disabled="!record.name" style="margin-left: 10px;" >
               <div class="save-text">Save &nbsp; &nbsp;<i class="fa-solid fa-floppy-disk"></i></div>
            </Button>
         </div>

         <div class="container container-left">
            <div class="input-label">Recipe Description</div>
            <Textarea v-model="record.description" :autoResize="true" rows="5" cols="48" />
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, defineProps, onMounted } from 'vue';
   import { RestPost } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { recipeStore } from '@/stores/recipe';
   import { useRouter } from 'vue-router'

   import InputText from 'primevue/inputtext';
   import Textarea from 'primevue/textarea';
   import ShowRecipe from "./ShowRecipe";
   import Button from 'primevue/button';

   const router = useRouter();
   const recipe = recipeStore();
   const profile = userProfileStore();
   const record = ref ({name: ''});
   const message = ref ({message: '', isError: false, isWarning: false, isInfo: false});
   const user = ref ();

   const props = defineProps ({product: Object});

   onMounted (() =>
   {
      user.value = profile.get();
   })

   const save = function()
   {
      var user_recipe = recipe.get();
      if (! user_recipe)
      {
         message.value.message = "Something went horribly wrong. Your recipe appears to have been lost.";
         message.value.isError = true;
         return;
      }

      var json = {customer_id: user.value.id,
                  product_id: user_recipe.product.id,
                  product_type_id: user_recipe.product.product_type_id,
                  name: record.value.name,
                  description: record.value.description,
                  ingredients: JSON.stringify (user_recipe)};

      RestPost ("/recipe/", profile.token(), json, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
console.log ("RESP: ", response);
            message.value.message = "We have saved your recipe (" + record.value.name + ") to your recipe book.";
            message.value.isInfo = true;
            return;
         }
      });

   }

</script>

<style scoped>
.title
{
   font-size: 1.2em;
   font-weight: bold;
   font-variant: small-caps;
   text-shadow: 2px 2px 3px rgba(0,0,0,.5);
}
.body
{
   font-size: .9em;
   padding: 3px 20px 10px 20px;
   white-space: pre-line;
   font-family: 'Open Sans', sans-serif;
   text-shadow: 2px 2px 3px rgba(0,0,0,.5);
}
.save-text { font-size: .7em !important; width: 100px; }

.recipe-button
{
   color: #000000;
   border-radius: 10px;
   background: #C4B0F0;
   height: 30px;
   font-weight: bold;
   font-face: var(--font-face);
   font-size: .7em;
   margin: 10px 5px 10px 5px;
   padding: 3px 7px 3px 7px;
   box-shadow: 2px 2px 3px rgba(0,0,0,.5);
}

.message
{
   font-size: .9em;
   flex: display;
   align-content: center;
   align-items: center;
   justify-content: center;
   padding: 0px 10px 0px 10px;
   margin: 5px 5px 10px 5px;
   border-radius: 10px;
   height: 30px;
   line-height: 30px;
   text-shadow: 2px 2px 3px rgba(0,0,0,.5);
   text-align: center;
}

.error {background: red; color: white;}
.warning {background: orange; color: brown;}
.info {background: #B9B9FF; color: #000000;}

</style>
