<template>
   <ToastTip />

   <Dialog position="top" :header="header" v-model:visible="displayEdit" :style="{width: '60vw'}">
      <div class="container container-left">
         <div class="input-label">Manufacturer Name</div>
         <InputText id="name" type="text" size="50" v-model="record.manufacturer_name" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Product Name (Internal Use Only)</div>
         <InputText id="product_name" type="text" size="50" v-model="record.product_name" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Trade Name</div>
         <InputText id="trade_name" type="text" size="50" v-model="record.trade_name" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">INCI Name</div>
         <InputText id="inci_name" type="text" size="20" v-model="record.inci_name" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Product URL</div>
         <InputText id="product_url" type="text" size="65" v-model="record.product_url" v-on:blur="setWebsite" :disabled="disabled" /> 
         <div v-if="record.product_url" class="product-url" v-tooltip="'Open in a new tab'"><a :href="record.product_url" target="new_tab"><i class="fa-solid fa-globe fa-2x"></i></a></div>
      </div>

      <div class="container container-left">
         <div class="input-label">Manufacturer Website</div>
         <InputText id="website" type="text" size="65" v-model="record.manufacturer_website" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Product SKU</div>
         <InputText id="sku" type="text" size="20" v-model="record.product_sku" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Certificate of Analysis (COA) URL</div>
         <InputText id="coa_url" type="text" size="65" v-model="record.coa_url" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Safety Data Sheet (SDS) URL</div>
         <InputText id="sds_url" type="text" size="65" v-model="record.sds_url" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">BIN Number</div>
         <InputText id="bin" type="text" size="20" v-model="record.bin" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">PH Range</div>
         <div class="input-label auto-size">Low</div><InputText id="ph_low" type="number" size="5" placeholder='0' v-model="record.ph_low" :disabled="disabled" />
         <div class="input-label auto-size">High</div><InputText id="ph_high" type="number" size="5" placeholder='0' v-model="record.ph_high" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Melting Point</div>
         <InputText id="melting_point" type="number" size="5" step='0.01' placeholder='0.00' v-model="record.melting_point" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Water Soluable</div>
         <Dropdown v-model="record.water_soluable" :options="yes_no_partial" optionLabel="label" optionValue="value" placeholder="Select Soluability" />
      </div>

      <div class="container container-left">
         <div class="input-label">Oil Soluable</div>
         <Dropdown v-model="record.oil_soluable" :options="yes_no_partial" optionLabel="label" optionValue="value" placeholder="Select Soluability" />
      </div>

      <div class="container container-left">
         <div class="input-label">Ethanol Soluable</div>
         <Dropdown v-model="record.ethanol_soluable" :options="yes_no_partial" optionLabel="label" optionValue="value" placeholder="Select Soluability" />
      </div>

      <div class="container container-left">
         <div class="input-label">Propanediol Soluable</div>
         <Dropdown v-model="record.propanediol_soluable" :options="yes_no_partial" optionLabel="label" optionValue="value" placeholder="Select Soluability" />
      </div>

      <div class="container container-left">
         <div class="input-label">COSMOS Approved</div>
         <ToggleButton v-model="record.cosmos_approved_bool" onIcon="pi pi-check" offIcon="pi pi-times" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">ECOCERT Approved</div>
         <ToggleButton v-model="record.ecoart_approved_bool" onIcon="pi pi-check" offIcon="pi pi-times" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Formulation Instructions</div>
         <Textarea v-model="record.formulation_instructions" :autoResize="true" rows="3" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">Storage Instructions</div>
         <Textarea v-model="record.storage_instructions" :autoResize="true" rows="3" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">Claims</div>
         <Textarea v-model="record.claims" :autoResize="true" rows="3" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">Benefits</div>
         <Textarea v-model="record.benefits" :autoResize="true" rows="3" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">Product Uses</div>
         <Textarea v-model="record.product_uses" :autoResize="true" rows="3" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">Compatible With</div>
         <Textarea v-model="record.compatible_with" :autoResize="true" rows="3" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">Tolerances</div>
         <Textarea v-model="record.tolerance" :autoResize="true" rows="3" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">Notes</div>
         <Textarea v-model="record.notes" :autoResize="true" rows="3" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">In Stock</div>
         <ToggleButton v-model="record.in_stock_bool" onIcon="pi pi-check" offIcon="pi pi-times" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Purchase Cost ($0.00)</div>
         <InputText id="cost" type="number" step="0.01" style="width: 8em" v-model="record.cost" v-on:blur="calcCosts" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Ingredient Volume (ml)</div>
         <InputText id="volume" type="number" min="0" style="width: 8em" v-model="record.volume" v-on:blur="calcCosts" :disabled="disabled" />
         <div class="input-label" style="width:140px">Cost (ml)&nbsp;${{cost_per_ml}}</div>
         <div class="input-label" style="width:140px">Cost (oz)&nbsp;${{cost_per_oz}}</div>
      </div>

      <div class="container container-left">
         <div class="input-label">Remaining Volume (ml)</div>
         <InputText id="remaining_volume" type="number" min="0" style="width: 8em" v-model="record.remaining_volume" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Customer Price per (oz)</div>
         <InputText ref="price" id="price" type="number" step="0.01" style="width: 8em;" v-model="record.price" v-on:blur="verifyPrice" v-tooltip="priceTooltip" :disabled="disabled" />
         <div class="input-label" style="width:140px">Cost (oz) x {{multiplier}}</div>
      </div>

      <div class="container container-left">
         <div class="input-label">Ingredient Purchase Date</div>
         <InputText id="volume" type="date" v-model="record.purchase_date" :max="today" :disabled="disabled" />
      </div>

      <template #footer>
         <Button label="Close" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
         <Button :label="dialogLabel" :class="dialogClass" icon="pi pi-save" @click="saveData" :disabled="saveDisabled" autofocus />
      </template>
   </Dialog>

   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
    <div class="container container-left">
       <Button @click="$router.go(-1)" class="p-button-sm back-button">
          <i class="fa-solid fa-circle-left"></i>&nbsp;&nbsp;Back
       </Button>
       <h1 v-if="ingredient">Manage {{ingredient.name}} Inventory</h1>
       <div @click="openDialog ('new')" class="call-to-action-button">
          Add <i class="fa-solid fa-square-plus"></i>
       </div>
    </div>

    <div class="ingredient-description">{{ingredient.description}}</div>

    <DataTable ref="table" :value="ingredient_inventory" responsiveLayout="scroll" dataKey="id" >
       <Column field="name" header="Product Name" sortable style="min-width: 14rem">
          <template #body="{data}">{{data.product_name}}</template>
       </Column>

       <Column field="name" header="In Stock" sortable style="min-width: 14rem">
          <template #body="{data}">{{data.in_stock_string}}</template>
       </Column>

       <Column field="name" header="Manufacturer" sortable style="min-width: 14rem">
          <template #body="{data}">{{data.manufacturer_name}}</template>
       </Column>

       <Column field="name" header="INCI Name" sortable style="min-width: 14rem">
          <template #body="{data}">{{data.inci_name}}</template>
          <template #footer>Total</template>
       </Column>

       <Column field="volume" header="Volume">
          <template #body="{data}">{{data.volume}}</template>
          <template #footer>{{total_volume}}</template>
       </Column>

       <Column field="cost" header="Cost">
          <template #body="{data}">${{data.cost_string}}</template>
          <template #footer>${{total_cost}}</template>
       </Column>

       <Column field="cost" header="Cost (gm)">
          <template #body="{data}">${{data.cost_gram_string}}</template>
       </Column>

       <Column field="measure" header="Price (oz)">
          <template #body="{data}">${{data.price_string}}</template>
       </Column>

       <Column field="purchase_date" header="Purchase Date">
          <template #body="{data}">{{data.purchase_date}}</template>
       </Column>

       <Column field="actions" header="Actions">
          <template #body="{data}">
             <div class="container container-left">
                <Button v-if="data.product_url" class="p-button-rounded action-icon" @click="openWebsite (data.product_url)" v-tooltip.top="'Open Website'"><i class="fa-solid fa-globe"></i></Button>
                <Button class="p-button-rounded action-icon" @click="openDialog ('edit', data.id)" v-tooltip.top="'Edit Record'"><i class="fa-solid fa-cog"></i></Button>
                <Button class="p-button-rounded action-icon" @click="openDialog ('delete', data.id)" v-tooltip.top="'Delete Record'"><i class="fa-solid fa-trash"></i></Button>
             </div>
          </template>
       </Column>
    </DataTable>
      </div>
   </div>
</template>

<script setup>
   import { ref, computed, onMounted } from "vue";
   import { RestGet, RestPut, RestDelete, RestPost } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRoute, useRouter } from 'vue-router'

   import Dropdown from 'primevue/dropdown';
   import ToggleButton from 'primevue/togglebutton';
   import Textarea from 'primevue/textarea';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import Dialog from 'primevue/dialog';
   import DataTable from 'primevue/datatable';
   import Column from 'primevue/column';
   import { useToast } from "primevue/usetoast";
   import AdminNavigation from '../AdminNavigation.vue'

   const toast = useToast();
   const profile = userProfileStore();
   const router = useRouter();
   const route = useRoute();

   const yes_no_partial = [{label: "N/A", value: "na"}, {label: "Yes", value: "yes"}, {label: "No", value: "no"}, {label: "Partial", value: "partial"}];
   const multiplier = ref (5);
   const priceTooLow = ref (false);
   const priceTooltip = ref ('');
   const price = ref();
   const cost_per_ml = ref (0);
   const cost_per_oz = ref (0);
   const today = new Date().toISOString().slice(0, 10);
   var total_volume = ref (0);
   var average_price = ref (0);
   var total_cost = ref (0);
   var dialogLabel = ref ("");
   var disabled = ref (false);
   var header = ref ("");
   var displayEdit = ref (false);
   var dialogAction = ref ("");
   var dialogClass = ref ("");
   var record = ref ({});
   const table = ref();
   
   const ingredient_inventory = ref([]);
   const ingredient = ref({});

   const ingredient_id = route.params.id;

   const saveDisabled = computed(() => !record.value.manufacturer_name || !record.value.product_name || 
                   !record.value.cost || !record.value.volume || !record.value.price ||
                   !record.value.purchase_date);

   onMounted(() =>
   {
      getIngredientDetails();
   })

   const openWebsite = function (url)
   {
      window.open(url, "_product_url_");
   }

   const setWebsite = function ()
   {
      const { protocol, host } = new URL(record.value.product_url);
      record.value.manufacturer_website = protocol + "//" + host;
   }

   const verifyPrice = function ()
   {
      price.value.$el.style.color = "";
      priceTooltip.value = "";
      priceTooLow.value = false;

      calcCosts();

      if (record.value.price)
      {
         //
         // We need the parseInt otherwise the comparison measures the length of the strings. :D
         //
         if (parseInt (record.value.price) < parseInt (cost_per_oz.value))
         {
            price.value.$el.style.color = "red";
            priceTooltip.value = "The price to the customer is too low. It should be " + cost_per_oz.value + " minimum.";
            priceTooLow.value = true;
         }

         var p = record.value.price;
         record.value.price = parseFloat (p).toFixed(2);
      }
      else
      {
         calcCosts();
      }
   }

   const calcCosts = function ()
   {
      cost_per_ml.value = 0.00;
      cost_per_oz.value = 0.00;
      record.value.price = 0.00;

      if (record.value.volume && !record.value.remaining_volume)
      {
         record.value.remaining_volume = record.value.volume;
      }

      if (!record.value.cost || !record.value.volume ||  record.value.cost == 0 || record.value.volume == 0)
      {
         return;
      }

      if (record.value.cost > 0)
      {
         cost_per_ml.value = ((record.value.cost * 100) / record.value.volume) / 100;
      }

      //
      // 30 grams in an ounce.
      //
      cost_per_oz.value = cost_per_ml.value * 30;

      cost_per_ml.value = parseFloat (cost_per_ml.value).toFixed (3);
      cost_per_oz.value = parseFloat (cost_per_oz.value).toFixed (3);

      if (! record.value.price || record.value.price == 0)
      {
         record.value.price = (cost_per_oz.value * multiplier.value);
      }
      record.value.price = record.value.price.toFixed (2);

      if (record.value.price < 2)
      {
         record.value.price = 2;
      }
   }

   const closeDialog = function () {displayEdit.value = false; }
   const openDialog = function (action, id)
   {
      displayEdit.value = true;
      dialogAction.value = action;

      if (ingredient_inventory.value && ingredient_inventory.value.length > 0)
      {
         for (var x = 0; x < ingredient_inventory.value.length; x++)
         {
            if (ingredient_inventory.value[x].id == id)
            {
               record.value = ingredient_inventory.value[x];

               record.value.in_stock_bool = record.value.in_stock ? true : false;
               record.value.ecoart_approved_bool = record.value.ecoart_approved ? true : false;
               record.value.cosmos_approved_bool = record.value.cosmos_approved ? true : false;
               break;
            }
         }
      }

      calcCosts();

      if (action == "new")
      {
         header.value = "Add Ingredient Inventory Item";
         disabled.value = false;
         dialogLabel.value = "Save";
         dialogClass.value = "";

         cost_per_ml.value = "0.00";
         cost_per_oz.value = "0.00";

         record.value = {"purchase_date": today,
                          in_stock_bool: true, ecoart_approved_bool: true, cosmos_approved_bool: true,
                          water_soluable: 'no',
                          oil_soluable: 'no',
                          ethanol_soluable: 'no',
                          propanediol_soluable: 'no',
                        };
      }
      else if (action == "edit")
      {
         header.value = "Edit Ingredient Inventory Item";
         disabled.value = false;
         dialogLabel.value = "Edit";
         dialogClass.value = "";
      }
      else
      {
         header.value = "Delete Ingredient Inventory Item";
         disabled.value = true;
         dialogLabel.value = "Delete";
         dialogClass.value = "p-button-danger";
      }
   }

   const saveData = function ()
   {
      closeDialog();
      var json = {"ingredient_id": ingredient_id};
      var fields = ["manufacturer_name", "product_name", "trade_name", "product_url", "manufacturer_website", 
                    "melting_point", "ph_low", "ph_high", "tolerance", "product_uses", "claims", "benefits", "compatible_with",
                    "water_soluable", "oil_soluable", "ethanol_soluable", "propanediol_soluable", "formulation_instructions",
                    "product_sku", "tolerance", "notes", "storage_instructions",
                    "coa_url", "sds_url", "bin","inci_name", "cost", "volume", "price", "purchase_date"];

      for (var idx in fields)
      {
         var field = fields[idx];

         if (record.value[field])
         {
            json[field] = record.value[field];
         }
      }

      json['in_stock'] = record.value.in_stock_bool ? 1 : 0;
      json['cosmos_approved'] = record.value.cosmos_approved_bool ? 1 : 0;
      json['ecoart_approved'] = record.value.ecoart_approved_bool ? 1 : 0;

      var mesg = "";
      var uri = ""
      if (dialogAction.value == "new")
      {
         RestPost ("/ingredient/inventory/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Data successfully saved.";
               toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});
               getIngredientInventory();
               return;
            }
         });
      }
      else if (dialogAction.value == "delete")
      {
         uri = "/ingredient/inventory/" + record.value.id;
         RestDelete (uri, profile.token(), {}, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Record successfully deleted.";
               toast.add ({severity: 'success', summary: "Record Removed", detail: mesg, life: 3000});
               getIngredientInventory();
               return;
            }
         });
      }
      else
      {
         uri = "/ingredient/inventory/" + record.value.id;
         RestPut (uri, profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Data successfully updated.";
               toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});

               getIngredientInventory();
               return;
            }
         });
      }
   }

   const getIngredientDetails = function()
   {
      ingredient.value = [];

      var uri = "/ingredient/" + ingredient_id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient.value = response[0];

            getIngredientInventory();
         }
      });
   }

   const getIngredientInventory = function ()
   {
      ingredient_inventory.value = [];

      var uri = "/ingredient/inventory/type/" + ingredient_id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient_inventory.value = response;
            var cost = 0;
            var volume = 0;
            var price = 0;
            var count = 0;
            var cost_gram = 0;

            for (var idx in ingredient_inventory.value)
            {
               if (ingredient_inventory.value[idx].in_stock)
               {
                  cost += ingredient_inventory.value[idx].cost;
                  volume += ingredient_inventory.value[idx].volume;
                  price += ingredient_inventory.value[idx].price;
               }

               cost_gram = ingredient_inventory.value[idx].cost / ingredient_inventory.value[idx].volume;
               ingredient_inventory.value[idx].cost_string = ingredient_inventory.value[idx].cost.toFixed (2);
               ingredient_inventory.value[idx].price_string = ingredient_inventory.value[idx].price.toFixed (2);
               ingredient_inventory.value[idx].cost_gram_string = cost_gram.toFixed(2);

               ingredient_inventory.value[idx].in_stock_string = "No";
               if (ingredient_inventory.value[idx].in_stock)
               {
                  ingredient_inventory.value[idx].in_stock_string = "Yes";
               }
               count++;
            }

            total_cost.value = cost;
            total_volume.value = volume;
            average_price.value = 0;

            if (count > 0 && price > 0)
            {
               average_price.value = price / count;
            }
 
            total_cost.value = total_cost.value.toFixed(2);
            total_volume.value = total_volume.value.toFixed (2);
            average_price.value = average_price.value.toFixed (2);
         }
      });
   }
</script>

<style scoped>
   .menu-button
   {
      height: 30px;
      width: 150px;
      display: flex;
      justify-content: flex-end;
      font-weight: bold;
      font-face: var(--font-face);
      font-size: .8em;
      margin-top: 10px;
      background: #19BF55;
      border: 1px solid #237C43 !important;
   }
   .menu-button:hover
   {
      background: #237C43 !important;
      color: #ffffff !important;
   }
   .product-name-button
   {
      width: 225px !important;
   }

   .label-col {width: 100px !important;; height: 30px; background: #03080F; }
   .name-col {width: 200px !important; height: 30px; background: #03080F;}
   .description-col {width: 350px !important; height: 30px; background: #03080F;}

   .delete-record-icon
   {
      margin-top: 5px;
      margin-left: 5px;
      font-size: 1.5em;
   }
   .delete-record-icon:hover
   {
      cursor: pointer;
      color: red;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex; 
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
   }

   .ingredient-description
   {
      min-height: 30px;
      width: auto;
      margin: 2px 0px 10px 0px;
      padding: 5px;
      font-size: .7em;
      border: 2px solid var(--header-border-color);
      border-radius: 5px;
   }
   .product-url
   {
      display: flex; 
      justify-content: center;
      align-text: center;
      margin-left: 10px;
      font-size: 1em !important;
   }
   .back-button
   {
      display: flex;
      justify-content: flex-start;
      padding: 0px 5px 0px 3px;
      font-size: .8em;
      line-height: 30px;
      height: 30px;
      width: 100px;
      font-weight: bold;
   }
</style>
