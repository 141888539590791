<template>
   <ToastTip />

   <div class="page">
      <AdminNavigation />

      <div class="content">
         <h1>Product Formulations</h1>
         <div v-if="!edit" class="container container-column container-align-top">
            <div v-for="record in product_types" v-bind:key="record.id" class="product-type-container">
               <div class="container container-left">
                  <div class="container container-left">
                     <div class="product-name-button large" :class="{notavailable: !record.is_available}" v-tooltip="record.name">
                        <div class="icon"><img :src="record.icon"></div>
                        <div class="text">{{record.name}}</div>
                     </div>
                  </div>

                  <div v-if="products[record.id] != null" class="container container-left container-wrap">
                     <div v-for="(product,idx) in products[record.id]" v-bind:key="idx">
                        <div v-if="product.is_available">
                           <div v-if="!product_workflow_list[product.id]" class="product-button noWorkflow" v-on:click="editWorkflow (product)" v-tooltip="'Workflow Not Defined'">
                              <div class="product-button-badge"><img :src="record.icon"></div>
                              <div class="product-button-text">{{product.name}}</div>
                           </div>
                           <div v-else class="product-button" v-on:click="editWorkflow (product)">
                              <div class="product-button-badge"><img :src="record.icon"></div>
                              <div class="product-button-text">{{product.name}}</div>
                           </div>
                        </div>
                        <div v-else class="product-button unavailable" v-tooltip="'Product Unavailable'">
                           <div class="product-button-badge"><img :src="record.icon"></div>
                           <div class="product-button-text">{{product.name}}</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div v-if="edit" class="container container-column container-align-top">
            <div class="container container-left"><h1>{{title}}</h1></div>
            <div class="container container-left">
               <div class="input-label">Name</div>
               <InputText id="name" type="text" size="50" v-model="record.name" autofocus />
               <Button label="Close" icon="pi pi-times" @click="save ('close')" class="p-button-text"/>
               <Button label="Save" icon="pi pi-file" @click="save (record.action)" class="p-button-text" :disabled="formDisabled" />
            </div>

            <div class="container container-left">
               <div class="input-label">Product Type</div>
               <Dropdown v-model="record.product_type_id" :options="product_types" optionLabel="name" optionValue="id" placeholder="Select a Product Type"  v-on:change="updateTitle (record.product_type_id)" />
            </div>

            <div class="container container-left">
               <div class="input-label">Product Available</div>
               <ToggleButton v-model="record.is_available" onIcon="pi pi-check" offIcon="pi pi-times" />
            </div>

            <div class="container container-left">
               <div class="input-label">Description</div>
               <Textarea v-model="record.description" :autoResize="true" rows="5" cols="49" />
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { RestGet, RestPost } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'

   import { useToast } from "primevue/usetoast";
   import Dropdown from 'primevue/dropdown';
   import ToggleButton from 'primevue/togglebutton';
   import Button from 'primevue/button';
   import Textarea from 'primevue/textarea';
   import InputText from 'primevue/inputtext';
   import AdminNavigation from '../AdminNavigation.vue'

   const profile = userProfileStore();
   const router = useRouter();
   const toast = useToast();
   const title= ref();
   const record = ref({});
   const edit = ref (false);
   const product_workflow_list = ref();
   const product_workflows = ref();
   const product_types = ref();
   const products = ref ();

   //
   // Disables the navigation menus/buttons
   //
   //const formDisabled = computed(() => !record.value.name);

   onMounted(() =>
   {
      getProductTypes();
   })

   const editWorkflow = function (product)
   {
      router.push ("/admin/workflow/" + product.id);
   }

   const save = function (action)
   {
      edit.value = false;

      if (action === 'close')
      {
         title.value = null;
         record.value = {};
         return;
      }

      //
      // Prepare the request.
      //
      var json = {name: record.value.name,
                  description: record.value.description,
                  product_type_id: record.value.product_type_id,
                  is_available: record.value.is_available};
      var mesg;
      var uri;

      if (record.value.action === "add")
      {
         RestPost ("/product/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "The record has been saved.";
               toast.add ({severity: 'info', summary: "Record Saved", detail: mesg, life: 3000});

               getProducts();
               return;
            }
         });
      }
      else if (record.value.action === "edit")
      {
         uri = "/product/" + record.value.id;
         RestPost (uri, profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "The record has been updated.";
               toast.add ({severity: 'info', summary: "Record Updated", detail: mesg, life: 3000});

               getProducts();
               return;
            }
         });
      }
   }

   const getProductTypes = function ()
   {
      product_types.value = [];

      RestGet ("/product/type/all", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_types.value = response;
            for (var x = 0; x < product_types.value.length; x++)
            {  
               var id = product_types.value[x].id;
               product_types.value[x].icon = require (`@/assets/icons/product/type/${id}.png`);
               product_types.value[x].tooltip = "Create a New " + product_types.value[x].name + " Product";
            }

            getProducts();
         }
      });
   }

   const getProducts = function ()
   {
      products.value = [];

      RestGet ("/product/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var json = {};
            for (var x = 0; x < response.length; x++)
            {
               var rec = response[x];

               if (! json[rec.product_type_id])
               {
                  json[rec.product_type_id] = [];
               }

               var id = rec.product_type_id;
               rec.badge = require (`@/assets/icons/product/type/${id}.png`);
               json[rec.product_type_id].push (rec);
            }

            products.value = json;

            getProductWorkflows();
         }
      });
   }

   const getProductWorkflows = function ()
   {
      product_workflows.value = {};

      RestGet ("/product/workflow/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_workflows.value = response;
            var json = {};

            for (var x = 0; x < product_workflows.value.length; x++)
            {
               var rec = product_workflows.value[x];
               json[rec.product_id] = rec.stage_list;
            }

            product_workflow_list.value = json;
         }
      });
   }
</script>

<style>
   .product-name-button
   {
      height: 60px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
      padding: 2px 1px 2px 1px;
   }
   .product-name-button .icon
   {
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .product-name-button .icon img
   {
      height: 30px;
      width: 30px;
   }
   .product-name-button .text
   {
      margin-left: 10px;
      font-size: .8em;
      font-weight: bold;
   }
   .product-name-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }

   .product-type-button
   {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
   }
   .product-type-button .icon
   {
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .product-type-button .icon img
   {
      height: 30px;
      width: 30px;
   }
   .product-type-button .text
   {
      margin-left: 10px;
      font-size: 1em;
      font-weight: bold;
   }

   .product-type-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }

   .product-button
   {
      width: 120px;
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
      padding: 1px 2px 1px 2px;
   }
   .product-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }
   .product-button-text
   {
      margin-left: 5px;
      text-align: center;
      font-size: .7em;
      font-weight: bold;
      text-shadow: 2px 2px 3px #111111;
   }
   .product-button-badge
   {
      width: 30px;
      border-radius: 5px;
      background: #45427C;
   }
   .product-button-badge img
   {
      margin-left: 5px;
      height: 20px;
      width: 20px;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .large { width: 120px; }

   .image-preview
   {
      height: 50px;
      width: 50px;
      border-radius: 5px;
   }

   .unavailable
   {
      opacity: .5 !important;
      cursor: not-allowed !important;
   }

   .noWorkflow
   {
      opacity: .8;
      color: #05C5EA !important;
   }
</style>
