<template>
   <ToastTip />
   <div class="page">
      <AdminNavigation />

      <div class="content">

         <h1>Product Editor</h1>
         <ProductEditor v-if="showProductEditor" :action="productAction" :data="record" v-on:save="saveProductCB" :key="productKey" />
         <ProductTypeEditor v-if="showProductTypeEditor" :action="productAction" :data="record" v-on:save="saveProductTypeCB" :key="productTypeKey" />

         <div class="container container-right container-grow">
            <div @click="addProduct" class="call-to-action-button">
               Add Product <i class="fa-solid fa-square-plus"></i>
            </div>
         </div>

         <div class="container container-column container-align-top">
            <div v-for="record in product_types" v-bind:key="record.id" class="product-type-container">
               <div class="container container-left">
                  <div class="container container-left">
                     <div class="product-name-button large" :class="{notavailable: !record.is_available}" v-on:click="openProductTypeEditor (record)" >
                        <div class="icon"><img :src="record.image"></div>
                        <div class="text">{{record.name}}</div>
                     </div>
                  </div>
                  <div v-if="products[record.id]">
                     <div class="container container-left container-full container-wrap">
                        <div v-for="(product,idx) in products[record.id]" v-bind:key="idx">
                           <div class="product-button large" :class="{notavailable: !product.is_available, noDescription: !product.description}" v-on:click="editProduct (product)"  v-tooltip="product.tooltip">
                              <div class="product-button-text">{{product.name}}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { RestGet } from '@/plugins/webapi';
   import { ref, onMounted } from "vue";
   import { useRouter } from 'vue-router'
   import { userProfileStore } from '@/stores/profile';

   import { useToast } from "primevue/usetoast";
   import AdminNavigation from './AdminNavigation.vue'
   import ProductEditor from './Product/ProductEditor.vue'
   import ProductTypeEditor from './Product/TypeEditor.vue'

   const profile = userProfileStore();
   const router = useRouter();
   const toast = useToast();
   const product_types = ref();
   const products = ref ();
   const showProductTypeEditor = ref (false);
   const showProductEditor = ref (false);
   const productTypeKey = ref (0);
   const productKey = ref (0);
   const record = ref ({});

   var productAction = ref ();

   onMounted(() =>
   {
      getProductTypes();
   })

   const addProduct = function ()
   {
      record.value = {};
      productAction = "add";
      showProductEditor.value = true;
      productKey.value++;
   }

   const editProduct = function (data)
   {
      record.value = data;
      productAction = "edit";
      showProductEditor.value = true;
      productKey.value++;
   }

   const openProductTypeEditor = function (data)
   {
console.log ("DATA: ", data);
      record.value = data;
      productAction = "edit";
      showProductTypeEditor.value = true;
      productTypeKey.value++;
   }

   const saveProductTypeCB = function (summary, mesg)
   {
      toast.add ({severity: 'info', summary: summary, detail: mesg, life: 3000});
      getProductTypes();
   }

   const saveProductCB = function (summary, mesg)
   {
      toast.add ({severity: 'info', summary: summary, detail: mesg, life: 3000});
      getProducts ();
   }

   const getProductTypes = function ()
   {
      product_types.value = {};
      RestGet ("/product/type/all", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_types.value = response;
            for (var x = 0; x < product_types.value.length; x++)
            {
               var icon = product_types.value[x].icon;
               product_types.value[x].image = require (`@/assets/icons/misc/${icon}`);
               product_types.value[x].tooltip = "Create a New " + product_types.value[x].name + " Product";
            }

            getProducts();
         }
      });
   }

   const getProducts = function ()
   {
      products.value = {};
      RestGet ("/product/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var json = {};
            for (var x = 0; x < response.length; x++)
            {
               var rec = response[x];

               if (! json[rec.product_type_id])
               {
                  json[rec.product_type_id] = [];
               }

               rec.is_available = rec.is_available == 1 ? true : false;
               rec.tooltip = rec.tooltip == null ? "No Description" : rec.tooltip;
               rec.tooltip = rec.is_available == true ? rec.tooltip : "Product Not Available";
               json[rec.product_type_id].push (rec);
            }

            products.value = json;
         }
      });
   }
</script>

<style scoped>
   .menu-button
   {
      height: 30px;
      font-weight: bold;
      font-face: var(--font-face);
      font-size: .8em;
      margin-top: 10px;
      background: #19BF55;
      border: 1px solid #237C43 !important;
   }
   .menu-button:hover
   {
      background: #237C43 !important;
      color: #ffffff !important;
   }
   .col { width: 65px !important; }

   .product-name-button
   {
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
      padding: 2px 1px 2px 1px;
   }
   .product-name-button .icon
   {
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .product-name-button .icon img
   {
      height: 30px;
      width: 30px;
      opacity: 60%;
   }
   .product-name-button .text
   {
      margin-left: 10px;
      font-size: .8em;
      font-weight: bold;
   }
   .product-name-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }

   .product-type-button
   {
      width: 60px;
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
   }
   .product-type-button .icon
   {
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(3px 3px 4px #111111);
      opacity: 60%;
   }
   .product-type-button .icon img
   {
      height: 30px;
      filter: drop-shadow(3px 3px 4px #111111);
      opacity: 60%;
   }
   .product-type-button .text
   {
      margin-left: 10px;
      font-size: 1em;
      font-weight: bold;
   }

   .product-type-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }

   .product-button
   {
      width: 120px;
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      justify-content: flex-start !important;
      align-items: center;
      margin: 8px 10px 0px 10px;
      padding: 1px 2px 1px 2px;
   }
   .product-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }
   .product-button-text
   {
      margin-left: 5px;
      font-size: .7em;
      font-weight: bold;
      text-shadow: 2px 2px 3px #111111;
   }
   .product-button-badge
   {
      width: 30px;
      border-radius: 5px;
   }
   .product-button-badge img
   {
      margin-left: 5px;
      height: 20px;
      width: 20px;
      filter: drop-shadow(3px 3px 4px #111111);
      opacity: 60%;
   }
   .large { width: 150px !important; }

   .image-preview
   {
      height: 50px;
      width: 50px;
      border-radius: 5px;
   }

   .notavailable { opacity: .5; }
   .noDescription { opacity: .8; color: #05C5EA !important; }

</style>
