<template>
   <div v-if="! no_header" class="container container-row container-left">
      <div class="icon-container">
         <div class="recipe-navigation-button selector-icon"><div class="recipe-navigation-button-icon small"><img :src="props.icon"></div></div>
      </div>

      <div class="description-container">
         <div class="selector-description" v-for="(text, idx) in props.text" :key="idx">{{text}}</div>
      </div>

      <div v-if="props.save_button || props.next_button" class="button-container">
         <div v-if="!buttonDisabled" class="call-to-action-button selector-button" v-on:click="save">
            {{button.label}} <i :class="button.icon"></i>
         </div>
         <div v-else class="call-to-action-button disabled-call-to-action-button selector-button">
            {{button.label}} <i :class="button.icon"></i>
         </div>
      </div>
   </div>

   <div id="button_group" class="button-group">
      <ButtonGroup :id="id" :buttons=props.buttons v-on:click="selected" :multiple="multiple" :small="props.small" :wide="props.wide" :default="props.default" :scroll="props.scroll" :rollover="props.rollover"  />
   </div>
</template>

<script setup>
   import { ref, defineEmits, defineProps, onMounted, watch } from 'vue';
   import ButtonGroup from "../ButtonGroup";

   const buttonDisabled = ref (true);
   const button = ref ({});
   var multiple = ref (false);
   var id = ref ();

   const props = defineProps ({id: [String], save_button: [Boolean], next_button: [Boolean], ok_button: [Boolean], rollover: [Boolean],
                               no_header: [Boolean], multiple: [Boolean], small: [Boolean], wide: [Boolean], button_state: [Boolean],
                               minimum: [Number, String], maximum: [Number, String],
                               scroll: [Number, String], icon: [String], default: [Object], buttons: [Object], text: [Object]});

   onMounted (() =>
   {
      init();
   });

   watch(() => props.button_state, () =>
   {
      buttonDisabled.value = !props.button_state;
   });

   const init = function()
   {
      id.value = "button_group";
      if (props.id)
      {
         id.value = props.id;
      }

      button.value = {label: "Save", icon: "fa-solid fa-floppy-disk"};
      if (props.next_button)
      {
         button.value = {label: "Next", icon: "fa-solid fa-circle-right"};
      }
      else if (props.ok_button)
      {
         button.value = {label: "OK", icon: "fa-solid fa-circle-check"};
      }

      if (props.multiple || props.maximum || props.minimum)
      {
         multiple.value = true;
      }
   }

   const emit = defineEmits(['change', 'save']);
   const selected = function (obj, state)
   {
      emit ('change', obj);

console.log ("SELECTED OBJ: ", obj, state);

      //
      //
      //
      if (props.maximum)
      {
         buttonDisabled.value = !props.button_state;
      }
      else
      {
         buttonDisabled.value = !props.button_state;
      }
   }

   const save = function (obj)
   {
      emit ('save', obj);
   }
</script>

<style scoped>
   .selector-description {font-size: .7em; margin-bottom: 5px; }
   .selector-icon {height: 40px; width: 40px; padding: 0px; position: absolute; left: 0px;}
   .p-button-text {font-size: .6em !important; }

   .icon-container {width: 10%; margin-bottom: 10px;}
   .description-container
   {
      padding-right: 2px;
      padding-left: 10px;
      width: 75%;
      display: flex;
      flex-direction: column;
    }
   .button-container {width: 15%; position: absolute; right: 5px;
   }
   .selector-button
   {
      width: 100% !important;
      height: 25px !important;
      font-size: .6em !important;
      margin: 0px !important;
   }
   .button-group
   {
      border-top: 1px solid gray;
      padding: 0px;
   }
</style>
