<template>
   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
         <h1>Manage Ingredient Attributes</h1>
         <br />
         <br />
         <br />

         <div class="container container-left">
            <div class="input-label ">New Attribute</div>
            <InputText class="name-col" type="text" v-model="attribute" size="50" @keyup.enter="inputCB" />
            <Button :label="buttonLabel" icon="pi pi-plus-circle" @click="inputCB" class="p-button-text" :disabled="!attribute" />
         </div>

         <hr />
         <div class="container container-left container-column-wrap container-frame" style="min-height: 200px">
            <div v-for="(chip, idx) in attributes" v-bind:key="idx">
               <div class="container attribute-chip" v-on:click="setAttribute (chip)">
                  {{chip.name}}
                  <div class="attribute-chip-icon" v-on:click="deleteAttribute (chip)"><i class="fa-solid fa-circle-xmark"></i></div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { RestGet, RestPost, RestPut, RestDelete } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'

   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import AdminNavigation from '../AdminNavigation.vue'

   const profile = userProfileStore();
   const router = useRouter();
   const attributes = ref([]);
   const attribute = ref();
   const buttonLabel = ref ("Add");
   var edit = null;

   onMounted(() =>
   {
      getAttributes();
   })

   const setAttribute = function (chip)
   {
      attribute.value = chip.name;
      buttonLabel.value = "Edit";
      edit = chip;
   }

   const inputCB = function ()
   {
      if (edit === null)
      {
         addAttribute();
      }
      else
      {
         editAttribute();
      }
   }

   const getAttributes = function ()
   {
      attributes.value = [];
      RestGet ("/attribute/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            attributes.value = response;
         }
      });
   }

   const addAttribute = function ()
   {
      var json = {name: attribute.value};
      RestPost ("/attribute/", profile.token(), json, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            attributes.value.push ({id: response.id, name: attribute.value});
            buttonLabel.value = "Add";
            attribute.value = null;
            edit = null;
            return;
         }
      });
   }

   const editAttribute = function ()
   {
      var json = {name: attribute.value};
      var uri = "/attribute/" + edit.id;
      RestPut (uri, profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            for (var x in attributes.value)
            {
               if (edit.id == attributes.value[x].id)
               {
                  attributes.value[x].name = attribute.value;
                  break;
               }
            }

            buttonLabel.value = "Add";
            attribute.value = null;
            edit = null;
            return;
         }
      });
   }

   const deleteAttribute = function (chip)
   {
      var uri = "/attribute/" + chip.id;
      RestDelete (uri, profile.token(), {}, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            for (var x = 0; x < attributes.value.length; x++)
            {
               if (chip.id == attributes.value[x].id)
               {
                  if (x == 0)
                  {
                     attributes.value.shift ();
                  }
                  else
                  {
                     attributes.value.splice (x, x);
                  }
                  break;
               }
            }

            buttonLabel.value = "Add";
            attribute.value = null;
            edit = null;
            return;
         }
      });
   }
</script>

<style>

.attribute-chip
{
   height: 30px;
   width: auto;
   margin: 2px 10px 2px 10px;
   padding: 1px 3px 1px 3px;
   background: #545098;
   font-size: .8em;
   font-weight: bold;
   border: 1px solid var(--header-border-color);
   border-radius: 5px;
}
.attribute-chip:hover
{
   cursor: pointer;
   background: #484482;
}

.attribute-chip-icon
{
   margin-top: 3px;
   margin-left: 10px;
   font-size: .8em;
}

</style>
