<template>
   <Dialog header="Thank You" v-model:visible="showDialog" :style="{width: '55vw'}">
      <div class="text">
         Thank you for filling in the skin survey. We will use this information to better suggest products for your use.
      </div>

      <template #footer>
         <router-link to="/home">
            <Button label="Lets Craft" icon="pi pi-check" autofocus>Lets Craft &nbsp; <i class="fa-solid fa-palette"></i></Button>
         </router-link>
      </template>
   </Dialog>

   <div class="page">
      <LeftNavigation inactive />

      <div class="content content-top">
         <div class="card card-full">
            <h1>Customer Skin Survey</h1>

            <TabView ref="tabview" v-model:activeIndex="active">
               <TabPanel header="Gender and Age Group">
                  <div class="text">
                     This survey asks 5 questions which will take less than a minute to answer. By answering the survey, we will know
                     more about you and what your goals are. <br />
                     <br />

                     First, lets know about you. Your age and gender will have an impact on the type of
                     products suggested for you and the type of ingredients offered.

                     <Divider />

                     <div class="container container-left">
                        <div class="input-label">What is your gender?</div>
                        <Dropdown v-model="record.gender" :options="genders" optionLabel="name" optionValue="name" placeholder="What is your gender?" />
                     </div>

                     <div class="container container-left">
                        <div class="input-label">What is your age group?</div>
                        <Dropdown v-model="record.age_group" :options="age_groups" optionLabel="name" optionValue="value" placeholder="What is your age group?" />
                     </div>

                     <Divider />

                     <div class="container container-full container-center">
                        <Button class="p-button-rounded p-button-primary menu-button button-text" :disabled="demograpicDisabled" v-on:click="next">
                           Next Question <i class="fa-solid fa-circle-right"></i>
                        </Button>
                     </div>
                  </div>
               </TabPanel>

               <TabPanel header="Skin Type" :disabled="tabdisabled(1)">
                  <div class="text">
                     According to the American Academy of Dermatology (AAD), there are five primary types of skin: 
                     oily, dry, normal, combination, and sensitive. Each skin type has its own set of unique 
                     characteristics and needs that can affect the look and feel of your complexion. By first 
                     understanding what type of skin you have, you can begin to make informed decisions on the type 
                     of products and ingredients which will work best for you.<br /><br />
                     Pick the skin type below that best describes your skin.
                     <Divider />

                     <div class="container container-left container-full">
                        <div class="input-label">What Is Your Skin Type?</div>
                        <div class="container container-column container-grow">
                           <div v-for="(category, index) in skin_types" :key="category.option" class="flex align-items-center selection-item">
                              <div class="container container-left container-20">
                                 <RadioButton v-model="record.skintype" :inputId="category.option + index" name="types" :value="category.option" />
                                 <label :for="category.option" class="ml-2">{{ category.option }}</label>
                              </div>
                              <div class="container container-left container-full skin-type-details">{{category.details}}</div>
                           </div>
                        </div>
                     </div>

                     <Divider />

                     <div class="container container-full container-center">
                        <Button class="p-button-rounded p-button-primary menu-button button-text" :disabled="!record.skintype" v-on:click="active = 2">
                           Next Question <i class="fa-solid fa-circle-right"></i>
                        </Button>
                     </div>
                  </div>
               </TabPanel>

               <TabPanel header="Skin Goals" :disabled="tabdisabled(2)">
                 <div class="text">
                     Knowing what your skin type is, helps us know your goals better. However, you may have multiple goals in mind. Below is a list of standard
                     goals for peoples skin care regiem. Let us know which goals you have in mind. This will help us communicate with you with your interests in mind.
                     <Divider />

                     <div class="container container-left container-full">
                        <div class="input-label">What Are Your Skin Goals?</div>
                        <div class="container container-column">
                           <div v-for="(category, index) in skin_goals" :key="category.key" :id="'goals_'+index" class="flex align-items-center selection-item">
                              <div class="container container-left container-20">
                                 <Checkbox v-model="record.skingoals" :inputId="category.key + index" name="goals" :value="category.key" />
                                 <label :for="category.key" class="ml-2">{{ category.option }}</label>
                              </div>
                              <div class="container container-left container-full skin-type-details">{{category.details}}</div>
                           </div>
                        </div>
                     </div>

                     <Divider />

                     <div class="container container-full container-center">
                        <Button class="p-button-rounded p-button-primary menu-button button-text" :disabled="!record.skingoals" v-on:click="next">
                           Next Question <i class="fa-solid fa-circle-right"></i>
                        </Button>
                     </div>
                  </div>
               </TabPanel>

               <TabPanel header="Allergies" :disabled="tabdisabled(3)">
                 <div class="text">
                     Skin related allergies are a greater issue than ever before.Skin allergies, also known as allergic contact dermatitis,
                     occur when the skin comes into contact with a substance that triggers an allergic reaction. Common allergens include 
                     metals (such as nickel), fragrances, preservatives, and certain chemicals. Consumers who use skin care products 
                     should be aware of the ingredients in the products they use. <br />
                     <br />

                     Letting us know what allergies you have, will allow us to provide products and ingredients which should not 
                     trigger your allergy. Below are some of the most common allergens, select all that apply.
                     <Divider />

                     <div class="container container-left container-full">
                        <div class="input-label">What Allergens are You Aware of?</div>
                        <div class="container container-column container-grow">
                           <div v-for="(category, index) in allergies" :key="category.key" :id="'goals_'+index" class="flex align-items-center selection-item container container-row">
                              <div class="container container-left container-20">
                                 <Checkbox v-model="record.allergies" :inputId="category.key + index" name="allergies" :value="category.key" />
                                 <label :for="category.key" class="ml-2">{{ category.option }}</label>
                              </div>
                              <div class="container container-left container-full skin-type-details">{{category.details}}</div>
                           </div>

                           <div key="other" class="flex align-items-center selection-item container container-row">
                              <div class="container container-left container-20">
                                 <Checkbox v-model="record.allergies" inputId="other" name="allergies" value="other" />
                                 <label for="other" class="ml-2">Other</label>
                              </div>
                              <div class="container container-left container-full skin-type-details">
                                 <InputText id="other" type="text" size="40" v-model="other" placeholder="Provide a list of allergens"/>
                              </div>
                           </div>
                        </div>
                     </div>

                     <Divider />

                     <div class="container container-full container-center">
                        <Button class="p-button-rounded p-button-primary menu-button button-text" :disabled="!record.allergies" v-on:click="next">
                           Next Question <i class="fa-solid fa-circle-right"></i>
                        </Button>
                     </div>
                  </div>
               </TabPanel>

               <TabPanel header="Skin Care Experience" :disabled="tabdisabled(4)">
                  <div class="text">
                     Knowing how experienced you are with skin care produucts gives us insight on how best to share information with you
                     regarding products and ingredients.
                     <Divider />
                     <div class="container container-left container-full">
                        <div class="input-label">What Is Your Skincare Experience?</div>
                        <div class="container container-column">
                           <div v-for="(category, index) in experience" :key="category.option" class="flex align-items-center selection-item">
                              <div class="container container-left container-20">
                                 <RadioButton v-model="record.experience" :inputId="category.option + index" name="types" :value="category.option" />
                                 <label :for="category.option" class="ml-2">{{ category.option }}</label>
                              </div>
                              <div class="container container-left container-full skin-type-details">{{category.details}}</div>
                           </div>
                        </div>
                     </div>

                     <Divider />

                     <div class="container container-full container-center">
                        <Button class="p-button-rounded p-button-primary menu-button button-text" :disabled="!record.experience" v-on:click="save">
                           Save Survey <i class="fa-solid fa-floppy-disk"></i>
                        </Button>
                     </div>
                  </div>
               </TabPanel>
            </TabView>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { computed, ref, onMounted } from "vue";
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router';
   import { RestPost } from '@/plugins/webapi';
   import { useRoute } from 'vue-router'

   import LeftNavigation from './LeftNavigation.vue'
   import TabView from 'primevue/tabview'
   import TabPanel from 'primevue/tabpanel'
   import Dropdown from 'primevue/dropdown'
   import Divider from 'primevue/divider'
   import Dialog from 'primevue/dialog'
   import Button from 'primevue/button'
   import RadioButton from 'primevue/radiobutton'
   import Checkbox from 'primevue/checkbox'
   import InputText from 'primevue/inputtext';

   const route = useRoute();
   const router = useRouter();
   const profile = userProfileStore();

   const demograpicDisabled = computed(() => !record.value.gender || !record.value.age_group);

   const other = ref ("");
   const active = ref (0);
   const showDialog = ref (false);
   const record = ref ({skintype: ''});
   const genders = ref ([{name: "Male"}, {name: "Female"}]);
   const age_groups = ref ([{name: "19 and Under", value: 19},
                            {name: "20 to 29", value: 20},
                            {name: "30 to 39", value: 30},
                            {name: "40 to 49", value: 40},
                            {name: "50 to 59", value: 50},
                            {name: "60 and Over", value: 60}]);
   const skin_types = ref ([{option: "Oily", details: "Oily skin produces an excess of sebum that causes the skin to \
                                                       appear shiny and feel greasy, especially throughout the \
                                                       forehead, nose, and chin."},
                            {option: "Dry", details: "Dry skin generally produces less natural oils than other \
                                                      types of skin. This may cause it to appear dull and become \
                                                      rough, flaky, or even scaly. It often feels tight or less \
                                                      elastic, noticeably dehydrated, and may be prone to showing \
                                                      more visible fine lines. In addition, it may become itchy \
                                                      or irritated."},
                            {option: "Normal", details: "Normal skin is balanced—feeling neither too dry nor too \
                                                         oily. It is not prone to breakouts, flakiness, or feeling \
                                                         greasy or tight. "},
                            {option: "Combination", details: "Combination skin includes areas that are dry as \
                                                              well as oily—with the T-zone commonly being oily, and \
                                                              the cheeks being either dry or normal. This skin type \
                                                              can vary during different seasons of the year, and due \
                                                              to various factors, such as stress or hormone fluctuation"},
                            {option: "Sensitive", details: "Sensitive skin is often referred to as a skin type, but \
                                                            it’s possible to have oily sensitive skin, dry sensitive \
                                                            skin, or normal sensitive skin. Sensitive skin is typically \
                                                            more vulnerable to external irritants and may be triggered \
                                                            by certain ingredients—like dyes or fragrance—as well as \
                                                            environmental factors."}]);

   const skin_goals = ref ([{option: "Dryness", key: "dryness", details: "I want to address itchiness and dryness, as it \
                                                                          makes my skin uncomfortable. I need a product to \
                                                                          hydrate the skin, so my skin will look and feel better."},
                            {option: "Dullness", key: "dullness", details: "My skin looks dull and I need skin products which will \
                                                                            help exfoliate and hydrate my skin. I want my skin to improve \
                                                                            the appearance of my skin and make it look radiant."},
                            {option: "Signs of Aging", key: "aging", details: "I want to keep my skin looking young and healthy. I want to \
                                                                               address any fine lines or wrinkles that may appear. I also \
                                                                               want to continue or start a regime which addresses the potential \
                                                                               impacts of the environment on my skin to protect it as best possible."},
                            {option: "Uneven Skin Tone", key: "tone", details: "I have some hyperpigmentation which I want to address."},
                            {option: "Maintain", key: "maintain", details: "I want a regime that helps me maintain my skin, keeps it hydrated, exfoliated, \
                                                                            and looking (and feeling) amazing."}]);

   const allergies = ref ([{option: "None", key: "none", details: "I don't have any diagnosed allergies, nor am I aware of any allergies."},
                            {option: "Fruits", key: "fruits", details: "I have allergies to certain fruits."},
                            {option: "Nuts", key: "nuts", details: "I have a nut allergy."},
                            {option: "Colours", key: "colours", details: "I have certain allergies to man-made colours."},
                            {option: "Fragrances", key: "fragrances", details: "I react to fragrances in products, so I prefer fragrance free."}]);

   const experience = ref ([{option: "None", key: "none", details: "I don't have much or any experience with skincare products."},
                            {option: "Some", key: "some", details: "I use skincare products a few times a month."},
                            {option: "Moderate", key: "moderate", details: "I use skincare products regularly and have a basic skin care routine."},
                            {option: "Expert", key: "expert", details: "I have a sophisticated skincare regime and use several different products for my routine."}]);

   onMounted (() =>
   {
      //
      // This is a hacky way to force a page refresh.
      //
      if (route.query.fresh)
      {
         window.location.replace ("/home");
      }
   })

   const tabdisabled = function (tab) { return tab > active.value; }

   const next = function()
   {
      active.value = active.value + 1;
      tabdisabled[active.value] = false;
   }

   const save = function ()
   {
      var json = {customer_id: profile.id(), survey: JSON.stringify (record.value)};

      RestPost ("/survey", profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            showDialog.value = true;
         }
     });
   }

</script>

<style scoped>

.required {color: red}
.text
{
   font-size: 1.3em !important; 
   margin: 5px 5px 0px 5px;
   line-height: 1.5;
}
.button-text { font-size: 1.1em !important; }
.p-tabview { width: 100%; }

.skin-type-details
{
   margin-left: 20px;
}

.selection-item:hover
{
   background: #252343;
}

</style>
