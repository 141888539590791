<template>
   <ToastTip />
   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
       TOP ROW - SHOW THE INGREDIENT TYPES
       <br />
       NEXT ROW: INGREDIENT LIST
       <br />
       THEN EDIT THE CHECKLIST OF INGREDIENT TO PRODUCT LIST.
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { RestGet } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'
   import AdminNavigation from '../AdminNavigation.vue'

   const ingredient_types = ref();
   const profile = userProfileStore();
   const router = useRouter();

   onMounted(() =>
   {
      getIngredientTypes();
   })

   const getIngredientTypes = function ()
   {
      var uri = "/ingredient/type/";
      ingredient_types.value = [];

      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient_types.value = response;
         }
      });
   }
</script>

<style>
</style>
