<template>
   <Dialog header="Create Your First Recipe" v-model:visible="showCreateRecipeDialog" :style="{width: '55vw'}">
      <div class="text">
      It appears you have not crafted any recipes yet. Click any of the buttons below to start crafting your first recipe.
      </div>

      <div class="container container-row container-left container-space-evenly contaier-full" style="margin-top: 20px;">
         <div v-for="(product, idx) in product_types" :key="idx">
            <div v-if="product.is_available">
               <div class="recipe-navigation-button product-button" v-tooltip.top="'Craft A ' + product.singular_name" v-on:click="craftProduct (product.id)">
                  <div class="recipe-navigation-button-icon"><img :src="product.image"></div>
                  <div class="recipe-navigation-button-text">{{product.name}}</div>
               </div>
            </div>
         </div>
      </div>

      <template #footer>
         <Button label="No Thanks, I Want to Explore Your Site First!" icon="pi pi-times" @click="showCreateRecipeDialog = false" class="p-button-text"/>
      </template>
   </Dialog>

   <div class="page">
      <LeftNavigation />

      <div class="content">
         <div class="container container-row container-left container-space-evenly contaier-full">
            <div v-for="(product, idx) in product_types" v-bind:key="idx">
               <div v-if="product.count == 0">
                  <div class="disabled-recipe-navigation-button">
                     <div class="recipe-navigation-button-icon"><img :src="product.image"></div>
                     <div class="recipe-navigation-button-text">{{product.name}}</div>
                  </div>
               </div>
               <div v-else>
                  <div class="recipe-navigation-button" v-badge.info="product.count" v-on:click="showCarousel (idx)">
                     <div class="recipe-navigation-button-icon"><img :src="product.image"></div>
                     <div class="recipe-navigation-button-text">{{product.name}}</div>
                  </div>
               </div>
            </div>
         </div>
  
         <hr />

         <div v-if="header" class="recipe-carousel">
            <Carousel :value="recipes" :numVisible="3" :numScroll="2" :circular="true">
               <template #header>
                  <div class="recipe-card-header">{{header}}</div>
               </template>
               <template #item="slotProps">
                  <div class="product-item">
                     <div class="product-item-content">
                        <div class="product-item-title">{{slotProps.data.name}}</div>
                        <div v-if="slotProps.data.description" class="product-item-description">{{slotProps.data.description}}</div>
                        <div v-else class="product-item-description">No Description</div>

                        <div class="container container-left">
                           <Button class="p-button-rounded action-icon" v-tooltip.top="'Edit Recipe'"><i class="fa-solid fa-flask"></i></Button>
                           <Button class="p-button-rounded action-icon" v-tooltip.top="'Reorder'"><i class="fa-solid fa-arrows-rotate"></i></Button>
                           <Button class="p-button-rounded action-icon" v-tooltip.top="'Share Recipe'"><i class="fa-solid fa-share-nodes"></i></Button>
                        </div>
                     </div>
                  </div>
               </template>
            </Carousel>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { useRouter } from "vue-router";
   import { RestGet } from '@/plugins/webapi';
   import LeftNavigation from './LeftNavigation.vue'
   import { userProfileStore } from '@/stores/profile';
   import Carousel from 'primevue/carousel';
   import Button from 'primevue/button';
   import Dialog from 'primevue/dialog';

   const router = useRouter();
   const profile = userProfileStore();
   const showCreateRecipeDialog = ref (false);
   const recipes = ref ([]);
   const product_types = ref ([]);
   const header = ref ();

   onMounted (() =>
   {
      profile.load();
      getUserRecipes();
   })

   const craftProduct = function (id)
   {
      showCreateRecipeDialog.value = false;

      router.push ("/product/" + id);
      return;
   }

   const showCarousel = function (idx)
   {
      var name = product_types.value[idx].name.slice(0,-1);
      header.value = "My " + name + " Recipes";
   }

   const getUserRecipes = function ()
   {
      recipes.value = [];

      var uri = "/recipe/user/" + profile.id();
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            recipes.value = response;

            getProductTypes();

console.log ("RECIPES: ", recipes.value);
         }
      });
   }

   const getProductTypes = function ()
   {
      RestGet ("/product/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var count = 0;
            product_types.value = response;
            for (var x = 0; x < product_types.value.length; x++)
            { 
               var icon = product_types.value[x].icon;

               product_types.value[x].image = require (`@/assets/icons/misc/${icon}`);
               product_types.value[x].is_available = product_types.value[x].is_available == 1 ? true : false;
               product_types.value[x].singular_name = product_types.value[x].name.slice (0,-1);

               product_types.value[x].count = 0;
               for (var y = 0; y < recipes.value.length; y++)
               {
                  if (recipes.value[y].product_type_id == product_types.value[x].id)
                  {
                     product_types.value[x].count++;
                     count++;
                  }
               }
            }

            if (count == 0)
            {
               showCreateRecipeDialog.value = true;
            }
         }
       });
   }
</script>

<style scoped>
   .product-button
   {
      width: 50px !important;
      height: 50px !important;
   }

   .recipe-navigation-button
   {
      font-size: .7em !important;
      display: flex;
      align-items: center;
      align-content: center;
      width: 7vw;
      height: 5vw;
      background-color: #545098;
      border: 1px solid #484482;
      text-align: center;
      position: relative;
      border-radius: 1vw;
      box-shadow: 2px 2px 5px #302D56;
      margin-bottom: 10px;
   }
   .recipe-navigation-button:hover
   {
      background: #756FD4;
      cursor: pointer;
   }
   .disabled-recipe-navigation-button
   {
      font-size: .7em !important;
      display: flex;
      align-items: center;
      align-content: center;
      width: 7vw;
      height: 5vw;
      background-color: #545098;
      border: 1px solid #484482;
      text-align: center;
      position: relative;
      border-radius: 1vw;
      box-shadow: 2px 2px 5px #302D56;
      margin-bottom: 10px;
      cursor: not-allowed;
      opacity: .5;
   }
   .recipe-navigation-button-icon
   {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.5;
      top:0;
      left:0;
   }
   .recipe-navigation-button-icon img
   {
      margin-top: 10%;
      height: 80%;
      filter: drop-shadow(5px 5px 3px #0F0D30);
   }
   .recipe-navigation-button-text
   {
      width: 100%;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      z-index: 1;
      color: #ffffff;
      font-variant: small-caps;
      font-weight: bold;
      font-size: 1.2em;
      text-shadow: 3px 3px 3px #0F0D30;
   }

   .recipe-carousel
   {
      margin: 20px 0px 0px 0px;
      max-width: 90%;
   }

   .recipe-card-header
   {
      margin: 5px 0px 10px 40px;
      font-size: 1.2em;
      font-weight: bold;
      font-variant: small-caps;
      text-shadow: 3px 3px 3px #0F0D30;
   }

   .product-item .product-item-content
   {
      border: 2px solid #06C4E9;
      background-color: #545098;
      border-radius: 10px;
      margin: .3rem;
      padding: 10px;
   }

   .product-item-title
   {
      font-size: 1.2em;
      font-weight: bold;
      font-variant: small-caps;
      text-shadow: 3px 3px 3px #0F0D30;
      margin: 10px;
   }
   .product-item-description
   {
      font-size: 1em;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
      margin: 10px 2px 2px 5px;
   }

   .text {font-size: 1.1em;}
   .p-tooltip { z-index: 5; }
</style>
