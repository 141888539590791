<template>
   <div class="page">
      <div v-if="content.title" class="container container-column container-center container-full">
         <div v-if="route.query.type == 'logout'" class="card" style="margin-top: 40px">
            <div class="title">{{content.title}}</div>
            <div class="body">{{content.body}}</div>
 
            <hr />

            <div class="container container-left container-full">
               <div class="container container-grow">
                  <router-link to="/login">
                     <div class="menu-button">Log Back In <i class="fas fa-right-to-bracket"></i></div>
                  </router-link>
               </div>
               <div class="container container-grow">
                  <router-link to="/contact">
                     <div class="menu-button">Contact Us<i class="fa-solid fa-at"></i></div>
                  </router-link>
               </div>
               <div class="container container-grow">
                  <router-link to="/store">
                     <div class="menu-button">Visit the Store <i class="fa-solid fa-store"></i></div>
                  </router-link>
               </div>
            </div>
         </div>

         <div v-if="route.query.type == 'timeout'" class="card" style="margin-top: 40px">
            <div class="title">{{content.title}}</div>
            <div class="body">{{content.body}}</div>
 
            <hr />

            <div class="container container-left container-full">
               <div class="container container-grow">
                  <router-link to="/login">
                     <div class="menu-button">Log Back In <i class="fas fa-right-to-bracket"></i></div>
                  </router-link>
               </div>
               <div class="container container-grow">
                  <router-link to="/contact">
                     <div class="menu-button">Contact Us<i class="fa-solid fa-at"></i></div>
                  </router-link>
               </div>
               <div class="container container-grow">
                  <router-link to="/store">
                     <div class="menu-button">Visit the Store <i class="fa-solid fa-store"></i></div>
                  </router-link>
               </div>
            </div>
         </div>

         <div v-else-if="route.query.type == 'disabled'" class="card">
            <div class="title">{{content.title}}</div>
            <div class="body">{{content.body}}</div>
 
            <hr />

            <div class="container container-left container-full">
               <div class="container container-grow">
                  <router-link to="/contact">
                     <div class="menu-button">Contact Us<i class="fa-solid fa-at"></i></div>
                  </router-link>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from 'vue'
   import { getAuth } from "firebase/auth";
   import { useRoute } from 'vue-router'
   import { userSessionStore } from '@/stores/session';
   import { userProfileStore } from '@/stores/profile';
   import { loginStatusStore } from '@/stores/login.js'

   const session = userSessionStore();
   const profile = userProfileStore();
   const route = useRoute();
   const content = ref ({title: '', body: ''});
   const auth = getAuth();  

   if (route.query.type == "timeout")
   {
      content.value = {title: "Logged Out Due to Inactivity",
                       body: "You have been logged due to inactivity. \n \n \
                              If you do not do anything and are idle, our platform will eventually log you out. \
                              This is for both our security and yours.\n \n \
                              Please log back in when you are ready."};

   }
   else if (route.query.type == "logout")
   {
      content.value = {title: "Thank You",
                       body: "Thank you for using our website! \n\n \
                              We are grateful for your support and appreciate the time you have \n \
                              taken to visit us. We hope that you enjoy the products we offer, \n \
                              and would love to hear feedback from you on any improvements you feel \
                              would improve our offerings and experience. \n\n \
                              If you have any suggestions or feedback, please do not hesitate to reach \n \
                              out to us. We value your input and look forward to hearing from you. \n\n \
                              Thank you again for choosing our website, and we hope to see you again soon!"};
   }
   else if (route.query.type == "disabled")
   {
      content.value = {title: "Account Disabled",
                       body: "You have been logged due to your account being disabled. \n \
                              This may be in response to a problem we have noticed with the activity of your account, \
                              or for the protection of your information. \n \n \
                              Please contact us as soon as possible."};
   }

   console.log ("QUERY PARAMS: ", route.query);

   onMounted (() =>
   {
      logout();
   })

   const logout = function ()
   {
      loginStatusStore.loggedIn = false;
      loginStatusStore.isAdmin = false;

      session.clear();
      profile.clear();

      auth.signOut().then (() =>
      {
         profile.clear();
      });
   }
</script>

<style scoped>
   .title 
   {
      font-size: 1.2em;
      font-weight: bold;
      font-variant: small-caps;
      text-shadow: 2px 2px 3px rgba(0,0,0,.5);
      margin: 3px 10px 20px 10px;
      text-decoration: underline;
   }

   .body
   {
      font-size: .8em;
      text-shadow: 2px 2px 3px rgba(0,0,0,.5);
      margin: 3px 10px 3px 10px;
      white-space: pre-line;
      display: block;
   }

   .button
   {
      background: #C4B0F0;
      height: 25px;
      font-weight: bold;
      font-size: .7em;
      text-align: center;
      color: #000000;
      border-radius: 10px;
      margin: 10px 5px 10px 5px;
      padding: 3px 10px 3px 10px;
   }
   .button:hover
   {
      background: #E4D7FF !important;
      cursor: pointer;
   }
</style>
