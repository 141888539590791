<template>
   <div class="container">
     <button v-on:click="shrinkAndShift()">Click me</button>
     <div class="content">
       <!-- Your content here -->
     </div>
   </div>
</template>

<script setup>
   //import { ref, defineEmits, defineProps, onMounted } from 'vue';

   const shrinkAndShift = function()
   {
      const container = document.querySelector('.container');
      const content = document.querySelector('.content');
      container.classList.add('shrink-shift');
      content.classList.add('shrink-shift');
   }
</script>

<style scoped>
.container {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}

.content {
  transition: all 0.5s ease;
}

.shrink-shift {
  transform: translate(-50%, -50%) scale(0.6);
}
</style>
