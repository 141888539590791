<template>
   <div class="container container-left">
      <div class="input-label required" v-tooltip.top="'Required Field'">{{label}}</div>
      <InputText id="nickname" type="text" size="30" v-model="nickname" disabled />

      <Dropdown v-model="suggestion" :options="nicknames" optionValue="id" optionLabel="name" v-on:change="setNickname" placeholder="Select a Nickname" />

      <Button v-on:click="getNicknameSuggestions" class="fetch-button">Fetch More</Button>

      <div v-if="nickname_checked">
         <div v-if="nickname_taken" class="checkmark" >
            <div class="icon" v-tooltip="'Nickname Taken'"><i class="fa-solid fa-square-xmark fa-2x" style="color: #FF0000"></i></div>
         </div>
         <div v-else class="checkmark">
            <div class="icon" v-tooltip="'Nickname Available'"><i class="fa-solid fa-square-check fa-2x" style="color: #00C200"></i></div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted, watchEffect, defineProps, defineEmits } from 'vue';
   import { userProfileStore } from '@/stores/profile';
   import { RestGet } from '@/plugins/webapi';
   import Button from 'primevue/button';
   import Dropdown from 'primevue/dropdown';
   import InputText from 'primevue/inputtext';
   import { useRouter } from 'vue-router'

   const props = defineProps ({label: [String, Number], default: [String], modelValue: [String, Number], respect: [Boolean], id: [Number]});
   const emit = defineEmits(['update:modelValue']);
   const profile = userProfileStore();
   const router = useRouter();

   const suggestion = ref("");
   const label = ref ();
   const nickname_checked = ref (false);
   const nickname_taken = ref (false);
   const nickname = ref (props.default);
   const nicknames = ref ([]);

   onMounted(() =>
   {
      label.value = !props.label ? "Nickname" : props.label;

      getNicknameSuggestions();
   })

   //
   // This is needed to catch the properties passed in. I cant do this in onMounted.
   //
   watchEffect(() =>
   {
      if (props.default)
      {
         nickname.value = props.default;
      }
   })

   const setNickname = function (val)
   {
      if (nicknames.value && nicknames.value[val.value] && nicknames.value[val.value].name)
      {
         nickname.value = nicknames.value[val.value].name;

         emit ('update:modelValue', nickname.value);
      }

      checkNickname();
   }

   const getNicknameSuggestions = function()
   {
      nickname_checked.value = false;
      nickname_taken.value = false;

      RestGet ("/nickname/random/50", profile.token(), {}, function (status, response)
      {
         nicknames.value = response;

         if (! props.respect)
         {
            var idx = ref (0);
            setNickname (idx);
         }
      });
   }

   const checkNickname = function()
   {
      //
      // No value, no need to check.
      //
      if (! nickname.value)
      {
         return;
      }

      //
      // Go find accounts with the same nickname.
      //
      nickname_checked.value = false;
      nickname_taken.value = false;

      var uri = "/customer/nickname/" + nickname.value;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            if (response && Object.keys(response).length === 0)
            {
               nickname_checked.value = true;
               nickname_taken.value = false;

               emit ('update:modelValue', nickname.value);
               return;
            }

            //
            // Don't throw an error if the nickname is ours. :D
            //
            if (props.id)
            {
               if (props.id == response[0].id)
               {
                  nickname_checked.value = false;
                  nickname_taken.value = false;
               }
               else
               {
                  nickname_checked.value = true;
                  nickname_taken.value = true;
               }
            }
            else
            {
               nickname_checked.value = true;
               nickname_taken.value = true;
            }
         }
      });
   }
</script>

<style scoped>
.checkmark
{
   display: flex;
   align-items: center;
   margin-top: 5px;
}

.icon { margin: 0px 5px 0px 5px; }
.error { color: red; }
.ok { color: #0DFF00; }
.required {color: red; }

.fetch-button
{
   margin-left: 20px;
   max-height: 30px;
}
</style>
