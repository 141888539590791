<template>
   <ToastTip />

   <Dialog position="top" :header="header" v-model:visible="displaySubTypeDialog" :style="{width: '60vw'}">
      <div class="container container-column">
         <div class="container container-left">
            <div class="input-label label-col"></div>
            <div class="input-label name-col">Name</div>
            <div class="input-label description-col">Description</div>
         </div>

         <div v-for="(rec, idx) in subtypes" v-bind:key="idx" class="container container-left">
            <div class="input-label label-col">{{rec.label}}</div>
            <InputText class="name-col" type="text" v-model="rec.name" style="width: 120px"/>
            <InputText class="description-col" type="text" v-model="rec.description" style="width: 200px" />
            <div v-if="rec.id" class="delete-record-icon" v-on:click="deleteRecord (idx, rec)" v-tooltip="'Delete Record'"><i class="fa-solid fa-circle-xmark"></i></div>
         </div>
      </div>

      <template #footer>
         <Button label="Close" icon="pi pi-times" class="p-button-text"/>
         <Button label="Save" :class="dialogClass" icon="pi pi-save" @click="saveSubTypes" autofocus />
      </template>
   </Dialog>

   <Dialog position="top" :header="header" v-model:visible="displayEdit" :style="{width: '50vw'}">
      <div class="container container-left">
         <div class="input-label">Name</div>
         <InputText id="name" type="text" size="50" v-model="record.name" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Code</div>
         <InputText id="code" type="text" size="8" v-model="record.code" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Tooltip</div>
         <InputText id="tooltip" type="text" size="50" v-model="record.tooltip" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Description</div>
         <Textarea id="description" v-model="record.description" :autoResize="true" rows="5" cols="49" :disabled="disabled"  />
      </div>

      <template #footer>
         <Button label="Close" icon="pi pi-times" @click="closeDialog" class="p-button-text"/>
         <Button :label="dialogLabel" :class="dialogClass" icon="pi pi-save" @click="saveData" :disabled="saveDisabled" autofocus />
      </template>
   </Dialog>

   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
         <div class="container container-left container-align-center container-full">
            <div class="container container-left container-align-center container-full">
               <div class="container container-left">
               <h1>Manage Inventory</h1>
               </div>
               <div class="container container-right container-grow container-gap-10">
                  <Button @click="openDialog ('new', 0)" class="p-button-success p-button-sm menu-button">
                     Add Inventory Item &nbsp; <i class="fa-solid fa-square-plus"></i>
                  </Button>
               </div>
            </div>
         </div>

        <DataTable ref="table" :value="inventory" responsiveLayout="scroll" dataKey="id" @rowReorder="onRowReorder">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />

            <Column field="code" header="Code" sortable>
               <template #body="{data}">{{data.code}}</template>
            </Column>

            <Column field="name" header="Name" sortable style="min-width: 14rem">
               <template #body="{data}">{{data.name}}</template>
            </Column>

            <Column field="name" header="Type" sortable style="min-width: 14rem">
               <template #body="{data}">{{data.inventory_type}}</template>
            </Column>

            <Column field="maximum_selections" header="Quantity In Stock">
               <template #body="{data}">{{data.count}}</template>
            </Column>

            <Column field="maximum_selections" header="Cost">
               <template #body="{data}">{{data.cost}}</template>
            </Column>

            <Column field="actions" header="Actions">
               <template #body="{data}">
                  <div class="container container-left">
                     <Button class="p-button-rounded action-icon" @click="openDialog ('edit', data.id)" v-tooltip="'Edit Record'"><i class="fa-solid fa-cog"></i></Button>
                     <Button class="p-button-rounded action-icon" @click="openSubTypeDialog (data)" v-tooltip="'Define Subtypes'"><i class="fa-solid fa-clone"></i></Button>
                     <Button class="p-button-rounded action-icon" @click="openDialog ('delete', data.id)" v-tooltip="'Delete Record'"><i class="fa-solid fa-trash"></i></Button>
                  </div>
               </template>
            </Column>
        </DataTable>
      </div>
   </div>
</template>

<script setup>
   import { ref, computed, onMounted } from "vue";
   import { RestGet, RestPost, RestPut, RestDelete } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'

   //import Dropdown from 'primevue/dropdown';
   import Textarea from 'primevue/textarea';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import Dialog from 'primevue/dialog';
   import DataTable from 'primevue/datatable';
   import Column from 'primevue/column';
   import { useToast } from "primevue/usetoast";
   import AdminNavigation from './AdminNavigation.vue'

   const toast = useToast();
   const profile = userProfileStore();
   const router = useRouter();

   var displaySubTypeDialog = ref (false);
   var subtypes = ref ([]);
   var dialogLabel = ref ("");
   var disabled = ref (false);
   var header = ref ("");
   var displayEdit = ref (false);
   var dialogAction = ref ("");
   var dialogClass = ref ("");
   var record = ref ({});
   const table = ref();
   const maxSubTypes = 5;
   
   const inventory = ref([]);

   const saveDisabled = computed(() => !record.value.name || !record.value.code || 
                                       !record.value.tooltip || !record.value.description);


   onMounted(() =>
   {
      getInventory();
   })

   const onRowReorder = function (event)
   {
      inventory.value = event.value;

      //
      // Now we need to save the data. All of this would considered an update.
      // XXXXX
      dialogAction.value = "edit";
      for (var x in inventory.value)
      {
         record.value = inventory.value[x];
         record.value.stage = parseInt(x) + 1;

         saveData (true);
      }

      var mesg = "Records successfully updated.";
      toast.add ({severity: 'success', summary: "Records Updated", detail: mesg, life: 3000});
   }

   const openSubTypeDialog = function (rec)
   {
      record.value = rec;
      header.value = "Edit " + rec.name + " Sub-Types";

      subtypes.value = [];
      for (var x = 0; x < maxSubTypes; x++)
      {
         var label = "Option " + (x+1);
         subtypes.value[x] = {id: '', label: label, name: '', description: ''};
      }

      getSubTypes (rec.id);
   }

   const deleteRecord = function (row, rec)
   {
      var uri = "/ingredient/subtype/" + rec.id;
      RestDelete (uri, profile.token(), {}, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var label = "Option " + (row+1);
            subtypes.value[row] = {id: '', label: label, name: '', description: ''};
            return;
         }
      });
   }

   const closeSubTypeDialog = function () {displaySubTypeDialog.value = false; }
   const saveSubTypes = function ()
   {
      closeSubTypeDialog();

      var json = {'ingredient_type_id': record.value.id, 'subtypes': []};
      var count = 0;

      for (var x = 0; x < subtypes.value.length; x++)
      {
         if (subtypes.value[x].name.length > 0)
         {
            if (subtypes.value[x].id)
            {
               count++;
            }

            json['subtypes'].push ({'id': subtypes.value[x].id,
                                    'name': subtypes.value[x].name,
                                    'description': subtypes.value[x].description});
         }
      }

      displaySubTypeDialog.value = false;

      //
      // If the count is more than one, then we are updating (PUT). Otherwise, save (POST)
      //
      if (count > 0)
      {
         RestPost ("/ingredient/subtype/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }

            if (status.ok)
            {
               var mesg = "Subtypes were successfully saved.";
               toast.add ({severity: 'success', summary: "Subtypes Saved", detail: mesg, life: 3000});
               return;
            }
         });
      }
      else
      {
         RestPost ("/ingredient/subtype/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }

            if (status.ok)
            {
               var mesg = "Subtypes were successfully saved.";
               toast.add ({severity: 'success', summary: "Subtypes Saved", detail: mesg, life: 3000});
               return;
            }
         });
      }
   }

   const getSubTypes = function (id)
   {
      var uri = "/ingredient/subtype/type/" + id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            displaySubTypeDialog.value = true;
            var array = response;

            for (var x=0; x < maxSubTypes; x++)
            {
               var label = "Option " + (x+1);
               if (array[x])
               {
                  var rec = array[x];
                  subtypes.value[x] = {id: rec.id, name: rec.name, description: rec.description, label: label };
               }
               else
               {
                  subtypes.value[x] = {id: '', label: label, name: '', description: ''};
               }
            }
         }
      });
   }

   const openDialog = function (action, id)
   {
      displayEdit.value = true;
      dialogAction.value = action;

      if (inventory.value && inventory.value.length > 0)
      {
         for (var x = 0; x < inventory.value.length; x++)
         {
            if (inventory.value[x].id == id)
            {
               record.value = inventory.value[x];
               break;
            }
         }
      }

      if (action == "new")
      {
         header.value = "Add Ingredient Type";
         disabled.value = false;
         dialogLabel.value = "Save";
         dialogClass.value = "";
         record.value = {};
      }
      else if (action == "edit")
      {
         header.value = "Edit Ingredient Type";
         disabled.value = false;
         dialogLabel.value = "Edit";
         dialogClass.value = "";
      }
      else
      {
         header.value = "Delete Ingredient Type";
         disabled.value = true;
         dialogLabel.value = "Delete";
         dialogClass.value = "p-button-danger";
      }
   }

   //
   // This will save the data from the dialog, if needed.
   //
   const closeDialog = function () {displayEdit.value = false;}
   const saveData = function (bulk)
   {
      closeDialog();

      var tooltip = record.value.tooltip;
      if (tooltip)
      {
         tooltip = record.value.tooltip.replaceAll ("'", "\\'");
      }

      var desc = record.value.description;
      if (tooltip)
      {
         desc = record.value.description.replaceAll ("'", "\\'");
      }

      var json = {name: record.value.name,
                  code: record.value.code,
                  stage: record.value.stage,
                  percent: record.value.percent,
                  minimum_selections: record.value.minimum_selections,
                  maximum_selections: record.value.maximum_selections,
                  tooltip: tooltip,
                  description: desc};

      //
      // Given the dialogAction, we either save (POST), update (PUT), or DELETE (remove)
      //
      var mesg = "";
      var uri = "";

      if (dialogAction.value == "new")
      {
         RestPost ("/ingredient/type/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Record successfully saved.";
               toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});

               getInventory();
               return;
            }
         });
      }
      else if (dialogAction.value == "delete")
      {
         uri = "/ingredient/type/" + record.value.id;
         RestDelete (uri, profile.token(), {}, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Record successfully deleted.";
               toast.add ({severity: 'success', summary: "Record Deleted", detail: mesg, life: 3000});

               getInventory();
               return;
            }
         });
      }
      else
      {
         uri = "/ingredient/type/" + record.value.id;
         RestPut (uri, profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               if (!bulk)
               {
                  mesg = "Record successfully updated.";
                  toast.add ({severity: 'success', summary: "Record Updated", detail: mesg, life: 3000});

                  getInventory();
               }
               return;
            }
         });
      }
   }

   const getInventory = function ()
   {
      inventory.value = [];

      RestGet ("/inventory/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            inventory.value = response;
         }
      });
   }
</script>

<style scoped>
   .menu-button
   {
      height: 30px;
      font-weight: bold;
      font-face: var(--font-face);
      font-size: .8em;
      margin-top: 10px;
      background: #19BF55;
      border: 1px solid #237C43 !important;
   }
   .menu-button:hover
   {
      background: #237C43 !important;
      color: #ffffff !important;
   }

   .label-col {width: 100px !important;; height: 30px; background: #03080F; }
   .name-col {width: 200px !important; height: 30px; background: #03080F;}
   .description-col {width: 350px !important; height: 30px; background: #03080F;}

   .delete-record-icon
   {
      margin-top: 5px;
      margin-left: 5px;
      font-size: 1.5em;
   }
   .delete-record-icon:hover
   {
      cursor: pointer;
      color: red;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex; 
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
   }
</style>
