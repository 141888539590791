<template>
   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
         ADMIN PAGE
      </div>
   </div>
</template>

<script setup>
   import { onMounted } from "vue";
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'
   import AdminNavigation from './AdminNavigation.vue'

   const profile = userProfileStore();
   const router = useRouter();
   
   onMounted(() =>
   {
      profile.load();

      if (! profile.isAdmin())
      {
         router.push ("/home");
         return;
      }

profile.dump ("ADMIN");
   })

</script>
