<template>
   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
         <h1>Manage Ingredients</h1>
         <div class="container container-row container-left" style="margin-top: 10px;">
            <div class="input-label">Search Ingredients</div>
            <InputText id="search" type="text" size="40" v-model="query" placeholder="Search" v-on:keyup.enter="search"/>
            <Button @click="search" class="button-text">Search <i class="fa-solid fa-magnifying-glass"></i></Button>
            <div v-if="warningMessage" class="warning-label">{{warningMessage}}</div>
         </div>

         <div class="container container-row container-left container-wrap">
            <div v-for="(record) in ingredient_types" v-bind:key="record.id">
               <div class="container container-left">
                  <div class="product-name-button large" v-tooltip="record.tooltip" v-on:click="showIngredients (record.id)">
                     <span class="product-name-button-icon"><img :src="record.image"></span>
                     <span class="product-name-button-text">{{record.name}}</span>
                     <span class="product-name-button-count">{{record.count}}</span>
                  </div>
               </div>
            </div>
         </div>

         <div v-if="search_results.length > 0" class="container container-row container-left container-wrap">
            <hr />
            <DataTable ref="table" :value="search_results" responsiveLayout="scroll" dataKey="id">
               <Column field="name" header="Name" sortable style="min-width: 14rem">
                  <template #body="{data}">{{data.name}}</template>
               </Column>

               <Column field="ingredient_type_name" header="Ingredient Type">
                  <template #body="{data}">{{data.ingredient_type_name}}</template>
               </Column>

               <Column field="ingredient_subtype_name" header="Ingredient Subtype">
                  <template #body="{data}">{{data.ingredient_subtype_name}}</template>
               </Column>

               <Column field="description" header="Description">
                  <template #body="{data}">{{data.description}}</template>
               </Column>

               <Column field="actions" header="Actions">
                  <template #body="{data}">
                     <div class="container container-left">
                        <Button class="p-button-rounded action-icon" @click="showIngredients (data.ingredient_type_id)" v-tooltip.top="'View Ingredient'"><i class="fa-solid fa-circle-arrow-right"></i></Button>
                     </div>
                  </template>
               </Column>
            </DataTable>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { RestGet } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'
   import AdminNavigation from '../AdminNavigation.vue'

   import DataTable from 'primevue/datatable';
   import Column from 'primevue/column';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';

   const profile = userProfileStore();
   const router = useRouter();

   const warningMessage = ref ();
   const search_results = ref ([]);
   const ingredients = ref ();
   const ingredient_types = ref ();
   const query = ref();

   onMounted(() =>
   {
      getIngredientTypes();
   })

   const showIngredients = function(id)
   {
      router.push ("/admin/ingredient/" + id);
   }

   const search = function ()
   {
      search_results.value = [];
      warningMessage.value = null;

      var uri = "/ingredient/search/" + query.value;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            search_results.value = response;

            if (search_results.value && Object.keys(search_results.value).length === 0)
            {
               warningMessage.value = "Search Term (" + query.value + ") not found.";
            }
         }
      });
   }

   const getIngredientTypes = function ()
   {
      ingredient_types.value = {};

      RestGet ("/ingredient/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient_types.value = response;
            
            for (var idx in ingredient_types.value)
            {
               var icon =  ingredient_types.value[idx].icon;

               ingredient_types.value[idx].count = 0;
               ingredient_types.value[idx].image = require (`@/assets/icons/misc/${icon}`)
            }

            getIngredients();
         }
      });
   }

   const getIngredients = function ()
   {
      ingredients.value = [];

      RestGet ("/ingredient/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredients.value = response;

            for (var x = 0; x < ingredients.value.length; x++)
            {
               for (var y = 0; y < ingredient_types.value.length; y++)
               {
                  if (ingredients.value[x].ingredient_type_id == ingredient_types.value[y].id)
                  {
                     ingredient_types.value[y].count++;
                     break;
                  }
               }
            }
         }
      });
   }
</script>

<style scoped>
   .back-button
   {
      display: flex;
      justify-content: flex-end;
      padding: 0px 5px 0px 3px;
      font-size: .8em;
      line-height: 30px;
      height: 30px;
      width: 100px;
      font-weight: bold;
   }

   .product-name-button
   {
      width: 225px !important;
   }

   .text
   {
      display: flex;
      justify-content: flex-start;
      flex-grow: 2;
      font-size: .9em;
      font-variant: small-caps;
   }

   .count
   {
      font-size: .8em;
      font-weight: bold;
      text-align: center;
      line-height: 25px;
      height: 25px;
      width: 25px;
      background: #ffffff;
      border-radius: 5px;
      margin-right: 3px;
      color: #545098;
      box-shadow: 2px 2px 3px #0F0D30;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
   }

   .button-text
   {
      font-weight: bold;
      font-size: .7em;
      margin-right: 10px;
      margin-left: 10px;
   }
   .button-text i
   {
      margin-left: 10px;
   }
   
</style>
