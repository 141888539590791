<template>
   <ToastTip />

   <Dialog position="top" :header="header" v-model:visible="displayEdit" :modal="true" dismissableMask :style="{width: '50vw'}">
      <div class="container container-left">
         <div class="input-label">Name</div>
         <InputText id="name" type="text" size="50" v-model="record.name" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Tooltip</div>
         <InputText id="tooltip" type="text" size="50" v-model="record.tooltip" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Description</div>
         <Textarea id="description" v-model="record.description" :autoResize="true" rows="5" cols="49" :disabled="disabled"  />
      </div>

      <template #footer>
         <Button label="Close" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
         <Button :label="dialogLabel" :class="dialogClass" icon="pi pi-save" @click="saveData" :disabled="saveDisabled" autofocus />
      </template>
   </Dialog>

   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
         <div class="container container-left container-align-center container-full">
            <div class="container container-left container-align-center container-full">
               <div class="container container-left">
               <h1>Manage Package Types</h1>
               </div>
               <div class="container container-right container-grow container-gap-10">
                  <div @click="openDialog ('new', 0)" class="call-to-action-button">
                     Add Package Type <i class="fa-solid fa-square-plus"></i>
                  </div>
               </div>
            </div>
         </div>

        <DataTable ref="table" :value="package_types" responsiveLayout="scroll" dataKey="id">
            <Column field="stage" header="Name">
               <template #body="{data}">{{data.name}}</template>
            </Column>

            <Column field="code" header="Description" sortable>
               <template #body="{data}">{{data.description}}</template>
            </Column>

            <Column field="actions" header="Actions">
               <template #body="{data}">
                  <div class="container container-left">
                     <Button class="p-button-rounded action-icon" @click="openDialog ('edit', data.id)" v-tooltip.top="'Edit Record'"><i class="fa-solid fa-cog"></i></Button>
                     <Button class="p-button-rounded action-icon" @click="openDialog ('delete', data.id)" v-tooltip.top="'Delete Record'"><i class="fa-solid fa-trash"></i></Button>
                  </div>
               </template>
            </Column>
        </DataTable>
      </div>
   </div>
</template>

<script setup>
   import { ref, computed, onMounted } from "vue";
   import { RestGet, RestPost, RestPut, RestDelete } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'

   import Textarea from 'primevue/textarea';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import Dialog from 'primevue/dialog';
   import DataTable from 'primevue/datatable';
   import Column from 'primevue/column';
   import { useToast } from "primevue/usetoast";
   import AdminNavigation from './AdminNavigation.vue'

   const toast = useToast();
   const profile = userProfileStore();
   const router = useRouter();

   var dialogLabel = ref ("");
   var disabled = ref (false);
   var header = ref ("");
   var displayEdit = ref (false);
   var dialogAction = ref ("");
   var dialogClass = ref ("");
   var record = ref ({});
   const table = ref();
   
   const package_types = ref([]);

   const saveDisabled = computed(() => !record.value.name || !record.value.tooltip || !record.value.description);

   onMounted(() =>
   {
      getPackageTypes();
   })

   const openDialog = function (action, id)
   {
      displayEdit.value = true;
      dialogAction.value = action;

      if (package_types.value && package_types.value.length > 0)
      {
         for (var x = 0; x < package_types.value.length; x++)
         {
            if (package_types.value[x].id == id)
            {
               record.value = package_types.value[x];
               break;
            }
         }
      }

      if (action == "new")
      {
         header.value = "Add Package Type";
         disabled.value = false;
         dialogLabel.value = "Save";
         dialogClass.value = "";
         record.value = {};
      }
      else if (action == "edit")
      {
         header.value = "Edit Package Type";
         disabled.value = false;
         dialogLabel.value = "Edit";
         dialogClass.value = "";
      }
      else
      {
         header.value = "Delete Package Type";
         disabled.value = true;
         dialogLabel.value = "Delete";
         dialogClass.value = "p-button-danger";
      }
   }

   //
   // This will save the data from the dialog, if needed.
   //
   const closeDialog = function () {displayEdit.value = false;}
   const saveData = function ()
   {
      closeDialog();

      var tooltip = record.value.tooltip;
      if (tooltip)
      {
         tooltip = record.value.tooltip.replaceAll ("'", "\\'");
      }

      var desc = record.value.description;
      if (desc)
      {
         desc = record.value.description.replaceAll ("'", "\\'");
      }

      var json = {name: record.value.name, tooltip: tooltip, description: desc};

      //
      // Given the dialogAction, we either save (POST), update (PUT), or DELETE (remove)
      //
      var mesg = "";
      var uri = "";

      if (dialogAction.value == "new")
      {
         RestPost ("/package/type/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Record successfully saved.";
               toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});

               getPackageTypes();
               return;
            }
         });
      }
      else if (dialogAction.value == "delete")
      {
         uri = "/package/type/" + record.value.id;
         RestDelete (uri, profile.token(), {}, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Record successfully deleted.";
               toast.add ({severity: 'success', summary: "Record Deleted", detail: mesg, life: 3000});

               getPackageTypes();
               return;
            }
         });
      }
      else
      {
         uri = "/package/type/" + record.value.id;
         RestPut (uri, profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               mesg = "Record successfully updated.";
               toast.add ({severity: 'success', summary: "Record Updated", detail: mesg, life: 3000});

               getPackageTypes();
            }
         });
      }
   }

   //
   // https://primetek.hashnode.dev/vue3-datatable
   //
   const getPackageTypes = function ()
   {
      package_types.value = [];

      RestGet ("/package/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            if (Object.keys(response).length > 0)
            {
               package_types.value = response;
            }
         }
      });
   }
</script>

<style scoped>
   .menu-button
   {
      height: 30px;
      font-weight: bold;
      font-face: var(--font-face);
      font-size: .8em;
      margin-top: 10px;
      background: #19BF55;
      border: 1px solid #237C43 !important;
   }
   .menu-button:hover
   {
      background: #237C43 !important;
      color: #ffffff !important;
   }

   .label-col {width: 100px !important;; height: 30px; background: #03080F; }
   .name-col {width: 200px !important; height: 30px; background: #03080F;}
   .description-col {width: 350px !important; height: 30px; background: #03080F;}

   .delete-record-icon
   {
      margin-top: 5px;
      margin-left: 5px;
      font-size: 1.5em;
   }
   .delete-record-icon:hover
   {
      cursor: pointer;
      color: red;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex; 
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
   }
</style>
