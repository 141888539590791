<template>
CONTACT US
</template>

<script setup>
   import { ref } from 'vue'

   var contact_details = ref ({});

   console.log ("CONTACT REF: ", contact_details);
</script>

<style scoped>
</style>
