<template>
   <div class="card card-large">
      <div class="selector-title">Skin Type Selector</div>

      <SelectorTemplate :buttons=buttons :icon="ingredient_type_icon" :text=text v-on:change="change" v-on:save="save" :save_button="props.save_button" :next_button="props.next_button" small />
   </div>
</template>

<script setup>
   import { ref, defineProps, defineEmits } from 'vue';
   import SelectorTemplate from "./SelectorTemplate";

   const props = defineProps ({save_button: [Boolean], next_button: [Boolean]});
   const buttons = ref ([{name: "Normal", value: "normal", 
                          description: "Normal skin is balanced—feeling neither too dry nor too oily. It is not prone to breakouts, \
                                        flakiness, or feeling greasy or tight. ",
                          image: require (`@/assets/icons/misc/dyos2.png`)},
                         {name: "Oily", value: "oily",
                          description: "Oily skin produces an excess of sebum that causes the skin to appear shiny and feel greasy, \
                                        especially throughout the forehead, nose, and chin.",
                          image: require (`@/assets/icons/misc/oil.png`)},
                         {name: "Dry", value: "dry", 
                          description: "Dry skin generally produces less natural oils than other types of skin. This may cause \
                                        it to appear dull and become rough, flaky, or even scaly. It often feels tight or less elastic.",
                          image: require (`@/assets/icons/misc/cactus.png`)},
                         {name: "Combo", value: "combination", 
                          description: "Combination skin includes areas that are dry as well as oily—with the T-zone commonly \
                                        being oily, and the cheeks being either dry or normal.",
                          image: require (`@/assets/icons/misc/circles.png`)},
                         {name: "Sensitive", value: "sensitive", 
                          description: "Sensitive skin is typically more vulnerable to external irritants and may be triggered \
                                        by certain ingredients—like dyes or fragrance—as well as environmental factors.",
                          image: require (`@/assets/icons/misc/bolt.png`)}]);

   const ingredient_type_icon = require (`@/assets/icons/misc/leaf.png`);
   const text = ref (["According to the American Academy of Dermatology (AAD), there are five primary types of skin: \
                     oily, dry, normal, combination, and sensitive. Each skin type has its own set of unique \
                     characteristics and needs that can affect the look and feel of your complexion. By first \
                     understanding what type of skin you have, you can begin to make informed decisions on the type \
                     of products and ingredients which will work best for you.",
                     "Pick the skin type below that best describes your skin."]);

   const emit = defineEmits(['save', 'change']);
   const change = function (obj) { emit ('change', obj); }
   const save = function (obj) { emit ('save', obj); }

</script>

<style scoped>
.selector-title
{
   font-size: .8em;
   font-weight: bold;
   font-variant: small-caps;
}
</style>
