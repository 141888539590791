import { defineStore } from 'pinia'

export const userProfileStore = defineStore('profile', {
  state: () => ({
     storageName: 'userProfile',
     data: null,
  }),

  getters: 
  {
  },

  actions:
  {
     load (force)
     {
        if (! this.data || force == true)
        {
           var sd = window.localStorage.getItem (this.storageName);
           if (sd)
           {
              this.data = JSON.parse (sd);
           }
           else
           {
              this.data = {};
           }
        }
     },

     set (profile)
     {
        this.data = profile;
        this.data.name = profile.first_name + " " + profile.last_name;
        this.data.displayName = this.data.name;
        
        window.localStorage.setItem (this.storageName, JSON.stringify (this.data));
     },

     read ()
     {
        this.load();
        return this.data;
     },

     add (key, value)
     {
        this.load (true);

        this.data[key] = value;
        window.localStorage.setItem (this.storageName, JSON.stringify (this.data));
     },

     id () { return this.get ("id"); },
     token () { return this.get ("token"); },
     avatar() { return this.get ("avatar"); },
     reset_token (token) { this.add ("token", token); },

     get (field)
     {
        this.load (true);

        if (field == null)
        {
           return this.data;
        }

        if (this.data === null || this.data[field] === null)
        {
           return null;
        }
        return this.data[field];
     },

     isAdmin ()
     {
        this.load();

        if (this.data === null || this.data['is_admin'] === null)
        {
           return false;
        }

        return this.data['is_admin'];
     },

     create()
     {
        this.data = {};
        window.localStorage.setItem (this.storageName, JSON.stringify (this.data));
     },

     clear()
     {
        this.data = null;
        window.localStorage.removeItem (this.storageName);
     },

     dump (message)
     {
        console.log ("PROFILE DUMP: ", message);
        console.log( JSON.stringify(this.data, null, 2) );
     },
  },
})
