<template>
   <div class="container container-column">
       <div class="container container-left">
          <div class="input-label required" v-tooltip="'Required Field'">Country</div>
          <Dropdown v-if="address.country" v-model="address.country" :options="countries" optionLabel="name" optionValue="name" placeholder="Select Your Country" v-on:change="getRegions" />
       </div>

       <div class="container container-left">
          <div class="input-label required" v-tooltip="'Required Field'">Province/State</div>
          <Dropdown v-if="address.region" v-model="address.region" :options="regions" optionLabel="name" optionValue="name" placeholder="Select Your Region" v-on:change="getCities" />
       </div>

       <div class="container container-left">
          <div class="input-label required" v-tooltip="'Required Field'">City</div>
          <Dropdown v-if="address.city" v-model="address.city" :options="cities" optionLabel="name" optionValue="name" placeholder="Select Your City" />
       </div>

       <div class="container container-left">
          <div class="input-label">Address (Optional)</div>
          <InputText id="address" type="text" size="50" v-model="address.address" placeholder="Address" />
       </div>

       <div class="container container-left">
          <div class="input-label">Postal Code (Optional)</div>
          <InputText id="postal_code" type="text" size="10" v-model="address.postal_code" placeholder="Postal Code" />
       </div>

       <Divider />
       <div class="container container-center">
          <Button :disabled="addressDisabled" class="button-text" v-on:click="emitAddress">
             Save Address <i class="fa-solid fa-circle-right button-icon"></i>
          </Button>
       </div>
    </div>
</template>

<script setup>
   import { ref, computed, onMounted, watchEffect, defineProps, defineEmits } from 'vue';
   import { userProfileStore } from '@/stores/profile';
   import { RestGet } from '@/plugins/webapi';
   import { useRouter } from 'vue-router'

   import Divider from 'primevue/divider';
   import Button from 'primevue/button';
   import Dropdown from 'primevue/dropdown';
   import InputText from 'primevue/inputtext';

   const props = defineProps ({modelValue: [Object]});
   const emit = defineEmits(['change']);
   const profile = userProfileStore();
   const router = useRouter();

   var address = ref ({});
   var countries = ref ([{name: "United States"}, {name: "Canada"}]);
   var regions = ref ([]);
   var cities = ref ([]);

   const addressDisabled = computed(() => !address.value.city || !address.value.region || !address.value.country);

   onMounted(() =>
   {
      if (! address.value.country)
      {
         address.value.country = "Canada";
      }

      if (! address.value.region)
      {
         address.value.region = "Ontario";
      }

      if (! address.value.city)
      {
         address.value.city = "--";
      }
      getRegions (true);
   })

   //
   // This is needed to catch the properties passed in. I cant do this in onMounted.
   //
   watchEffect(() =>
   {
      if (props.modelValue)
      {
         address.value.country = props.modelValue.country;
         address.value.region = props.modelValue.region;
         address.value.city = props.modelValue.city;
         address.value.address = props.modelValue.address;
         address.value.postal_code = props.modelValue.postal_code;
      }
   })

   const emitAddress = function ()
   {
      emit ('change', address.value);
   }

   const getRegions = function (call_get_cities)
   {
      var json = [{country: address.value.country}];

      RestGet ("/geo/regions", profile.token(), json, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            regions.value = [];
            for (var x=0; x < response.length; x++)
            {
               regions.value[x] = {name: response[x].region_name};
            }

            if (call_get_cities)
            {
               getCities();
            }
         }
      });
   }

   const getCities = function ()
   {
      var json = [{region: address.value.region}];

      RestGet ("/geo/cities", profile.token(), json, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            cities.value = [];
            for (var x=0; x < response.length; x++)
            {
               cities.value[x] = {name: response[x].city};
            }

            if (! address.value.city)
            {
               address.value.city = "--";
            }
         }
      });
   }

</script>

<style scoped>
.required {color: red}
.button-text { font-weight: bold; font-size: 1em; }
.button-icon { font-size: 1.8em; padding-left: 10px; }
</style>
