<template>
   <div class="container container-column">
      <div class="container container-left">
         <div class="input-label required" v-tooltip="'Required Field'">Name</div>
         <InputText id="firstname" type="text" size="20" v-model="contact.first_name" placeholder="First Name"/>
         <InputText id="lastname" type="text" size="40" v-model="contact.last_name" placeholder="Last Name" />
      </div>

      <NickName v-if="contact" :default="contact.nickname" v-model="contact.nickname" :id="contact.id" respect />
   
      <div class="container container-left">
         <div class="input-label required" v-tooltip="'Required Field'">Email</div>
         <InputText id="email" type="text" size="30" v-model="contact.email" placeholder="Email" />
      </div>

      <div class="container container-left">
         <div class="input-label">Phone (Optional)</div>
         <InputMask id="phone" type="text" size="30" mask="(999) 999-9999" v-model="contact.phone" placeholder="Phone" />
      </div>

      <Divider />

      <div class="container container-center">
         <Button @click="emitContactDetails" :disabled="contactDisabled" class="button-text">
            Save Contact Details <i class="fa-solid fa-circle-right button-icon"></i>
         </Button>
      </div>
   </div>
</template>

<script setup>
   import { ref, computed, onMounted, watchEffect, defineProps, defineEmits } from 'vue';

   import NickName from './NickName'
   import InputMask from 'primevue/inputmask';
   import Divider from 'primevue/divider';
   import Button from 'primevue/button';
   import InputText from 'primevue/inputtext';

   const props = defineProps ({modelValue: [Object]});
   const emit = defineEmits(['change']);

   var contact = ref ({});

   const contactDisabled = computed(() => !contact.value.first_name || !contact.value.last_name || !contact.value.email);

   onMounted(() =>
   {
   })

   //
   // This is needed to catch the properties passed in. I cant do this in onMounted.
   //
   watchEffect(() =>
   {
      if (props.modelValue)
      {
         var fields = ["first_name", "last_name", "email", "nickname", "phone"];
         for (var field of fields)
         {
            if (props.modelValue[field])
            {
               contact.value[field] = props.modelValue[field];
            }
         }

console.log ("CONTACTS: ", contact.value);
      }
   })

   const emitContactDetails = function ()
   {
      emit ('change', contact.value);
   }
</script>

<style scoped>
.required {color: red}
.button-text { font-weight: bold; font-size: 1em; }
.button-icon { font-size: 1.8em; padding-left: 10px; }
</style>
