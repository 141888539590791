<template>

  <!-- This allows us to select an imgredient image -->
   <Dialog position="top" header="Select an Icon" v-model:visible="showImageDialog" :modal="true" dismissableMask :style="{width: '50vw'}">
      <div class="container container-full container-column">
         <div class="container container-full container-row">
            <div class="container container-column container-30">
               <div class="container icon-text-header">Current Icon</div>
               <div class="recipe-navigation-button">
                  <div class="recipe-navigation-button-icon"><img :src="current_icon"></div>
               </div>
               <div class="container icon-text-header">Selected Icon</div>
               <div v-if="selected_icon" class="recipe-navigation-button">
                  <div class="recipe-navigation-button-icon"><img :src="selected_icon"></div>
               </div>
            </div>

            <div class="container container-60">
               <IconSelector v-on:change="iconSelected" :icon="record.icon" :default="default_icon" no_header />
            </div>
         </div>

         <div class="container container-right">
            <Button label="Close" icon="pi pi-times" @click="closeImageDialog" class="p-button-text"/>
            <Button label="Save" icon="pi pi-save" @click="saveProductIcon" :disabled="!saveImageButton" autofocus />
         </div>
      </div>
   </Dialog>

   <ToastTip />

   <ProductTypeEditor v-if="showEditor" :action="productAction" :data="record" v-on:save="saveCB" :key="editorKey" />

   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
         <h1>Product Types</h1>

         <div class="container container-right">
            <div @click="addProductType" class="call-to-action-button">
               Add Product Type <i class="fa-solid fa-square-plus"></i>
            </div>
         </div>

        <DataTable :value="product_types" responsiveLayout="scroll" dataKey="id">
            <Column field="name" header="Name" sortable style="min-width: 14rem">
               <template #body="{data}">{{data.name}}</template>
            </Column>

            <Column field="description" header="Available">
               <template #body="{data}">{{data.is_available_string}}</template>
            </Column>

            <Column field="description" header="Description">
               <template #body="{data}">{{data.description}}</template>
            </Column>

            <Column field="icon_path" header="Icon">
               <template #body="{data}">
                  <img class="table-icon" :src="data.image" :alt="data.name" @click="editProductImage (data)">
               </template>
            </Column>

            <Column field="created" header="Created">
               <template #body="{data}">{{data.created}}</template>
            </Column>

            <Column field="actions" header="Actions">
               <template #body="{data}">
                  <div class="container container-left">
                     <Button class="p-button-rounded action-icon" @click="editProductType (data)" v-tooltip.top="'Edit Record'"><i class="fa-solid fa-cog"></i></Button>
                     <Button class="p-button-rounded action-icon" @click="editProductImage (data)" v-tooltip.top="'Edit Product Image'"><i class="fa-solid fa-image"></i></Button>
                     <Button class="p-button-rounded action-icon" @click="deleteProductType (data)" v-tooltip.top="'Delete Record'"><i class="fa-solid fa-trash"></i></Button>
                  </div>
               </template>
            </Column>
        </DataTable>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { RestGet, RestPut } from '@/plugins/webapi';

   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'

   import Dialog from 'primevue/dialog';
   import Button from 'primevue/button';
   import DataTable from 'primevue/datatable';
   import Column from 'primevue/column';
   import { useToast } from "primevue/usetoast";
   import AdminNavigation from '../AdminNavigation.vue'
   import ProductTypeEditor from './TypeEditor.vue'
   import IconSelector from '../../Lib/Selectors/IconSelector.vue'

   const profile = userProfileStore();
   const router = useRouter();
   const toast = useToast();

   var record = ref ({});
   const showImageDialog = ref (false);
   const showEditor = ref (false);
   const productAction = ref ();
   const editorKey = ref (0);
   const default_icon = ref ([]);
   const current_icon = ref ({});
   const selected_icon = ref ({});
   const selectedIcon = ref ();
   const saveImageButton = ref (false);
   
   const product_types = ref();

   onMounted(() =>
   {
      getProductTypes();
   })

   const closeImageDialog = function() { showImageDialog.value = false;}
   const editProductImage = function(obj)
   {
      showImageDialog.value = true;

      record.value = obj;
      current_icon.value = obj.image;
      selected_icon.value = obj.image;
      default_icon.value = [obj.icon];
   }

   const iconSelected = function(obj)
   {
      selectedIcon.value = obj;
      saveImageButton.value = true;
      selected_icon.value = obj.image;
   }

   const saveCB = function (summary, mesg)
   {
      toast.add ({severity: 'info', summary: summary, detail: mesg, life: 3000});
      getProductTypes ();
   }

   const addProductType = function ()
   {
      productAction.value = "add";
      editorKey.value++;
      showEditor.value = true;
   }

   const editProductType = function (product)
   {
      record.value = product;
      productAction.value = "edit";
      editorKey.value++;
      showEditor.value = true;
   }

   const deleteProductType = function (product)
   {
      record.value = product;
      productAction.value = "delete";
      editorKey.value++;
      showEditor.value = true;
   }

   const saveProductIcon = function()
   {
      closeImageDialog();

      var json = {name: record.value.name,
                  description: record.value.description,
                  is_available: record.value.is_available,
                  tooltip: record.value.tooltip,
                  icon: selectedIcon.value.icon};

      var uri = "/product/type/" + record.value.id;
      RestPut (uri, profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            toast.add ({severity: 'info', summary: "Product XXX icon updated.", detail: "Record Updated", life: 3000});
            getProductTypes();
         }
      });
   }

   const getProductTypes = function ()
   {
      product_types.value = [];
      RestGet ("/product/type/all", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_types.value = response;

            //
            // NOTE:
            // We need to do this in order to load the images. We will need this pattern moving forward.
            //
            for (var x = 0; x < product_types.value.length; x++)
            {
               var icon = product_types.value[x].icon;

               product_types.value[x].image = require (`@/assets/icons/misc/${icon}`);
               product_types.value[x].is_available_string = product_types.value[x].is_available == 1 ? "Yes" : "No";
               product_types.value[x].is_available_bool = product_types.value[x].is_available == 1 ? true : false;
            }
         }
      });
   }

</script>

<style scoped>
   .menu-button
   {
      height: 30px;
      font-weight: bold;
      font-face: var(--font-face);
      font-size: .8em;
      margin-top: 10px;
      background: #19BF55;
      border: 1px solid #237C43 !important;
   }
   .menu-button:hover
   {
      background: #237C43 !important;
      color: #ffffff !important;
   }

   .table-icon
   {
      height: 40px;
      opacity: .6;
   }
   .table-icon:hover
   {
      cursor: pointer;
      opacity: 1;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex; 
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
   }
</style>
