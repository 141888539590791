<template>
   <div v-if="stages && stages.length > 0" class="stage-container">
      <div class="stage-links">
         <div v-for="(record,idx) in stages" v-bind:key="idx">
            <div v-if="record.visited">
               <div class="stage-link previous-stage-link" v-on:click="selected (idx)">{{record.name}}</div>
            </div>
            <div v-else>
               <div v-if="idx < currentStage">
                  <div class="stage-link previous-stage-link" v-on:click="selected (idx)">{{record.name}}</div>
               </div>
               <div v-else-if="idx == currentStage">
                  <div class="stage-link active-stage-link">{{record.name}}</div>
               </div>
               <div v-else>
                  <div class="stage-link inactive-stage-link">{{record.name}}</div>
               </div>
            </div>
         </div>

         <div v-if="props.saving">
            <div class="stage-link active-stage-link">Save</div>
         </div>
         <div v-else>
            <div class="stage-link inactive-stage-link">Save</div>
         </div>
      </div>
   </div>
</template>

<script setup="props">
   import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';
   import { recipeStore } from '@/stores/recipe';

   const recipe = recipeStore();
   const isSelected = ref (false);
   const stages = ref ({});
   const currentStage = ref (props.modelValue);
   const currentRecipe = ref ({});

   isSelected.value = props.value == props.modelValue ? true : false;
   const props = defineProps ({id: [String, Number], stages: Object, modelValue: [String, Number], saving: [Boolean]});

   onMounted(() =>
   {
      currentRecipe.value = recipe.get();
      stages.value = props.stages;

       //
       // Set the visitied field.
       //
       if (currentRecipe.value && currentRecipe.value.ingredients.length > 0)
       {
          for (var x in currentRecipe.value.ingredients)
          {
             var ing = currentRecipe.value.ingredients[x];
             for (var y in stages.value)
             {
                if (stages.value[y].code == ing.code)
                {
                   stages.value[y].visited = true;
                }
             }
          }
       }
   })

   watch(() => props.modelValue, () =>
   {
      currentStage.value = props.modelValue;
   });

   const emit = defineEmits(['click', 'update:modelValue']);
   const selected = function (idx)
   {
      emit ('click', idx);
      emit ('update:modelValue', idx);
   }
</script>

<style scoped>
.active { background: red; box-shadow: 0px 0px 5px 3px rgba(0, 255, 255, .5); }
.stage-container
{
  text-align: center;
  position: relative;
  margin: 20px 0px 30px 0px;
}
.stage-container:before
{
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid green;
  background: black;
  width: 100%;
  transform: translateY(-50%);
}
.stage-links
{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  position: relative;
}
.stage-link
{
   display: flex;
   align-items: center;
   align-content: center;
   justify-content: center;
   width: auto;
   min-width: 100px;
   height: 30px;
   border-radius: 10px;
   padding: 2px 10px 2px 10px;
}
.previous-stage-link
{
   border: 2px solid green;
   font-size: .8em;
   background: rgba(0,0,0,.5);
}
.previous-stage-link:hover
{
   cursor: pointer;
   background: rgba(0,0,0,.75);
}
.active-stage-link
{
   border: 2px solid var(--input-label-border-color);
   font-size: .8em;
   font-weight: bold;
   background: rgba(0,0,0,.5);
}
.active-stage-link:hover
{
   background: rgba(0,0,0,.75);
}
.inactive-stage-link
{
   border: 2px solid var(--input-label-border-color);
   font-size: .8em;
   background: rgba(0,0,0,.5);
   color: var(--bluegray-500);
}
.inactive-stage-link:hover
{
   cursor: not-allowed;
   background: rgba(0,0,0,.75);
}
</style>
