<template>
   <ToastTip />

   <div class="page">
      <AdminNavigation />

      <ProductTypeEditor v-if="showProductTypeEditor" :action="productAction" :data="record" v-on:save="saveProductTypeCB" :key="productTypeKey" />

      <div class="content">
         <!-- Add product sizes and costs. -->
         <Dialog position="top" :header="title" v-model:visible="editSizes" :modal="true" dismissableMask :style="{width: '70vw'}">
            <div class="container container-right">
               <Button label="Close" icon="pi pi-times" @click="saveProductSizes ('close')" class="p-button-text"/>
               <Button label="Save" icon="pi pi-file" @click="saveProductSizes (record.action)" class="p-button-text" />
            </div>

            <div class="container container-column">
               <div class="container container-row">
                  <div class="input-label col">Product Size</div>
                  <div class="input-label col">Volume</div>
                  <div class="input-label col">Measure</div>
                  <div class="input-label col">Our Base Cost</div>
                  <div class="input-label col">Base Customer Price</div>
                  <div class="input-label col">Available</div>
               </div>

               <div v-for="(rec, idx) in sizes" :id="'size_' + idx" class="container container-row" v-bind:key="idx">
                  <div class="input-label col">{{rec.name}}</div>
                  <InputText class="col" type="number" min="0" size="5" v-model="rec.volume" />
                  <Dropdown class="col" v-model="rec.measure" type="number" min="0" :options="measures" optionLabel="name" optionValue="value" placeholder="Select a Product Size" style="width: 10vw;" />
                  <InputText class="col" type="number" step="0.01" min="0" size="5" placeholder="Cost" v-model="rec.cost" v-on:blur="calcPrice (idx)" />
                  <InputText class="col" type="number" step="0.01" min="0" size="5" placeholder="Price" v-model="rec.price" />
                  <ToggleButton class="col" v-model="rec.is_available" onIcon="pi pi-check" offIcon="pi pi-times" />
               </div>
            </div>
         </Dialog>

         <h1>Product Sizes</h1>

         <div v-if="product_types" class="container container-column container-align-top">
            <div v-for="record in product_types" v-bind:key="record.id" class="product-type-container">
               <div class="container container-left">
                  <div class="container container-left">
                     <div class="product-name-button large" :class="{notavailable: !record.is_available}" v-on:click="openProductTypeEditor (record)" >
                        <div class="icon"><img :src="record.icon"></div>
                        <div class="text">{{record.name}}</div>
                     </div>
                  </div>

                  <div v-if="products[record.id]" class="container container-left container-wrap">
                     <div v-for="(product,idx) in products[record.id]" v-bind:key="idx">
                        <div class="product-button large" :class="{notavailable: product.is_available == false, noSizeDefined: !product_sizes[product.id]}" v-on:click="showProductSizes (product)" v-tooltip="product.tooltip">
                           <div class="product-button-badge"><img :src="record.icon"></div>
                           <div class="product-button-text">{{product.name}}</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { useRouter } from 'vue-router'
   import { RestGet, RestPost } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';

   import { useToast } from "primevue/usetoast";
   import Dialog from 'primevue/dialog';
   import Dropdown from 'primevue/dropdown';
   import ToggleButton from 'primevue/togglebutton';
   import Button from 'primevue/button';
   import InputText from 'primevue/inputtext';
   import AdminNavigation from '../AdminNavigation.vue'
   import ProductTypeEditor from './TypeEditor.vue'

   const profile = userProfileStore();
   const router = useRouter();
   const toast = useToast();
   const sizes = ref ([]);
   const title= ref();
   const record = ref({});
   const showProductTypeEditor = ref (false);
   const productTypeKey = ref (0);
   const editSizes = ref (false);
   const product_sizes = ref ();
   const product_types = ref([]);
   const product = ref ();
   const products = ref ();
   const measures = ref ([{name: 'Milliliters', value: 'ml'}, {name: 'Grams', value: 'gm'}, {name: 'Ounces', value: 'oz'}]);
   var productAction = ref ();

   const calcPrice = function (idx)
   {
      //
      // The 8 below is our multiple for margin.
      //
      var tmp = sizes.value[idx].cost * 8;
      sizes.value[idx].price = tmp.toFixed (2);
   }

   onMounted(() =>
   {
      getProductTypes();
   })

   const openProductTypeEditor = function (data)
   {
      record.value = data;
      productAction = "edit";
      showProductTypeEditor.value = true;
      productTypeKey.value++;
   }

   const saveProductTypeCB = function (summary, mesg)
   {
      toast.add ({severity: 'info', summary: summary, detail: mesg, life: 3000});
      getProductTypes();
   }

   const getProductTypes = function ()
   {
      product_types.value = [];
      RestGet ("/product/type/all", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_types.value = response;
            for (var x = 0; x < product_types.value.length; x++)
            {
               var id = product_types.value[x].id;
               product_types.value[x].icon = require (`@/assets/icons/product/type/${id}.png`);
               product_types.value[x].tooltip = "Create a New " + product_types.value[x].name + " Product";
            }

            getProductSizes();
         }
      });
   }

   const getProductSizes = function ()
   {
      product_sizes.value = [];
      RestGet ("/product/size/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            for (var x = 0; x < response.length; x++)
            {
               var rec = response[x];

               if (! product_sizes.value[rec.product_id])
               {
                  product_sizes.value[rec.product_id] = [];
               }

               product_sizes.value[rec.product_id].push (rec);
            }

            getProducts();
         }
      });
   }

   const getProducts = function ()
   {
      products.value = {};

      RestGet ("/product/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var json = {};
            for (var x = 0; x < response.length; x++)
            {
               var rec = response[x];

               if (! json[rec.product_type_id])
               {
                  json[rec.product_type_id] = [];
               }

               var id = rec.product_type_id;
               rec.badge = require (`@/assets/icons/product/type/${id}.png`);
               json[rec.product_type_id].push (rec);

               rec.tooltip = product_sizes.value[rec.id] == null ? "No Size Defined" : "";
            }

            products.value = json;
         }
      });
   }

   const showProductSizes = function (record)
   {
      title.value = "Define " + record.name + " Sizes";

      product.value = record;

      //
      // Default to 3 size options.
      //
      sizes.value = [];
      sizes.value.push ({order: 0, label: 'small',  name: 'Small', cost: '0.00', price: '0.00', volume: '0', measure: 'ml', is_available: true});
      sizes.value.push ({order: 1, label: 'regular', name: 'Regular', cost: '0.00', price: '0.00', volume: '0', measure: 'ml', is_available: true});
      sizes.value.push ({order: 2, label: 'large', name: 'Large', cost: '0.00', price: '0.00', volume: '0', measure: 'ml', is_available: true});

      editSizes.value = true;

      var uri = "/product/size/" + record.id + "?active_only=false";
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            for (var x = 0; x < response.length; x++)
            {
               var idx = response[x].product_order;

               sizes.value[idx].id = response[x].id;
               sizes.value[idx].product_order = response[x].product_order;
               sizes.value[idx].volume = response[x].volume;
               sizes.value[idx].measure = response[x].measure;
               sizes.value[idx].cost = response[x].cost.toFixed (2);
               sizes.value[idx].price = response[x].price.toFixed (2);
               sizes.value[idx].name = response[x].name;
               sizes.value[idx].is_available = response[x].is_available == 1 ? true : false;
            }
         }
      });
   }

   const saveProductSizes = function (action)
   {
      editSizes.value = false;

      if (action === 'close')
      {
         return;
      }

      var json = {size_list: []};
      for (var x = 0; x < sizes.value.length; x++)
      {
         var tmp = {name: sizes.value[x].name, 
                    volume: sizes.value[x].volume,
                    measure: sizes.value[x].measure,
                    cost: sizes.value[x].cost,
                    price: sizes.value[x].price,
                    is_available: sizes.value[x].is_available,
                    product_order: sizes.value[x].order};

         if (sizes.value[x].id)
         {
            tmp.id = sizes.value[x].id;
         }

         json.size_list.push (tmp);
      }

      var uri = "/product/size/" + product.value.id;
      RestPost (uri, profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var mesg = "Data successfully saved.";
            toast.add ({severity: 'info', summary: "Record Saved", detail: mesg, life: 3000});
            return;
         }
      });
   }
</script>

<style>
   .col { width: 65px !important; }

   .product-name-button
   {
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
      padding: 2px 1px 2px 1px;
   }
   .product-name-button .icon
   {
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .product-name-button .icon img
   {
      height: 30px;
      width: 30px;
   }
   .product-name-button .text
   {
      margin-left: 10px;
      font-size: .8em;
      font-weight: bold;
   }
   .product-name-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }

   .product-type-button
   {
      width: 60px;
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
   }
   .product-type-button .icon
   {
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .product-type-button .icon img
   {
      height: 30px;
      width: 30px;
   }
   .product-type-button .text
   {
      margin-left: 10px;
      font-size: 1em;
      font-weight: bold;
   }

   .product-type-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }

   .product-button
   {
      width: 120px;
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      justify-content: flex-start !important;
      align-items: center;
      margin: 8px 10px 0px 10px;
      padding: 1px 2px 1px 2px;
   }
   .product-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }
   .product-button-text
   {
      margin-left: 5px;
      font-size: .7em;
      font-weight: bold;
      text-shadow: 2px 2px 3px #111111;
   }
   .product-button-badge
   {
      width: 30px;
      border-radius: 5px;
   }
   .product-button-badge img
   {
      margin-left: 5px;
      height: 20px;
      width: 20px;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .large { width: 150px !important; }

   .image-preview
   {
      height: 50px;
      width: 50px;
      border-radius: 5px;
   }

   .notavailable { opacity: .5; }
   .noSizeDefined { opacity: .8; color: #05C5EA !important; }

</style>
