<template>
   <Dialog position="top" class="recipe-dialog" v-model:visible="showRecipeDialog" :modal="true" dismissableMask style="width: 85vw;">
      <div class="recipe-title">{{props.product.name}} Ingredient List</div>

      <div class="grid boxed full">
          <div class="col-4 recipe-header">Ingredient</div>
          <div class="col-2 recipe-header">Type</div>
          <div v-if="recipe.sizes.small" class="col-2 recipe-header">Small ({{recipe.sizes.small.pretty_size}})</div>
          <div v-if="recipe.sizes.regular" class="col-2 recipe-header">Regular ({{recipe.sizes.regular.pretty_size}})</div>
          <div v-if="recipe.sizes.large" class="col-2 recipe-header">Large ({{recipe.sizes.large.pretty_size}})</div>
      </div>

      <div class="grid boxed full">
          <div class="col-4 recipe-cell">{{recipe.product.name}}</div>
          <div class="col-2 recipe-cell">Base Product</div>
          <div v-if="recipe.sizes.small" class="col-2 recipe-cell">{{recipe.sizes.small.pretty_price}}</div>
          <div v-if="recipe.sizes.regular" class="col-2 recipe-cell">{{recipe.sizes.regular.pretty_price}}</div>
          <div v-if="recipe.sizes.large" class="col-2 recipe-cell">{{recipe.sizes.large.pretty_price}}</div>
      </div>

      <div v-for="(record, idx) in ingredients_list" class="grid boxed full" v-bind:key="idx" >
          <div class="col-4 recipe-cell">{{record.name}}</div>
          <div class="col-2 recipe-cell">{{record.ingredient_type_name}}</div>
          <div v-if="recipe.sizes.small" class="col-2 recipe-cell">{{record.small_cost_pretty}}</div>
          <div v-if="recipe.sizes.regular" class="col-2 recipe-cell">{{record.regular_cost_pretty}}</div>
          <div v-if="recipe.sizes.large" class="col-2 recipe-cell">{{record.large_cost_pretty}}</div>
      </div>

      <div class="grid boxed full">
          <div class="col-6 recipe-header">Total</div>
          <div class="col-2 recipe-header">{{total.small}}</div>
          <div class="col-2 recipe-header">{{total.regular}}</div>
          <div class="col-2 recipe-header">{{total.large}}</div>
      </div>
   </Dialog>

   <div class="container container-right">
      <div class="recipe-button" v-on:click="showRecipe">Show Recipe</div>
   </div>
</template>

<script setup>
   import { ref, defineProps } from 'vue';
   import Dialog from 'primevue/dialog';
   import { recipeStore } from '@/stores/recipe';

   const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
   const recipeDetails = recipeStore();
   const showRecipeDialog = ref (false);
   const total = ref ({small: 0, regular: 0, large: 0});
   const ingredients_list = ref([]);
   var recipe = ref();

   const props = defineProps ({product: Object});

   const showRecipe = function ()
   {
      recipe.value = recipeDetails.get();

console.log ("RECIPE: ", recipe.value);

      //
      // Calc the totals.
      //
      recipe.value.sizes.small.pretty_size = recipe.value.sizes.small.volume + " (" + recipe.value.sizes.small.measure + ")";
      recipe.value.sizes.regular.pretty_size = recipe.value.sizes.regular.volume + " (" + recipe.value.sizes.regular.measure + ")";
      recipe.value.sizes.large.pretty_size = recipe.value.sizes.large.volume + " (" + recipe.value.sizes.large.measure + ")";

      recipe.value.sizes.small.price_pretty = formatter.format (recipe.value.sizes.small.price);
      recipe.value.sizes.regular.price_pretty = formatter.format (recipe.value.sizes.regular.price);
      recipe.value.sizes.large.price_pretty = formatter.format (recipe.value.sizes.large.price);
      total.value.small = recipe.value.sizes.small.price;
      total.value.regular = recipe.value.sizes.regular.price;
      total.value.large = recipe.value.sizes.large.price;

      ingredients_list.value = [];

      for (var idx in recipe.value.ingredients)
      {
         var rec = recipe.value.ingredients[idx];

         for (var x in rec.ingredients)
         {
            var item = rec.ingredients[x];
            item.ingredient_type_name = rec.ingredient_type.name;

            ingredients_list.value.push (item);

            total.value.small = parseFloat (item.small_cost) + parseFloat (total.value.small);
            total.value.regular = parseFloat (item.regular_cost) + parseFloat (total.value.regular);
            total.value.large = parseFloat (item.large_cost) + parseFloat (total.value.large);

            item.small_cost_pretty = formatter.format (item.small_cost);
            item.regular_cost_pretty = formatter.format (item.regular_cost);
            item.large_cost_pretty = formatter.format (item.large_cost);
         }
      }

      total.value.small = formatter.format (total.value.small);
      total.value.regular = formatter.format (total.value.regular);
      total.value.large = formatter.format (total.value.large);

      showRecipeDialog.value = showRecipeDialog.value == true ? false : true;
   }
</script>

<style scoped>
   .recipe-dialog { z-index: 15 !important; }

   .recipe-title 
   {
      font-size: 1.2em !important; 
      font-weight: bold;
      font-variant: small-caps;
      margin-bottom: 20px;
   }

   .recipe-button
   {
      width: 100px;
      min-width: 100px;
      height: 25px;
      font-size: .7em;
      padding: 2px 10px 2px 10px;
      margin: 2px 5px 2px 5px;
      border: 2px solid green;
      border-radius: 25px;
      text-shadow: 2px 2px 3px rgba(0,0,0,.5);
   }
   .recipe-button:hover
   {
      cursor: pointer;
      background: rgba(0,0,0,.5);
   }

   .recipe-header
   {
      font-weight: bold;
      font-size: 1.1em; 
      background: #252343; 
      margin-top: 3px;
      padding: 3px 3px 3px 3px;
      border-top: 1px solid var(--input-label-border-color);
      border-bottom: 1px solid var(--input-label-border-color);
   }

   .recipe-cell
   {
      border-bottom: 1px solid var(--input-label-border-color);
      height: 40px;
      line-height: 40px;
      font-size: 1.2em;
   }

   .full {width: 100%;}
   .xboxed { border: 1px solid var(--input-label-border-color);}
</style>
