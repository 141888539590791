<template>

  <!-- This allows us to select an ingredient image -->
   <Dialog position="top" header="Select an Icon" v-model:visible="showImageDialog" :modal="true" dismissableMask :style="{width: '90vw'}">
      <div class="container container-full container-column">
         <div class="container container-full container-row">
            <div class="container container-column container-20">
               <div class="container icon-text-header">Current Icon</div>
               <div class="recipe-navigation-button">
                  <div class="recipe-navigation-button-icon"><img :src="current_icon"></div>
               </div>
               <div class="container icon-text-header">Selected Icon</div>
               <div v-if="selected_icon" class="recipe-navigation-button">
                  <div class="recipe-navigation-button-icon"><img :src="selected_icon"></div>
               </div>
            </div>

            <div class="container container-70">
               <IconSelector v-on:change="iconSelected" :icon="record.icon" :default="default_icon" no_header />
            </div>
         </div>

         <div class="container container-right">
            <Button label="Close" icon="pi pi-times" @click="closeImageDialog" class="p-button-text"/>
            <Button label="Save" icon="pi pi-save" @click="imageCallback" :disabled="!saveImageButton" autofocus />
         </div>
      </div>
   </Dialog>

   <ToastTip />
   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
         <div v-if="!editIngredientTypeFlag" class="container container-column container-left container-full">
            <div class="container container-row container-left">
               <h1>Manage Ingredient Types</h1>

               <div class="container container-right container-grow container-gap-10">
                  <div @click="editIngredientType ('new', 0)" class="call-to-action-button">
                     Add Ingredient Type <i class="fa-solid fa-square-plus"></i>
                  </div>
               </div>
            </div>

            <div class="container container-row container-wrap container-full container-left">
               <div v-for="(rec, idx) in ingredient_types" :key="idx">
                  <div class="product-name-button large-product-name-button" v-on:click="editIngredientType ('edit', rec.id)">
                     <div class="product-name-button-icon"><img :src="rec.image"></div>
                     <div class="product-name-button-text">{{rec.name}}</div>
                     <div class="product-name-button-placeholder"></div>
                  </div>
               </div>
            </div>
         </div>

         <div v-if="editIngredientTypeFlag" class="container container-column container-left container-full">
            <div class="container container-row container-left">
               <h1>Manage Ingredient Type :: {{record.name}}</h1>
               <div class="container container-grow container-right">
                  <Button label="Close" icon="pi pi-times" class="p-button-text" @click="closeEditIngredientType" />
                  <Button label="Save" icon="pi pi-save" @click="saveIngredientTypes" :disabled="saveDisabled" autofocus />
               </div>
            </div>

            <div class="container container-column container-left">
               <div class="container container-left">
                  <div class="input-label">Name</div>
                  <InputText id="name" type="text" size="50" v-model="record.name" />
               </div>

               <div class="container container-left">
                  <div class="input-label">Unique Two Letter Code</div>
                  <InputText id="code" type="text" size="8" v-model="record.code" :disabled="editing" />
               </div>

               <div class="container container-left">
                  <div class="input-label">Tooltip</div>
                  <InputText id="tooltip" type="text" size="50" v-model="record.tooltip" />
               </div>

               <div class="container container-left">
                  <div class="input-label">Icon</div>
                  <div class="recipe-navigation-button">
                     <div class="recipe-navigation-button-icon" v-on:click="openImageDialog ('ingredient', record)"><img :src="record.image"></div>
                  </div>
               </div>

               <div class="container container-left">
                  <div class="input-label">Description</div>
                  <Textarea id="description" v-model="record.description" :autoResize="true" rows="5" cols="69" v-on:focusout="setTooltip" />
               </div>

               <div class="container container-column">
                   <div class="container container-left">
                      <div class="input-label label-col">Ingredient Subtypes</div>
                      <div class="input-label name-col">Name</div>
                      <div class="input-label description-col">Description</div>
                      <div class="input-label icon-col">Icon</div>
                   </div>

                   <div v-for="(rec, idx) in ingredient_subtypes" v-bind:key="idx" class="container container-left">
                      <div class="input-label label-col">{{rec.label}}</div>
                      <InputText class="name-col" type="text" v-model="rec.name" />
                      <InputText class="description-col" type="text" v-model="rec.description" />
                      <div class="icon-col" v-on:click="openImageDialog ('subtype', rec)"><img class="subtype-icon" :src="rec.image"></div>
                      <div v-if="rec.id" class="delete-record-icon" v-on:click="deleteIngredientSubTypeRecord (idx, rec)" v-tooltip="'Delete Record'"><i class="fa-solid fa-circle-xmark"></i></div>
                   </div>
                </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, computed, onMounted } from "vue";
   import { RestGet, RestPost, RestPut, RestDelete } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'

   import Textarea from 'primevue/textarea';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import { useToast } from "primevue/usetoast";
   import AdminNavigation from '../AdminNavigation.vue'
   import IconSelector from '../../Lib/Selectors/IconSelector.vue'
   import Dialog from 'primevue/dialog';

   const toast = useToast();
   const profile = userProfileStore();
   const router = useRouter();

   const ingredient_types = ref([]);
   const ingredient_subtypes = ref ([]);
   var editIngredientTypeFlag = ref (false);
   var selectedIcon = ref ({});
   var showImageDialog = ref (false);
   var saveImageButton = ref (false);
   var current_icon = ref ();
   var default_icon = ref ([]);
   var selected_icon = ref ();
   var editAction = ref (false);
   var record = ref ({});
   var tmp_record = ref ({});
   var editing = ref (false);
   var imageCallback = ref();
   const maxSubTypes = 5;

   const saveDisabled = computed(() => !record.value.name || !record.value.code || 
                                       !record.value.tooltip || !record.value.description);

   onMounted(() =>
   {
      getIngredientTypes();
   })

   const setTooltip = function()
   {
      if (record.value.tooltip.length == 0)
      {
         var sentences = record.value.description.split (".");
         record.value.tooltip = sentences[0] + ".";
      }
   }

   const closeImageDialog = function () {showImageDialog.value = false;}
   const openImageDialog = function (type, obj)
   {
      showImageDialog.value = true;
      default_icon.value = [obj.icon];
      selected_icon.value = obj.image;
      current_icon.value = obj.image;

      tmp_record.value = obj;

      imageCallback.value = saveSubTypeImage;
      if (type == 'ingredient')
      {
         imageCallback.value = updateIngredientTypeIcon;
      }
   }

   const iconSelected = function(obj)
   {
      saveImageButton.value = true;

      //
      // OK, this is shit coding on my part. Honest to god. OK...
      // selectedIcon is the icon object that was last clicked on...while
      // selected_icon is the current icon selected as dislayed above.
      // Both are needed and I really need to change my varaible names..this is rookie shit..
      //
      selectedIcon.value = obj;
      selected_icon.value = obj.image;
console.log ("IMAGE: ", obj.image);
   }

   const updateIngredientTypeIcon = function ()
   {
      closeImageDialog ();

      var icon = selectedIcon.value.icon;
      record.value.icon = icon;
      record.value.image = require (`@/assets/icons/misc/${icon}`);
   }

   const saveSubTypeImage = function ()
   {
      closeImageDialog ();

      var icon = selectedIcon.value.icon;
      tmp_record.value.icon = icon;
      tmp_record.value.image = require (`@/assets/icons/misc/${icon}`);
   }

   const closeEditIngredientType = function () { editIngredientTypeFlag.value = false; }
   const editIngredientType = function (action, id)
   {
      editIngredientTypeFlag.value = true;
      editAction.value = action;

      if (action == "edit")
      {
         for (var x=0; x < ingredient_types.value.length; x++)
         {
            if (ingredient_types.value[x].id == id)
            {
               record.value = ingredient_types.value[x];
               break;
            }
         }

         editing.value = true;

         getIngredientSubTypes();
      }
      else
      {
         record.value = {icon: "dyos.png", image: require (`@/assets/icons/misc/dyos.png`)};
         editing.value = false;

         for (x=0; x < maxSubTypes; x++)
         {
            var label = "Option " + (x+1);
            ingredient_subtypes.value[x] = {id: '', label: label, name: '', description: '',
                                            icon: "dyos.png", image: require (`@/assets/icons/misc/dyos.png`)};
         }
      }
   }

   const getIngredientTypes = function ()
   {
      ingredient_types.value = [];

      RestGet ("/ingredient/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient_types.value = response;

            for (var x = 0; x < ingredient_types.value.length; x++)
            {
               var icon = ingredient_types.value[x].icon;
               ingredient_types.value[x].image = require (`@/assets/icons/misc/${icon}`);
            }
         }
      });
   }

   const getIngredientSubTypes = function ()
   {
      var uri = "/ingredient/subtype/type/" + record.value.id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var array = response;

            for (var x=0; x < maxSubTypes; x++)
            {
               var label = "Subtype " + (x+1);
               if (array[x])
               {
                  var rec = array[x];

                  if (! rec.icon)
                  {
                     rec.icon = "dyos.png";
                  }
                  rec.image = require (`@/assets/icons/misc/` + rec.icon);

                  ingredient_subtypes.value[x] = {id: rec.id, name: rec.name, description: rec.description, label: label, icon: rec.icon, image: rec.image };
               }
               else
               {
                  ingredient_subtypes.value[x] = {id: '', label: label, name: '', description: '',
                                                 icon: "dyos.png", image: require (`@/assets/icons/misc/dyos.png`)};
               }
            }
         }
      });
   }

   //
   // This will save the data from the dialog, if needed.
   //
   const closeEditForm = function () {editIngredientTypeFlag.value = false;}
   const saveIngredientTypes = function ()
   {
      closeEditForm();

      var tooltip = record.value.tooltip;
      if (tooltip)
      {
         tooltip = record.value.tooltip.replaceAll ("'", "\\'");
      }

      var desc = record.value.description;
      if (desc)
      {
         desc = record.value.description.replaceAll ("'", "\\'");
      }

      var json = {name: record.value.name,
                  code: record.value.code,
                  icon: record.value.icon,
                  stage: ingredient_types.value.length + 1,
                  percent: 0,
                  minimum_selections: 0,
                  maximum_selections: 0,
                  tooltip: tooltip,
                  description: desc};

      //
      // Given the editAction, we either save (POST) or update (PUT)
      //
      var uri = "";

      if (editAction.value == "new")
      {
         RestPost ("/ingredient/type/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               saveIngredientSubTypes();
               return;
            }
         });
      }
      else
      {
         uri = "/ingredient/type/" + record.value.id;
         RestPut (uri, profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               saveIngredientSubTypes();
               return;
            }
         });
      }
   }

   const saveIngredientSubTypes = function ()
   {
      var json = {'ingredient_type_id': record.value.id, 'subtypes': []};
      var count = 0;
      var mesg = "";

      for (var x = 0; x < ingredient_subtypes.value.length; x++)
      {
         if (ingredient_subtypes.value[x].name.length > 0)
         {
            if (ingredient_subtypes.value[x].id)
            {
               count++;
            }

            json['subtypes'].push ({'id': ingredient_subtypes.value[x].id,
                                    'name': ingredient_subtypes.value[x].name,
                                    'description': ingredient_subtypes.value[x].description,
                                    'icon': ingredient_subtypes.value[x].icon});
         }
      }

      if (count == 0)
      {
         mesg = "Record successfully saved.";
         toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});

         getIngredientTypes();
         return;
      }

      //
      // If the count is more than one, then we are updating (PUT). Otherwise, save (POST)
      //
      if (count > 0)
      {
         RestPut ("/ingredient/subtype/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }

            if (status.ok)
            {
               mesg = "Record successfully saved.";
               toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});

               getIngredientTypes();
               return;
            }
         });
      }
      else
      {
         RestPost ("/ingredient/subtype/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }

            if (status.ok)
            {
               mesg = "Record successfully saved.";
               toast.add ({severity: 'success', summary: "Record Saved", detail: mesg, life: 3000});

               getIngredientTypes();
               return;
            }
         });
      }
   }

   const deleteIngredientSubTypeRecord = function (row, rec)
   {
      var uri = "/ingredient/subtype/" + rec.id;
      RestDelete (uri, profile.token(), {}, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var label = "Option " + (row+1);
            ingredient_subtypes.value[row] = {id: '', label: label, name: '', description: '',
                                              icon: "dyos.png", image: require (`@/assets/icons/misc/dyos.png`)};
            return;
         }
      });
   }
</script>

<style scoped>
   .icon-col {width: 90px !important; min-width: 0px !important; height: 30px;}
   .label-col {width: 100px !important;; height: 30px;}
   .name-col {width: 200px !important; height: 30px; text-align: left !important;}
   .description-col {width: 350px !important; height: 30px; text-align: left !important;}
   .subtype-icon {width: 25px; height: 25px; margin-left: 25px; opacity: .5; cursor: pointer; }
   .subtype-icon:hover {opacity: 1;}
</style>
