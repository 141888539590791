import { defineStore } from 'pinia'

export const userSessionStore = defineStore('session', {
  state: () => ({
     storageName: "lastUserAction",
     tokenName: "userUIDToken",
     lastAction: null,
     isLoggedIn: false,
     sessionDuration: 3600000,
     token: null,
  }),

  getters: 
  {
  },

  actions:
  {
     get ()
     {
        this.lastAction = window.localStorage.getItem (this.storageName);
        return this.lastAction;
     },

     set()
     {
        this.lastAction = Date.now();
        window.localStorage.setItem (this.storageName, this.lastAction);
        return this.lastAction;
     },

     setToken(token)
     {
        window.localStorage.setItem (this.tokenName, token);
        return token;
     },

     clear()
     {
        this.lastAction = null;
        window.localStorage.removeItem (this.storageName);
     },

     login () { this.isLoggedIn = true; return this.isLoggedIn; },
     logout () { this.isLoggedIn = false; return this.isLoggedIn; },
     setDuration (duration) { this.sessionDuration = duration; },

     diff()
     {
        var d = Date.now();
        this.get();

        return d - this.lastAction;
     },

     remaining ()
     {
        if (this.sessionDuration === null)
        {
           this.sessionDuration = 3600000;
        }

        this.get();
        const difference =  Date.now() - this.lastAction;
        const expire = (this.sessionDuration - difference );

        return expire;
     },

     dump (message)
     {
        this.get();

        const now = Date.now();
        const nowString = new Date (now);
        const difference =  now - this.lastAction;
        const hms = this.secondsToHms (difference);
        const laString = new Date (this.lastAction);

        console.log ("SESSION DUMP: ", message);
        console.log ("   Duration   : ", this.sessionDuration);
        console.log ("   Now        : ", Date.now(), " - ", nowString.toLocaleString());
        console.log ("   Now        : ", now, " - ", nowString.toLocaleString());
        console.log ("   Last Action: ", this.lastAction, " - ", laString.toLocaleString());
        console.log ("   Difference : ", difference, " - ", hms);
        console.log ("   Expire     : ", (this.sessionDuration - difference ));
     },

     secondsToHms(d)
     {
        d = Number(d);

        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
    }
  },
})
