<template>
   <div v-if="props.buttons">
      <div id="button_container">
         <div v-if="props.rollover">
            <div class="container container-column container-full">
               <div class="container container-row container-full rollover-tooltip"><div class="rollover-tooltip-text">{{description}}</div></div>
               <div class="container container-full container-row container-wrap container-space-evenly selection-button-container" style="padding: 0px;">
                  <div v-for="(button, idx) in props.buttons" :id="id+'button_'+idx" v-bind:key="idx" class="recipe-navigation-button shrink-button" :class="buttonSize" v-on:click="selected (idx)" @mouseover="rollover ('in', button)" @mouseout="rollover ('out')">
                     <div v-if="selectedButton[idx]" class="selected-button"><i class="fa-solid fa-circle-check"></i></div>
                     <div class="recipe-navigation-button-icon"><img :src="button.image"></div>
                     <div v-if="button.name" class="recipe-navigation-button-text">{{button.name}}</div>
                     <div v-if="button.label" class="recipe-navigation-button-label">{{button.label}}</div>
                  </div>
               </div>
            </div>
         </div>
         <div v-else>
            <div class="container container-full container-row container-wrap container-space-evenly selection-button-container" style="padding: 0px;">
               <div v-for="(button, idx) in props.buttons" :id="id+'button_'+idx" v-bind:key="idx" class="recipe-navigation-button" :class="buttonSize" v-on:click="selected (idx)" v-tooltip.top="button.description">
                  <div v-if="selectedButton[idx]" class="selected-button"><i class="fa-solid fa-circle-check"></i></div>
                  <div class="recipe-navigation-button-icon"><img :src="button.image"></div>
                  <div v-if="button.name" class="recipe-navigation-button-text">{{button.name}}</div>
                  <div v-if="button.label" class="recipe-navigation-button-label">{{button.label}}</div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { computed, ref, onUpdated, defineProps, defineEmits, onMounted, watch } from 'vue';

   const isSelected = ref (false);
   const selectedButton = ref ([]);
   var multiple = ref (false);
   var description = ref ("");
   var selected_button_description = ref("");
   var first_load = true;
   var id = ref ();

   isSelected.value = props.value == props.modelValue ? true : false;
   const props = defineProps ({id: [String], buttons: [Object], default: [Object], scroll: [Number, String], rollover: [Boolean],
                               multiple: [Boolean], single: [Boolean], wide: [Boolean], no_text: [Boolean],
                               maximum: [Number, String], small: [Boolean], large: [Boolean]});

   onMounted(() =>
   {
      init();
   });

   onUpdated(() =>
   {
      //
      // Only do this on the first load.
      //
      if (props.buttons && props.buttons[0] && first_load)
      {
         rollover ('in', props.buttons[0]);
         first_load = false;
      }
   });

   const init = function ()
   {
      var x = 0;

      if (props.buttons)
      {
         for (x = 0; x < props.buttons.length; x++)
         {
            selectedButton.value[x] = false;
         }
      }

      id.value = "button_container";
      if (props.id)
      {
         id.value = props.id;
      }
      id.value = "button_container";

      if (props.default)
      {
         for (x = 0; x < props.default.length; x++)
         {
             selectedButton.value[props.default[x]] = true;
         }
      }

      if (props.scroll)
      {
         turnOnScroll();
      }

      if (props.multiple || props.maximum > 1)
      {
         multiple.value = true;
      }

   }

   const rollover = function (direction, button)
   {
      if (button)
      {
         description.value = selected_button_description.value;
         if (direction == 'in')
         {
             description.value = button.description;
         }
      }
   }

   const turnOnScroll = function ()
   {
      var el = document.getElementById (id.value);
      if (el)
      {
         el.style.maxHeight = props.scroll + "px";
         el.style.overflowY = "scroll";

         scrollToSelectedIcon();
      }
   }

   const scrollToSelectedIcon = function()
   {
      if (props.default)
      {
         var id = props.default[0];
         var el = document.getElementById ("button_" + id);

         if (el)
         {
            var cont = document.getElementById ("button_container");

            //
            // OK, this is how this works. The ID is the # of the button we are scrolling to. This is the
            // first button in the list, which is why we use props.default[0]. The buttons.length is the
            // number of buttons provided. The scrollHeight is the full height of the scrolled area.
            // The props.scroll is the viewport, which we provided.  We divide  the view port by 2, so the
            // button is shown roughly halfway down the scoll viewport.
            //
            cont.scrollTop = ((id / props.buttons.length) * cont.scrollHeight) - (props.scroll/2);
         }
      }
   }

   const buttonSize = computed (function ()
   {
      var class_list = "regularbuttons shrink-shift"

      if (props.small)
      {
         class_list = "smallbuttons";
      }
      else if (props.large)
      {
         class_list = "largebuttons";
      }
     
      if (props.wide)
      {
         return class_list + " widebuttons";
      }
      return class_list;
   });

   const emit = defineEmits(['click', 'change']);
   const selected = function (idx)
   {
      if (multiple.value)
      {
         //
         // If we are turning the button on, check to see if we have hit the max.
         //
         if (! selectedButton.value[idx])
         {
            var count = 0;

            for (var y = 0; y < selectedButton.value.length; y++)
            {
               if (selectedButton.value[y])
               {
                  count++;
               }
            }

            //
            // If we have hit the limit, return.
            //
            if (count >= props.maximum)
            {
               return;
            }
         }

         selected_button_description.value = props.buttons[idx].description;
         selectedButton.value[idx] = !selectedButton.value[idx];

         var button_list = [];
         for (var x = 0; x < selectedButton.value.length; x++)
         {
            if (selectedButton.value[x])
            {
               button_list[x] = props.buttons[x];
            }
         }

         emit ('click', button_list, selectedButton.value);
         emit ('change', button_list, selectedButton.value);
      }
      else
      {
         selectedButton.value.fill (false);
         selectedButton.value[idx] = true;

         if (props.buttons && props.buttons[idx])
         {
            selected_button_description.value = props.buttons[idx].description;
         }

         emit ('click', props.buttons[idx], selectedButton.value);
         emit ('change', button_list, selectedButton.value);
      }
   }

   watch(() => props.modelValue, () =>
   {
      if (!props.multiple)
      {
         isSelected.value = props.value == props.modelValue ? true : false;
      }
   });
</script>

<style scoped>
   .selected-button
   {
      position: absolute;
      right: -5px;
      top: -5px;
   }
   .selected-button i
   {
      color: green;
      font-size: 20px;
      background: white;
      border: 0px solid green;
      border-radius: 10px;
      height: 20px;
      width: 20px;
   }
   .selection-button-container
   {
      position: relative;
      overflow: hidden;
      transition: all 0.5s ease;
   }
   .shrink-button { transition: all 0.5s ease; }
   .shrink-shift { transform: translate(-50%, -50%) scale(0.6); }

   .largebuttons
   {
       height: 90px;
       width: 90px;
       font-size: .8em !important;
   }
   .regularbuttons
   {
       height: 70px;
       width: 70px;
       font-size: .7em !important;
   }
   .smallbuttons
   {
       height: 50px;
       width: 50px;
       font-size: .6em !important;
   }

   .widebuttons { width: 80px !important; }

   .rollover-tooltip
   {
      display: fkex;
      border: 1px solid rgba(200, 200, 200, .3);
      border-radius: 5px;
      font-size: .6em;
      justify-content: flex-start;
      text-align:justify;
      flex-grow: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      min-width: 100%;
   }
   .rollover-tooltip-text
   {
      font-size: 1.1em;
      min-height: 1.2em;
      max-height: 6em;
      flex-grow: 2;
      overflow: hidden;
      text-overflow: ellipsis;
   }
</style>
