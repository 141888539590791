<template>
   <Dialog position="top" :header="header" v-model:visible="displayEdit" :modal="true" dismissableMask :style="{width: '60vw'}">
      <div class="container container-left">
         <div class="input-label">Name</div>
         <InputText id="name" type="text" size="50" v-model="record.name" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Available</div>
         <ToggleButton v-model="record.is_available_bool" onIcon="pi pi-check" offIcon="pi pi-times" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Tooltip</div>
         <InputText id="tooltip" type="text" size="50" v-model="record.tooltip" :disabled="disabled" />
      </div>

      <div class="container container-left">
         <div class="input-label">Description</div>
         <Textarea id="description" v-model="record.description" :autoResize="true" rows="5" cols="65" :disabled="disabled"  />
      </div>

      <template #footer>
         <Button label="Close" icon="pi pi-times" @click="closeDialog" class="p-button-text"/>
         <Button :label="dialogLabel" icon="pi pi-save" @click="saveData" autofocus />
      </template>
   </Dialog>
</template>

<script setup>
   import { ref, onMounted, defineEmits, defineProps } from "vue";
   import { RestGet, RestPost, RestPut, RestDelete } from '@/plugins/webapi';

   import { userProfileStore } from '@/stores/profile';
   import { useRouter } from 'vue-router'

   import ToggleButton from 'primevue/togglebutton';
   import Textarea from 'primevue/textarea';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import Dialog from 'primevue/dialog';

   const profile = userProfileStore();
   const router = useRouter();

   var dialogLabel = ref ("");
   var disabled = ref (false);
   var header = ref ("");
   var displayEdit = ref (false);
   var record = ref ({});
   
   const product_types = ref();

   const props = defineProps ({action: String, data: Object});
   const emit = defineEmits(['save']);

   onMounted(() =>
   {
      getProductTypes();

console.log ("PROPS: ", props.action, props.data);

      if (props.action == "add")
      {
         addProductType();
      }
      else if (props.action == "edit")
      {
         editProductType();
      }
      else if (props.action == "delete")
      {
         deleteProductType();
      }
   })

   const addProductType = function ()
   {
      header.value = "Add Product Type";
      disabled.value = false;
      dialogLabel.value = "Save";

      record.value = {name: '',
                      tooltip: '',
                      description: '',
                      is_available_bool: true};

      displayEdit.value = true;
   }

   const editProductType = function ()
   {
      header.value = "Edit Product Type";
      disabled.value = false;
      dialogLabel.value = "Edit";

      record.value.id = props.data.id;
      record.value.name = props.data.name;
      record.value.tooltip = props.data.tooltip;
      record.value.description = props.data.description;
      record.value.is_available_bool = props.data.is_available ? true : false;

      displayEdit.value = true;
   }

   const deleteProductType = function ()
   {
      header.value = "Delete Product Type";
      disabled.value = true;
      dialogLabel.value = "Delete";

      record.value.id = props.data.id;
      record.value.name = props.data.name;
      record.value.tooltip = props.data.tooltip;
      record.value.description = props.data.description;
      record.value.is_available_bool = props.data.is_available ? true : false;

      displayEdit.value = true;
   }

   const closeDialog = function () { displayEdit.value = false; }
   const saveData = function ()
   {
      closeDialog();

      var desc = record.value.description;
      if (desc)
      {
         desc = record.value.description.replaceAll ("'", "\\'");
      }

      var tooltip = record.value.tooltip;
      if (tooltip)
      {
         tooltip = record.value.tooltip.replaceAll ("'", "\\'");
      }


      //
      // XXX - FIX THE ICONS!
      //
      var json = {name: record.value.name, 
                  is_available: record.value.is_available_bool ? 1 : 0,
                  tooltip: tooltip, 
                  icon: "dyos.png",
                  description: desc};

      var uri = "";
      if (props.action == "add")
      {
         RestPost ("/product/type/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               emit ("save", "Record Saved", "Record sucessfully saved.");
               return;
            }
         });
      }
      else if (props.action == "delete")
      {
         uri = "/product/type/" + record.value.id;
         RestDelete (uri, profile.token(), {}, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               emit ("save", "Record Deleted", "Record sucessfully deleted.");
               return;
            }
         });
      }
      else
      {
         uri = "/product/type/" + record.value.id;
         RestPut (uri, profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               emit ("save", "Record Updated", "Record successfully updated.");
               return;
            }
         });
      }
   }

   const getProductTypes = function ()
   {
      product_types.value = [];
      RestGet ("/product/type/all", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_types.value = response;

            //
            // NOTE:
            // We need to do this in order to load the images. We will need this pattern moving forward.
            //
            for (var x = 0; x < product_types.value.length; x++)
            {
               var id = product_types.value[x].id;

               product_types.value[x].icon = require (`@/assets/icons/product/type/${id}.png`);
               product_types.value[x].is_available_string = product_types.value[x].is_available == 1 ? "Yes" : "No";
               product_types.value[x].is_available_bool = product_types.value[x].is_available == 1 ? true : false;
            }
         }
      });
   }
</script>

<style scoped>
   .menu-button
   {
      height: 30px;
      font-weight: bold;
      font-face: var(--font-face);
      font-size: .8em;
      margin-top: 10px;
      background: #19BF55;
      border: 1px solid #237C43 !important;
   }
   .menu-button:hover
   {
      background: #237C43 !important;
      color: #ffffff !important;
   }

   .table-icon
   {
      height: 30px;
      width: 30px;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex; 
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
   }
</style>
