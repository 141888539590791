<template>
   <div class="page">
      <LeftNavigation inactive />

      <div class="content content-top">
        <div class="card card-full">
           <h1>Welcome to Design Your Own Skincare</h1>

           <TabView ref="tabview" v-model:activeIndex="active">
              <TabPanel header="Contact Details">
                 <div class="text">First, lets get your contact details so we can properly interact with you.</div>
                 <Divider />

                 <UserContactDetails v-if="person" v-model="person" v-on:change="updateContactDetails" />
              </TabPanel>

              <TabPanel header="Address Details" :disabled="contactDisabled">
                 <div class="text">We don't need your full address yet, but we would like to know where you are coming from.</div>
                 <Divider />

                 <UserAddress v-if="person" v-model="person" v-on:change="updateAddress" />
              </TabPanel>

              <TabPanel header="Customer Skin Survey" :disabled="addressDisabled">
                 <div class="text">At this point you can do a few things:
                    <br /> <br />
                    <li>The first you can answer a few questions so we can get to know your skin and your personal needs better.</li>
 
                    <li>You can skip the survey and take a tour of the platform to see what this is all about.</li>
                    <li>Or you can skip all this and click around and see what we are all about.</li>

                    <br />
 
                    You can always come back and do the survey or take the tour later by going to <span class="menu-button inline-menu-button">Settings <i class="fas fa-cog"></i></span> above.
                 </div>

                 <Divider />

                 <div class="container container-left text">
                    <router-link to="/survey/profile">
                       <div class="menu-button inline-menu-button">Take the Survey <i class="fa-solid fa-file-pen"></i></div>
                    </router-link>

                    <router-link to="/tour">
                       <div class="menu-button inline-menu-button">Take a Tour <i class="fa-solid fa-globe"></i></div>
                    </router-link>

                    <router-link to="/home">
                       <div class="menu-button inline-menu-button">Start Crafting <i class="fa-solid fa-palette"></i></div>
                    </router-link>
                 </div>
              </TabPanel>
           </TabView>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, computed, onMounted } from 'vue';
   import { useRouter } from 'vue-router'
   import { RestPost } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { loginStatusStore } from '@/stores/login.js'

   import UserContactDetails from './Lib/UserContactDetails.vue'
   import UserAddress from './Lib/UserAddress.vue'
   import LeftNavigation from './LeftNavigation.vue'
   import TabView from 'primevue/tabview';
   import TabPanel from 'primevue/tabpanel';
   import Divider from 'primevue/divider';

   const profile = userProfileStore();
   const router = useRouter();
   let person = ref ({first_name: '', last_name: '', nickname: '', avatar: {}});
   let active = ref (0);

   //
   // Disables the navigation menus/buttons
   //
   const contactDisabled = computed(() => !person.value.first_name || !person.value.last_name || !person.value.email || !person.value.nickname);
   const addressDisabled = computed(() => !person.value.city || !person.value.region || !person.value.country);

   onMounted (() =>
   {
      readUserProfile ();

person.value.first_name = "Mike";
person.value.last_name = "Glover";
person.value.phone = "5551112222";

      loginStatusStore.loggedIn = true;
   })

   const readUserProfile = function()
   {
      var user = profile.read();

      if (user)
      {
         var fields = ["id", "uid", "first_name", "last_name", "email", "phone", "nickname",
                       "address", "city", "region", "country", "postal_code"];

         person.value = {};

         for (var field of fields)
         {
            if (user[field])
            {
               person.value[field] = user[field];
            }
         }
      }
      else
      {
         person.value = {country: "Canada", region: "Ontario", city: "--"};
      }
   }

   const updateContactDetails = function (val)
   {
      var fields = ["first_name", "last_name", "email", "phone", "nickname"];
      for (var field of fields)
      {
         if (val[field])
         {
            person.value[field] = val[field];
         }
      }

      active.value = 1;
   }

   const updateAddress = function (val)
   {
      var fields = ["country", "region", "city", "address", "postal_code"];
      for (var field of fields)
      {
         if (val[field])
         {
            person.value[field] = val[field];
         }
      }

console.log ("PERSON: ", person.value);

       saveAddress();
   }

   const saveAddress = function ()
   {
      var json = {address: person.value.address ? person.value.address : '',
                  city: person.value.city,
                  region: person.value.region,
                  country: person.value.country,
                  postal_code: person.value.postal_code ? person.value.postal_code : ''}

      RestPost ("/address/", profile.token(), json, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            saveCustomerDetails (response.id);
         }
      });
   }

   const saveCustomerDetails = function (address_id)
   {
      var json = {address_id: address_id, 
                  uid: person.value.uid,
                  first_name: person.value.first_name,
                  last_name: person.value.last_name,
                  nickname: person.value.nickname,
                  email: person.value.email,
                  phone: person.value.phone ? person.value.phone : '',
                  };

      RestPost ("/customer/", profile.token(), json, function (status, response)
      {
         if (status.ok)
         {
            profile.add ("id", response.id);
            profile.add ("displayName", person.value.first_name + " " + person.value.last_name);
            profile.add ("first_name", person.value.first_name);
            profile.add ("last_name",person.value.last_name);
            profile.add ("email", person.value.email);
            profile.add ("nickname", person.value.nickname);

            loginStatusStore.refresh = true;

            active.value = 2;
         }
      });
   }
</script>

<style scoped>

.required {color: red}
.text { font-size: 1.3em !important; }
.p-tabview { width: 100%; }
li {margin: 9px 3px 3px 40px; list-style-type: square;}

.inline-menu-button
{
   font-size: 1em !important; 
   max-width: fit-content; 
   white-space: nowrap !important; 
   display: inline-block !important;
   vertical-align: middle;
   line-height: 20px;
}

.message
{
   white-space: pre-line;
   font-size: 1.3em;
}

.button-text
{
   font-weight: bold;
   font-size: 1em;
}

.button-icon
{
   font-size: 1.8em;
   padding-left: 10px;
}

.product_name
{
   padding-left: 20px;
   font-size: 1.2em;
   font-weight: bold;
   font-variant: small-caps;
}

</style>
