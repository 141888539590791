
//const base = "http://localhost:9000";
const base = "https://api.designyourownsoap.com";
const debug = true;

export function RestGet (uri, token, params, callback)
{
   if (! token)
   {
      callback ({timeout: 1}, {message: 'Session timed out'});
      return;
   }

   //
   // If paramaters were provided, attach them to the end of the URI
   //
   if (params)
   {
      var query = "?";
      for (var x in params)
      {
         var obj = params[x];
         for (let key of Object.keys(obj))
         {
            query += key + "=" + obj[key] + "&";
         }
      }
   }

   var requestOptions = {method: "GET", 
                        headers: {"Content-Type": "application/json",
                                  "Authorization": "Bearer " + token,
                                  "Charset": "utf-8"}};
   var url = base + uri + query.slice (0, -1);

   if (debug)
   {
      console.log ("RestGet : ", url, requestOptions);
   }

   fetch(url, requestOptions)
         .then (function (response)
         {
            if (debug)
            {
               console.log ("  >>>> RESPONSE RestGet : ", response);
            }

            if (response.ok)
            {
               return response.json();
            }

            if (response.status === 461)
            {
               callback ({timeout: 1}, {message: response});
            }
            else if (response.status === 462)
            {
               callback ({disabled: 1}, {message: response});
            }
            else
            {
               callback ({error: 1}, {message: response});
            }

            console.error ("Fetch Error: ", response);
            return;
         })
         .then (function (response)
         {
            if (! response || ! response.data)
            {
               console.error (response);
               callback ({error: 1}, {message: response});
               return;
            }

            var data = {};
            if (response.data && response.data.length > 0)
            {
               data = JSON.parse (response.data);
            }
            callback ({ok: 1}, data);
         })
         .catch((error) =>
         {
            if (debug)
            {
               console.log ("  >>>> RESPONSE RestGet : ", error);
            }

            callback ({error: 1}, {message: error});
            return;
         });
}

export function RestPost (uri, token, params, callback)
{
   if (! token)
   {
      callback ({timeout: 1}, {message: 'Session timed out'});
      return;
   }

   var requestOptions = {method: "POST", 
                         headers: {"Content-Type": "application/json",
                                   "Authorization": "Bearer " + token,
                                   "Charset": "utf-8"},
                         body: {}};

   if (params)
   {
      requestOptions.body = JSON.stringify (params);
   }

   var url = base + uri;

   if (debug)
   {
      console.log ("RestPost : ", url, params);
   }

   fetch(url, requestOptions)
         .then (function (response)
         {
            if (debug)
            {
               console.log ("  >>>> RESPONSE RestPost : ", response);
            }

            if (response.ok)
            {
               return response.json();
            }

            console.error ("Fetch Error: ", response);
            callback ({error: 1}, {message: response});
            return;
         })
         .then (function (response)
         {
            if (! response)
            {
               console.error (response);
               callback ({error: 1}, {message: response});
               return;
            }

            var data = {};
            if (response.data && response.data.length > 0)
            {
               data = JSON.parse (response.data);
            }
            callback ({ok: 1}, data);
         })
         .catch((error) =>
         {
            callback ({error: 1}, {message: error});
            return;
         });
}

export function RestPut (uri, token, params, callback)
{
   if (! token)
   {
      callback ({timeout: 1}, {message: 'Session timed out'});
      return;
   }

   var requestOptions = {method: "PUT", 
                         headers: {"Content-Type": "application/json",
                                   "Authorization": "Bearer " + token,
                                   "Charset": "utf-8"},
                         body: {}};

   if (params)
   {
      requestOptions.body = JSON.stringify (params);
   }

   var url = base + uri;

   if (debug)
   {
      console.log ("RestPut : ", url, params);
   }

   fetch(url, requestOptions)
         .then (function (response)
         {
            if (debug)
            {
               console.log ("  >>>> RESPONSE RestPut : ", response);
            }

            if (response.ok)
            {
               return response.json();
            }

            console.error ("Fetch Error: ", response);
            callback ({error: 1}, {message: response});
            return;
         })
         .then (function (response)
         {
            if (! response)
            {
               console.error (response);
               callback ({error: 1}, {message: response});
               return;
            }

            var data = {};
            if (response.data && response.data.length > 0)
            {
               data = JSON.parse (response.data);
            }
            callback ({ok: 1}, data);
         })
         .catch((error) =>
         {
            callback ({error: 1}, {message: error});
            return;
         });
}

export function RestDelete (uri, token, params, callback)
{
   if (! token)
   {
      callback ({timeout: 1}, {message: 'Session timed out'});
      return;
   }

   //
   // If paramaters were provided, attach them to the end of the URI
   //
   if (params)
   {
      var query = "?";
      for (var x in params)
      {
         var obj = params[x];
         for (let key of Object.keys(obj))
         {
            query += key + "=" + obj[key] + "&";
         }
      }
   }

   var requestOptions = {method: "DELETE", 
                        headers: {"Content-Type": "application/json",
                                  "Authorization": "Bearer " + token,
                                  "Charset": "utf-8"}};

   var url = base + uri + query.slice (0, -1);
   if (debug)
   {
      console.log ("RestDelete : ", url);
   }
   fetch(url, requestOptions)
         .then (function (response)
         {
            if (debug)
            {
               console.log ("  >>>> RESPONSE RestDelete : ", response);
            }

            if (response.ok)
            {
               return response.json();
            }

            console.error ("Fetch Error: ", response);
            callback ({error: 1}, {message: response});
            return;
         })
         .then (function (response)
         {
            if (! response)
            {
               console.error (response);
               callback ({error: 1}, {message: response});
               return;
            }

            var data = {};
            if (response.data && response.data.length > 0)
            {
               data = JSON.parse (response.data);
            }
            callback ({ok: 1}, data);
         })
         .catch((error) =>
         {
            callback ({error: 1}, {message: error});
            return;
         });
}

export function RestUpload (uri, token, files, callback)
{
   if (! token)
   {
      callback ({timeout: 1}, {message: 'Session timed out.'});
      return;
   }

   if (! files)
   {
      callback ({error: 1}, {message: 'File not provided.'});
      return;
   }

   var formData = new FormData();
   formData.append ("file", files[0]);

   //
   // DO NOT SET THE Content-Type to multipart/form-data It will fuck things UP!
   // Because the webbrowser will set it and the payload is fucked if this happens.
   //
   var requestOptions = {method: "POST", 
                         headers: {"Accept": "application/json",
                                   "Authorization": "Bearer " + token},
                         body: formData};

   var url = base + uri;

   if (debug)
   {
      console.log ("RestUpload : ", url, requestOptions);
   }

   fetch(url, requestOptions)
         .then (function (response)
         {
            if (debug)
            {
               console.log ("  >>>> RESPONSE RestUpload : ", response);
            }

            if (response.ok)
            {
               return response.json();
            }

            console.error ("Fetch Error: ", response);
            callback ({error: 1}, {message: response});
            return;
         })
         .then (function (response)
         {
            if (! response)
            {
               console.error (response);
               callback ({error: 1}, {message: response});
               return;
            }

            var data = {};
            if (response.data && response.data.length > 0)
            {
               data = JSON.parse (response.data);
            }
            callback ({ok: 1}, data);
         })
         .catch((error) =>
         {
            callback ({error: 1}, {message: error});
            return;
         });
}

