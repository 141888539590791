<template>
   <div class="container container-row container-full">
      <div class="container container-column container-center container-grow">
         <div class="avatar-title">Current Avatar</div>
         <div class="avatar-image"><img :src="avatar"></div>
      </div>

      <div class="container container-column container-center container-grow">
         <div class="avatar-title">Select a New Avatar</div>
         <div class="container container-left container-row">
            <div class="container">
               <input id="imageSelectButton" type="file" ref="filename" accept="image/*" v-on:change="loadFile" style="display: none;">
               <label for="imageSelectButton" class="avatar-upload-button" v-tooltip="'Click Here to Select a New Avatar'">
                  Select<i class="fa-solid fa-image button-icon"></i>
               </label>
            </div>

            <Button @click="uploadFile" class="avatar-upload-button" :disabled="uploadDisabled" v-tooltip="'Click Here to Select a New Avatar'">
                Upload<i class="fa-solid fa-save button-icon"></i>
            </Button>

            <div class="container" style="margin-left: 20px">
               <div class="avatar-image"><img ref="preview_image"/></div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, defineEmits, onMounted } from 'vue';
   import { userProfileStore } from '@/stores/profile';
   import { getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";

   import Button from 'primevue/button';

   const storage = getStorage();
   const profile = userProfileStore();
   const preview_image = ref(null)
   const uploadDisabled = ref (true);
   const filename = ref();
   const avatar = ref ();
   const user = ref (profile.get());

   const emit = defineEmits(['load']);

   onMounted (() =>
   {
      var path = "/images/" + user.value.uid + "/avatar";
      var avatarStorageRef = storageRef(storage, path)
      getDownloadURL (avatarStorageRef, path).then ((url) =>
      {
         profile.add ("avatar", url);
         avatar.value = url;
      })
      .catch(() =>
      {
         var path = "/images/avatar.png";
         var avatarStorageRef = storageRef(storage, path)
         getDownloadURL (avatarStorageRef, path).then ((url) =>
         {
            profile.add ("avatar", url);
            avatar.value = url;
         });
      });
   })

   const uploadFile = function ()
   {
      if (! filename.value || !filename.value.files)
      {
         console.error ("File Upload: There was an issue.");
         return;
      }

      var path = "/images/" + user.value.uid + "/avatar";
      var avatarStorageRef = storageRef(storage, path)

      uploadBytes (avatarStorageRef, filename.value.files[0]).then (() =>
      {
         getDownloadURL (avatarStorageRef, path).then ((url) =>
         {
            profile.add ("avatar", url);
            avatar.value = url;

            emit ('load', url);
         });
      });
   }

   const loadFile = function(event)
   {
      uploadDisabled.value = false;

      preview_image.value.src = URL.createObjectURL(event.target.files[0]);
      preview_image.value.onload = function()
      {
         URL.revokeObjectURL(preview_image.value.src);
      }
   }
</script>

<style scoped>

.avatar-title
{
   font-size: 1.1em;
   font-weight: bold;
   font-variant: small-caps;
   text-shadow: 2px 2px 3px rgba(0,0,0,.5);
   margin-bottom: 10px;
}
.avatar-image
{
   display: flex;
   justify-content: center;
   padding: 12px;
}
.avatar-image img
{
   height: 140px;
   width: 140px;
   border-radius: 70px;
   border: 2px solid var(--text-color);
   box-shadow: 5px 5px 5px #071427;
}

.avatar-upload-button
{
   background: #A5B4FC;
   text-align: center;
   line-height: 50px;
   height: 50px;
   width: 120px;
   font-weight: bold;
   color: #000000;
   font-size: 1em;
   margin: auto;
   border-radius: 15px;
   border: 1px solid #828EC9;
   white-space: pre-line;
}
.avatar-upload-button:hover
{
   background: #828EC9 !important;
   cursor: pointer;
}

.button-text
{
   font-weight: bold;
   font-size: 1em;
}

.button-icon
{
   font-size: 1.8em;
   padding-left: 10px;
}
</style>
