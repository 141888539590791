<template>
   <div v-if="filters" class="container container-left">
      <div v-if="props.label" class="ingredient-label">{{props.label}}</div>
      <div v-for="(filter, idx) in filters" v-bind:key="idx" >
         <ToggleButton class="filter-button" :onLabel="filter.name" :offLabel="filter.name" v-model="choices[filter.id]" v-on:click="selected (filter.id)" onIcon="pi pi-check" offIcon="pi pi-times" />
      </div>
   </div>
</template>

<script setup>
   import { ref, defineEmits, defineProps, onMounted } from 'vue';
   import { userProfileStore } from '@/stores/profile';
   import { RestGet } from '@/plugins/webapi';
   import { useRouter } from 'vue-router'
   import ToggleButton from 'primevue/togglebutton';

   const init_state = props.on ? true : false;
   const profile = userProfileStore();
   const router = useRouter();
   const filters = ref();
   const choices = ref ({});

   const props = defineProps ({ingredients: Object, label: String, on: Boolean});

   onMounted (() =>
   {
console.log ("INGREDIENTS (FILTER)", props.ingredients);
      getIngredientFilters();
   })

   const emit = defineEmits(['change']);
   const selected = function()
   {
      emit ('change', choices.value);
   }

   const getIngredientFilters = function ()
   {
      var params = [];

      for (var x=0; x < props.ingredients.length; x++)
      {
         params.push ({q: props.ingredients[x].id});
      }
console.log ("Q: ", params);

      var uri = "/attribute/list/";
      RestGet (uri, profile.token(), params, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            filters.value = response;
            for (var x in filters.value)
            {
               choices.value[filters.value[x].id] = init_state;
            }
         }
      });
   }
</script>

<style scoped>
.ingredient-label
{
   color: white;
   font-size: .9em;
   font-weight: bold;
   font-variant: small-caps;
   text-shadow: 2px 2px 3px rgba(0,0,0,.5);
   margin: 3px 20px 3px 10px;
}

.filter-button
{
   height: 25px;
   max-width: 150px;
   margin: 2px 3px 2px 3px;
}
</style>
