<template>
   <div id="information-box" class="navigation-item-information-box"></div>
   <div class="navigation-menu">
      <div class="navigation-menu-title">Admin Links</div>
      <div v-for="item in items" :key="item.id">
         <div v-if="item.link">
            <router-link :to="item.link">
               <div :id="'menuitem_' + item.id" class="navigation-menu-item" @mouseover="itemDetails ('show', item.id)" @mouseout="itemDetails ('hide')" >
                  <div class="admin-navigation-icon"><i :class="item.icon"></i></div>
                  <div class="admin-navigation-text">{{item.name}}</div>
               </div>
            </router-link>
         </div>
         <div v-else><hr /></div>
      </div>
   </div>
</template>

<script setup>
   import { ref } from 'vue';

   var items = ref ("");

   items.value = ([{id: 0, name: "Product Editor", icon: "fa-solid fa-soap", link: '/admin/products',
                    description: 'This menu item will take you to a page which will show you all the current \
                                  products defined. This is where you define new products, update existing \
                                  products, and change products availabilty.',
                   },
                   {id: 1, name: "Product Types", icon: "fa-solid fa-boxes-stacked", link: '/admin/product/types',
                    description: 'This is where you define major product types like Lotions, Soaps, Oils, \
                                  Serums, and Facemasks. These product types should be already defined.',
                   },
                   {id: 2, name: "Product Formulations", icon: "fa-solid fa-book-journal-whills", link: '/admin/product/formulas',
                    description: 'This is where you define a products formulation',
                   },
                   {id: 3, name: "Customer Product Workflow", icon: "fa-solid fa-book-journal-whills", link: '/admin/product/workflows',
                    description: 'This is where you define a products workflow for customers to make changes.'
                   },
                   {id: 4, name: "Product Sizes", icon: "fa-solid fa-chart-simple", link: '/admin/product/sizes',
                    description: 'This is where you will manage all the product sizes',
                   },
                   {id: 10},
                   {id: 11, name: "Ingredients", icon: "fa-solid fa-flask", link: '/admin/ingredients',
                    description: 'This is where you will load your raw ingredients. Things like your oils \
                                  (like avacado), our exfloiators (like sand), colors (like blue mica), \
                                   smells (like lemongrass), etc... You need to have some Ingredient Types defined \
                                   before you can load an ingredient.'
                   },
                   {id: 12, name: "Ingredient Types", icon: "fa-solid fa-flask-vial", link: '/admin/ingredient/types',
                    description: 'Ingredient types are like Exfoliator, Oil, Fragrence Oil, Essential \
                                  Oil, Color, or Additives. Ingredient Types are used to classify the ingredients \
                                  loaded into the system. These should be fully defined.',
                   },
                   {id: 13, name: "Ingredient Attributes", icon: "fa-solid fa-comment-dots", link: '/admin/ingredient/attributes',
                    description: 'Ingredient Attributes are things like Seasonal, Halloween, Floral, etc.. \
                                  which allows the user to refine their selections.',
                   },
                   {id: 20},

                   {id: 21, name: "Packaging Inventory", icon: "fa-solid fa-cart-flatbed", link: '/admin/packagng/inventory',
                    description: 'This is where you will maintain the inventory.',
                   },
                   {id: 21, name: "Packaging Types", icon: "fa-solid fa-box-open", link: '/admin/packaging/types',
                    description: 'This is where you will maintain the inventory.',
                   },
                   {id: 30},

                   {id: 31, name: "Order Manager", icon: "fa-solid fa-scroll", link: '/admin/orders',
                    description: 'This is where you will manage all the orders.',
                   },
               ]);

   const itemDetails = function (action, id)
   {
      const infobox = document.getElementById ("information-box");

      //
      // Hide the information box.
      //
      if (action == "hide")
      {
         infobox.style.opacity = 0;
         infobox.style.zIndex = -1;
         return;
      }

      //
      // Show the information box.
      //
      const el = document.getElementById('menuitem_' + id);
      var rect = el.getBoundingClientRect();
      let top = rect.top;
      let left = rect.width + 20;
      infobox.style.zIndex = 5;
      infobox.style.left = left + "px";
      infobox.style.top = top + "px";
      infobox.style.opacity = 1;

      var item = {};
      for (var x=0; x < items.value.length; x++)
      {
         if (items.value[x].id == id)
         {
            item = items.value[x];
            infobox.textContent = item.description;
            return;
         }
      }
   }
</script>

<style scoped>
   @import '@/assets/fonts/fontawesome6/css/all.css';

   hr
   {
      opacity: .25;
      margin: 10px 1px 15px 1px;
      border-top: dotted 1px;
   }

   .navigation-menu
   {
      width: 25%;
      height:100vh;
      padding-top: 10px;
   }
   .navigation-menu:hover
   {
      border-right: 1px solid var(--header-border-color);
   }
   .navigation-menu-title
   {
      padding-right: 3px;
      padding-left: 3px;
      margin-left: 57px;
   }
   .navigation-menu-item
   {
      display: flex;
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
   }
   .navigation-menu-item:hover
   {
      background-color: var(--menu-item-hover-color);
      border-radius: 5px;
      cursor: pointer;
   }

   .inactive-navigation-menu-item
   {
      display: flex;
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
      color: var(--bluegray-500);
   }
   .inactive-navigation-menu-item:hover
   {
      background-color: var(--menu-item-hover-color);
      border-radius: 5px;
      cursor: not-allowed
   }

   .admin-navigation-icon
   {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2px;
      height: 30px;
      width: 50px;
      background-color: red !important;
      color: #ffffff;
      margin-right: 20px;
      border-radius: 5px;
   }

   .navigation-item-information-box
   {
      position: absolute;
      top: 0px;
      left: 0px;
      height: auto;
      width: fit-content;
      max-width: 500px;
      z-index: 5;
      opacity: 0;
      font-size: .8em;
      padding: 5px;
      padding-right: 10px;
      padding-left: 10px;
      transition: all 0.5s ease-in-out;
      background-color: var(--menu-item-hover-color);
      border: 1px solid var(--header-border-color);
      border-right: 5px solid var(--header-border-color);
      border-left: 5px solid var(--header-border-color);
      border-radius: 10px;
   }
</style>
