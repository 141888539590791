<template>
   <div class="card card-large">
      <div class="selector-title">Personal Skin Care Goals</div>

      <SelectorTemplate :buttons=buttons :icon="ingredient_type_icon" :text=text v-on:change="change" v-on:save="save" :save_button="props.save_button" :next_button="props.next_button" multiple small />
   </div>
</template>

<script setup>
   import { ref, defineProps, defineEmits } from 'vue';
   import SelectorTemplate from "./SelectorTemplate";

   const props = defineProps ({save_button: [Boolean], next_button: [Boolean]});
   const ingredient_type_icon = require (`@/assets/icons/misc/leaf.png`);
   const text = ref (["Below is a list of standard goals for people's skin care regime. Let us know which goals you have in mind.\
                       This will help recommend more suitable products for you."]);
   const buttons = ref ([{name: "Hydrate", value: "hydration", description: "You want products which support your skin hydrating goals.", image: require (`@/assets/icons/misc/water.png`)},
                         {name: "Skin Tone", value: "oily", description: "You want products which support your goals of managing your skin tone goals.", image: require (`@/assets/icons/misc/color-wheel.png`)},
                         {name: "Anti-Aging", value: "dry", description: "You want products which support your goals in addressing fine lines.", image: require (`@/assets/icons/misc/hourglass.png`)},
                         {name: "Dryness", value: "combination", description: "You want products which support your goals of addressing dry skin.", image: require (`@/assets/icons/misc/cactus.png`)},
                         {name: "Maintain", value: "sensitive", description: "You want products which suport your current skin care regime.", image: require (`@/assets/icons/misc/cog.png`)}]);

   const emit = defineEmits(['save', 'change']);
   const change = function (obj) { emit ('change', obj); }
   const save = function (obj) { emit ('save', obj); }
</script>

<style scoped>
.selector-title
{
   font-size: .8em;
   font-weight: bold;
   font-variant: small-caps;
}
</style>
