<template>
   <Dialog position="top" :header="title" v-model:visible="showEditor" :modal="true" dismissableMask :style="{width: '60vw'}">
      <div class="container container-left">
         <div class="input-label">Name</div>
         <InputText id="name" type="text" size="40" v-model="record.name" autofocus />
         <Button label="Close" icon="pi pi-times" @click="closeDialog" class="p-button-text"/>
         <Button label="Save" icon="pi pi-file" @click="saveProduct (record.action)" class="p-button-text" :disabled="!record.name || !record.product_type_id || !record.tooltip || !record.description" />
      </div>

      <div class="container container-left">
         <div class="input-label">Product Type</div>
         <Dropdown v-model="record.product_type_id" :options="product_types" optionLabel="name" optionValue="id" placeholder="Select a Product Type"  />
      </div>

      <div class="container container-left">
         <div class="input-label">Product Available</div>
         <ToggleButton v-model="record.is_available_bool" onIcon="pi pi-check" offIcon="pi pi-times" />
      </div>

      <div class="container container-left">
         <div class="input-label">Product Formulation</div>
         <Dropdown v-model="record.formulation" :options="product_formulation" optionLabel="name" optionValue="value" placeholder="Select a Formulation Type" />
      </div>

      <div class="container container-left">
         <div class="input-label">Minimum Order</div>
         <InputText id="minimum_order" type="number" min="1" style="width: 8em" v-model="record.minimum_order" />
      </div>

      <div class="container container-left">
         <div class="input-label">Tooltip</div>
         <InputText id="tooltip" type="text" size="50" v-model="record.tooltip" />
      </div>

      <div class="container container-left">
         <div class="input-label">Description</div>
         <Textarea v-model="record.description" :autoResize="true" rows="5" cols="49" />
      </div>

      <div class="container container-left">
         <div class="input-label">Product Usage</div>
         <Textarea v-model="record.product_usage" :autoResize="true" rows="5" cols="49" />
      </div>
   </Dialog>
</template>

<script setup>
   import { ref, onMounted, defineEmits, defineProps } from "vue";
   import { RestGet, RestPost, RestPut, RestDelete } from '@/plugins/webapi';
   import { useRouter } from 'vue-router'
   import { userProfileStore } from '@/stores/profile';

   import Dialog from 'primevue/dialog';
   import Dropdown from 'primevue/dropdown';
   import ToggleButton from 'primevue/togglebutton';
   import Button from 'primevue/button';
   import Textarea from 'primevue/textarea';
   import InputText from 'primevue/inputtext';

   const profile = userProfileStore();
   const router = useRouter();
   const title= ref();
   const record = ref({});
   const showEditor = ref (false);
   const product_types = ref();
   const products = ref ();
   const product_formulation = ref ([{'name': "Preformulated", 'value': 'Preformulated'}, 
                                     {'name': "Full Craft", 'value': 'Full Craft'}]);

   const props = defineProps ({action: String, data: Object});
   const emit = defineEmits(['save']);

   onMounted(() =>
   {
      getProductTypes();

      if (props.action == "add")
      {
         addProduct();
      }
      else if (props.action == "edit")
      {
         editProduct();
      }
   })

   const addProduct = function ()
   {
      title.value = "Create a New Product";

      record.value.action = "add";
      record.value.id = null;
      record.value.product_type_id = null;
      record.value.formulation = product_formulation.value[0].value;
      record.value.name = null;
      record.value.minimum_order = 1;
      record.value.tooltip = null;
      record.value.description = null;
      record.value.is_available_bool = true;

      showEditor.value = true;
   }

   const editProduct = function ()
   {
      title.value = "Edit Product :: " + props.data.name;
      record.value.action = "edit";
      record.value.id = props.data.id;
      record.value.name = props.data.name;
      record.value.product_type_id = props.data.product_type_id;
      record.value.formulation = props.data.formulation;
      record.value.minimum_order = props.data.minimum_order;
      record.value.tooltip = props.data.tooltip;
      record.value.description = props.data.description;
      record.value.formulator_notes = props.data.formulator_notes;
      record.value.product_usage = props.data.product_usage;
      record.value.is_available_bool = props.data.is_available ? true : false;

      showEditor.value = true;
   }

   const closeDialog = function () {showEditor.value = false; }
   const saveProduct = function ()
   {
      closeDialog();

      //
      // Prepare the request.
      //
      var desc = record.value.description;
      if (desc)
      {
         desc = record.value.description.replaceAll ("'", "\\'");
      }
      var tooltip = record.value.tooltip;
      if (tooltip)
      {
         tooltip = record.value.tooltip.replaceAll ("'", "\\'");
      }
      var formulator_notes = record.value.formulator_notes;
      if (formulator_notes)
      {
         formulator_notes = record.value.formulator_notes.replaceAll ("'", "\\'");
      }

      var json = {name: record.value.name,
                  tooltip: tooltip,
                  description: desc,
                  formulator_notes: formulator_notes,
                  formulation: record.value.formulation,
                  minimum_order: record.value.minimum_order,
                  product_type_id: record.value.product_type_id,
                  is_available: record.value.is_available_bool ? 1 : 0};
      var uri;

      if (record.value.action === "add")
      {
         RestPost ("/product/", profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               emit ('save', "Record Saved", "The product has been successfully added.");
               return;
            }
         });
      }
      else if (record.value.action === "edit")
      {
         uri = "/product/" + record.value.id;
         RestPut (uri, profile.token(), json, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               emit ('save', "Record Updated", "The product has been successfully updated.");
               return;
            }
         });
      }
      else
      {
         uri = "/product/" + record.value.id;
         RestDelete (uri, profile.token(), {}, function (status)
         {
            if (status.timeout)
            {
               router.push ("/logout?type=timeout");
               return;
            }
            else if (status.disabled)
            {
               router.push ("/logout?type=disabled");
               return;
            }
   
            if (status.ok)
            {
               emit ('save', "Record Deleted", "The product has been successfully deleted.");
               return;
            }
         });
      }
   }

   const getProductTypes = function ()
   {
      product_types.value = [];
      RestGet ("/product/type/all", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_types.value = response;
            for (var x = 0; x < product_types.value.length; x++)
            {
               var id = product_types.value[x].id;
               product_types.value[x].icon = require (`@/assets/icons/product/type/${id}.png`);
               product_types.value[x].tooltip = "Create a New " + product_types.value[x].name + " Product";
            }

            getProducts();
         }
      });
   }

   const getProducts = function ()
   {
      products.value = [];
      RestGet ("/product/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var json = {};
            for (var x = 0; x < response.length; x++)
            {
               var rec = response[x];

               if (! json[rec.product_type_id])
               {
                  json[rec.product_type_id] = [];
               }

               rec.is_available = rec.is_available == 1 ? true : false;
               rec.tooltip = rec.tooltip == null ? "No Description" : rec.tooltip;
               rec.tooltip = rec.is_available == true ? rec.tooltip : "Product Not Available";

console.log ("DATA: ", rec);

               var id = rec.product_type_id;
               rec.badge = require (`@/assets/icons/product/type/${id}.png`);
               json[rec.product_type_id].push (rec);
            }

            products.value = json;
         }
      });
   }
</script>

<style scoped>
   .menu-button
   {
      height: 30px;
      font-weight: bold;
      font-face: var(--font-face);
      font-size: .8em;
      margin-top: 10px;
      background: #19BF55;
      border: 1px solid #237C43 !important;
   }
   .menu-button:hover
   {
      background: #237C43 !important;
      color: #ffffff !important;
   }
   .col { width: 65px !important; }

   .product-name-button
   {
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
      padding: 2px 1px 2px 1px;
   }
   .product-name-button .icon
   {
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .product-name-button .icon img
   {
      height: 30px;
      width: 30px;
   }
   .product-name-button .text
   {
      margin-left: 10px;
      font-size: .8em;
      font-weight: bold;
   }
   .product-name-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }

   .product-type-button
   {
      width: 60px;
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin: 8px 10px 0px 10px;
   }
   .product-type-button .icon
   {
      height: 100%;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .product-type-button .icon img
   {
      height: 30px;
      width: 30px;
   }
   .product-type-button .text
   {
      margin-left: 10px;
      font-size: 1em;
      font-weight: bold;
   }

   .product-type-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }

   .product-button
   {
      width: 120px;
      height: 40px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      justify-content: flex-start !important;
      align-items: center;
      margin: 8px 10px 0px 10px;
      padding: 1px 2px 1px 2px;
   }
   .product-button:hover
   {
      background: #625DB2;
      cursor: pointer;
   }
   .product-button-text
   {
      margin-left: 5px;
      font-size: .7em;
      font-weight: bold;
      text-shadow: 2px 2px 3px #111111;
   }
   .product-button-badge
   {
      width: 30px;
      border-radius: 5px;
   }
   .product-button-badge img
   {
      margin-left: 5px;
      height: 20px;
      width: 20px;
      filter: drop-shadow(3px 3px 4px #111111);
   }
   .large { width: 150px !important; }

   .image-preview
   {
      height: 50px;
      width: 50px;
      border-radius: 5px;
   }

   .notavailable { opacity: .5; }
   .noDescription { opacity: .8; color: #05C5EA !important; }

</style>
