<template>
   <div class="page">
      <LeftNavigation />

      <div class="content content-top">
LOAD PAGE :: PRE-FORMULATED, CRAFT ETC...

         <ShrinkButton />

<!--
         <IconSelector v-on:change="colorCB" next_button />
         <br /><br />
-->

         <FragranceSelector v-on:change="colorCB" next_button />
         <br /><br />

         <GoalSelector v-on:change="colorCB" next_button />
         <br /><br />

         <ColorSelector v-on:change="colorCB" next_button />
         <br /><br />

         <VeganSelector v-on:change="veganCB" next_button />
         <br /><br />

         <SkinSelector v-on:change="exCB" next_button />
         <br /><br />

         <ExfoliantSelector v-on:change="exCB" next_button />
         <br /><br />
      </div>
   </div>
</template>

<script setup>
   import { onMounted } from "vue";
   import LeftNavigation from './LeftNavigation.vue'
   import GoalSelector from './Lib/Selectors/GoalSelector.vue'
   import ColorSelector from './Lib/Selectors/ColorSelector.vue'
   import VeganSelector from './Lib/Selectors/VeganSelector.vue'
   import SkinSelector from './Lib/Selectors/SkinSelector.vue'
   import ShrinkButton from './Lib/Selectors/ShrinkButton.vue'
   //import IconSelector from './Lib/Selectors/IconSelector.vue'
   import ExfoliantSelector from './Lib/Selectors/ExfoliantSelector.vue'
   import FragranceSelector from './Lib/Selectors/FragranceSelector.vue'
   import { useRoute } from 'vue-router'

   const route = useRoute();

   onMounted (() =>
   {
      //
      // This is a hacky way to force a page refresh.
      //
      if (route.query.fresh)
      {
         window.location.replace ("/home");
      }

   });

   const colorCB = function (obj) { console.log ("COLOR CB: ", obj); }
   const veganCB = function (obj) { console.log ("VEGAN CB: ", obj); }
   const exCB = function (obj) { console.log ("EX CB: ", obj); }
</script>

<style>

</style>
