<template>
   <div id="selector-container" class="selector-container" data-layer="1">
      <div id="category-selector" class="category-selector">
         <div id="header" class="header" v-on:click="expandCategoryMenu ('category-selector', 1)">
            <div class="icon-container"><div class="icon"><img :src="category_icon"></div></div>
            <div class="title">Fragrance Selector</div>
         </div>

         <div id="description" class="description">
            Below is a list of standard goals for people's skin care regime. Let us know which goals you have in mind. This will help recommend more suitable products for you.
         </div>

         <div id="menu" class="menu">
            <div class="menu-item" v-for="(menu, idx) in menu_item_list" :key="idx" v-tooltip.left="menu.tooltip" v-on:click="shrinkCategoryMenu ('category-selector', 1, idx)">
               <div class="selected-button"><i v-if="selectedButton[idx]" class="fa-solid fa-circle-check"></i></div>
               <div class="icon"> <img :id="'img_'+idx" :src="menu.icon"> </div>
               <div class="name">{{menu.name}}</div>
            </div>
         </div>
      </div>

      <div id="item-selector" class="category-selector layer-two" data-layer="2">
         <div id="header" class="header" v-on:click="expandCategoryMenu ('item-selector', 2)">
            <div class="icon-container"><div class="icon"><img :src="category_icon"></div></div>
            <div class="title">Fragrance Selector</div>
         </div>

         <div id="description" class="description">
            Below is a list of standard goals for people's skin care regime. Let us know which goals you have in mind. This will help recommend more suitable products for you.
         </div>

         <div id="menu" class="menu">
            <div class="menu-item" v-for="(menu, idx) in menu_item_list" :key="idx" v-tooltip.left="menu.tooltip" v-on:click="shrinkCategoryMenu ('item-selector', 2, idx)">
               <div class="icon"><img :id="'img_'+idx" :src="menu.icon"></div>
               <div class="name">{{menu.name}}</div>
            </div>
         </div>
      </div>

      <div id="ingredient-selector" class="category-selector layer-three" data-layer="3">
         <div id="header" class="header" v-on:click="expandCategoryMenu ('ingredient-selector', 3)">
            <div class="icon-container"><div class="icon"><img :src="category_icon"></div></div>
            <div class="title">Fragrance Selector</div>
         </div>

         <div id="description" class="description">
            Below is a list of standard goals for people's skin care regime. Let us know which goals you have in mind. This will help recommend more suitable products for you.
         </div>

         <div id="menu" class="menu">
            <div class="menu-item" v-for="(menu, idx) in menu_item_list" :key="idx" v-tooltip.left="menu.tooltip" v-on:click="shrinkCategoryMenu ('ingredient-selector', 3, idx)">
               <div class="icon"><img :id="'img_'+idx" :src="menu.icon"></div>
               <div class="name">{{menu.name}}</div>
            </div>
         </div>
      </div>

      <div id="last-selector" class="category-selector layer-four" data-layer="4">
         <div id="header" class="header" v-on:click="expandCategoryMenu ('last-selector', 4)">
            <div class="icon-container"><div class="icon"><img :src="category_icon"></div></div>
            <div class="title">Fragrance Selector</div>
         </div>

         <div id="description" class="description">
            Below is a list of standard goals for people's skin care regime. Let us know which goals you have in mind. This will help recommend more suitable products for you.
         </div>

         <div id="menu" class="menu">
            <div class="menu-item" v-for="(menu, idx) in menu_item_list" :key="idx" v-tooltip.left="menu.tooltip" v-on:click="shrinkCategoryMenu ('last-selector', 4, idx)">
               <div class="icon"><img :id="'img_'+idx" :src="menu.icon"></div>
               <div class="name">{{menu.name}}</div>
            </div>
         </div>
      </div>
   </div>
</template>

// https://dribbble.com/shots/3058022--10-Vertical-Distribution
<script setup>
   import { ref, onMounted } from 'vue';

   const category_icon = require (`@/assets/icons/misc/nose.png`);
   var selectedButton = ref ([]);
   var menu_item_list = ref ([]);
   const menu_width = 70;

   onMounted (() =>
   {
      menu_item_list.value = [{name: "Item 1", tooltip: "Tooltip 1", icon: require (`@/assets/icons/misc/pie.png`)},
                              {name: "Item 2", tooltip: "Tooltip 1", icon: require (`@/assets/icons/misc/pig.png`)},
                              {name: "Item 3", tooltip: "Tooltip 1", icon: require (`@/assets/icons/misc/pineapple.png`)},
                              {name: "Item 5", tooltip: "Tooltip 1", icon: require (`@/assets/icons/misc/turtle.png`)}];

      init();
   });

   const init = function ()
   {
      for (var x = 0; x < menu_item_list.value.length; x++)
      {
         selectedButton.value[x] = false;
      }
   }

   const expandCategoryMenu = function(id, layer)
   {
      var category_el = document.getElementById (id);
      var header_el = category_el.querySelector ("#header");
      var title_el = header_el.querySelector (".title");
      var icon_el = header_el.querySelector (".icon");
      var description_el = category_el.querySelector ("#description");

      var menu_el = category_el.querySelector ("#menu");

      category_el.style.width = "175px";

      title_el.style.width = "100%";
      title_el.style.opacity ="1";

      icon_el.style.height = "25px";

      description_el.style.opacity = "1";
      description_el.style.height = "auto";
      description_el.style.width = "100%";
      description_el.style.height = "auto";

      menu_el.style.width = "100%";

      var icons = menu_el.querySelectorAll (".icon");
      var image = {};
      icons.forEach (function (icon) 
      {
         icon.style.width = "100%";
         image = icon.querySelector ("img");
         image.style.height = "auto";
         image.style.width = "50px";
      });

      var names = menu_el.querySelectorAll (".name");
      names.forEach (function (name) 
      {
         name.style.width = "100%";
         name.style.opacity = "1";
      });

      //
      // Shift the menu below back to orginal position.
      //
      if (layer)
      {
         var elements = document.querySelectorAll('[data-layer]');

         elements.forEach (function (menu)
         {
            if (menu.dataset.layer > layer)
            {
               var left = (5 * menu.dataset.layer) + (menu_width * (layer-1));
               menu.style.left = left + "px";
            }
         });
      }
   }

   const shrinkCategoryMenu = function (id, layer, idx)
   {
      var category_el = document.getElementById (id);
      var header_el = category_el.querySelector ("#header");
      var description_el = category_el.querySelector ("#description");
      var menu_el = category_el.querySelector ("#menu");
      var title_el = header_el.querySelector (".title");
      var icon_el = header_el.querySelector (".icon");

console.log ("IDX: (leaving this to add the checkmark)", idx);

      selectedButton.value[idx] = !selectedButton.value[idx];
      category_el.style.width = "70px";

      title_el.style.width = "0px";
      title_el.style.opacity ="0";

      icon_el.style.height = "40px";

      menu_el.style.width = "70px";

      description_el.style.opacity = "0";
      description_el.style.height = "0px";
      description_el.style.width = "0px";
      description_el.style.height = "0px";

      var icons = menu_el.querySelectorAll (".icon");
      var image = {};
      icons.forEach (function (icon) 
      {
         icon.style.width = "100%";
         image = icon.querySelector ("img");
         image.style.height = "auto";
         image.style.width = "35px";
         image.style.margin = "auto";
      });

      var names = menu_el.querySelectorAll (".name");
      names.forEach (function (name) 
      {
         name.style.width = "0px";
         name.style.opacity = "0";
      });

      //
      // Shift the menus below this menu, out.
      //
      if (layer)
      {
         var elements = document.querySelectorAll('[data-layer]');

         elements.forEach (function (menu)
         {
            if (menu.dataset.layer > layer)
            {
               var left = (menu_width * layer) + (5 * (menu.dataset.layer-1));
               menu.style.left = left + "px";
            }
         });
      }
   }
</script>

<style scoped>

@keyframes fadeIn
{
  0% { opacity:0; }
  100% { opacity:1; }
}

.selector-container
{
   position: relative;
   min-width: 175px;
   width: max-content;
}

.vertical-selector
{
   border-radius: 5px;
   width: 200px;
   border: 1px solid var(--input-label-border-color);
   box-shadow: 3px 3px 5px rgba(0,0,0,1);
   overflow: hidden;
   transition: all 0.5s ease;
}

.category-selector
{
   position: relative;
   border-radius: 5px;
   background: var(--app-background-color);
   border: 1px solid var(--input-label-border-color);
   box-shadow: 3px 3px 5px rgba(0,0,0,1);
   overflow: hidden;
   top: 0px;
   left: 0px;
   width: 175px;
   transition: 0.8s;
   z-index: 10;
}

.layer-two
{
   position: absolute;
   top: 0px;
   left: 5px;
   width: 175px;
   transition: 0.8s;
   z-index: 7;
}

.layer-three
{
   position: absolute;
   top: 0px;
   left: 10px;
   width: 175px;
   transition: 0.8s;
   z-index: 5;
}

.layer-four
{
   position: absolute;
   top: 0px;
   left: 15px;
   width: 175px;
   transition: 0.8s;
   z-index: 3;
}

.category-selector .header:hover { cursor: pointer; }
.category-selector .header
{
   display: flex;
   align-content: flex-start;
   background: var(--card-background-color);
   border: 1px solid var(--card-border-color);
   overflow: hidden;
}

.category-selector .header .icon img
{
   position: relative;
   top: 0px;
   left: 0px;
   height: 30px;
   width: auto;
   padding: 3px;
   transition: all 0.5s ease;
   filter: drop-shadow(5px 5px 5px #000000);
}

.category-selector .header .title
{
   display: flex;
   align-items: center;
   margin-left: 15px;
   font-variant: small-caps;
   font-size: .7em;
   font-weight: bold;
}

.category-selector .description
{
   font-size: .7em;
   padding-bottom: 5px;
   border-bottom: 1px solid var(--menu-item-hover-color);
}

/*********************** 
   CATEGORY SELECTOR ITEMS
 ***********************/

.menu
{
   display: flex;
   justify-content: center;
   flex-direction: column;
   padding: 0px;
}

.menu-item:hover { background: var(--menu-item-hover-color); cursor: pointer; }
.menu-item
{
position: relative;
   display: flex;
   flex-direction: row;
   height: 60px;
   width: 100%;
   justify-content: flex-start;
   margin-bottom: 3px;
}

.menu-item .name
{
   display: flex;
   align-items: center;
   flex-grow: 1;
   padding: 3px;
   font-weight: bold;
   font-variant: small-caps;
   font-size: .8em;
   transition: all 0.5s ease;
}

.menu-item .icon
{
   display: flex;
   align-items: center;
   height: 100%;
   width: 50%;
   justify-content: center;
}

.menu-item .icon img
{
   height: 50px;
   filter: drop-shadow(5px 5px 5px #000000);
   transition: 0.8s;
}

.selected-button
{
   display: flex;
   align-items: center;
   border-radius: 7px;
   height: 100%;
   width: 25px;
}

.selected-button i
{
   color: green;
   background: white;
   font-size: 16px;
   border-radius: 8px;
}
</style>
