<template>
  <ToastTip />

   <div class="page">
      <LeftNavigation inactive />

      <div class="content content-top">
        <div class="card card-full">
           <div class="card-close" v-on:click="close"></div>
           <h1>Account Settings</h1>

           <TabView ref="tabview" v-model:activeIndex="active" @tab-change="tabCallback">
              <TabPanel header="Contact Details">
                 <p>Use this form to update your personal contact details. We have pre-filled the form based on the information we currently have.</p>
                 <Divider />

                 <div class="container container-column">
                    <div class="container container-left">
                       <div class="input-label required" v-tooltip.top="'Required Field'">Name</div>
                       <InputText id="firstname" type="text" size="20" v-model="person.first_name" placeholder="First Name"/>
                       <InputText id="lastname" type="text" size="40" v-model="person.last_name" placeholder="Last Name" />
                    </div>

                    <NickName v-if="render" :default="person.nickname" v-model="person.nickname" :id="person.id" respect />
      
                    <div class="container container-left">
                       <div class="input-label required" v-tooltip.top="'Required Field'">Email</div>
                       <InputText id="email" type="text" size="30" v-model="person.email" placeholder="Email" />
                    </div>

                    <div class="container container-left">
                       <div class="input-label">Phone</div>
                       <InputMask id="phone" type="text" size="30" mask="(999) 999-9999" v-model="person.phone" placeholder="Phone" />
                    </div>

                    <Divider />

                    <div class="container container-center">
                       <Button @click="updateContactDetails" :disabled="contactDisabled" class="button-text">
                          Update Contact Details <i class="fa-solid fa-save button-icon"></i>
                       </Button>
                    </div>
                 </div>
              </TabPanel>

              <TabPanel header="Address Details">
                 <p>Use this form to update your mailing address. We have pre-filled the form based on the information we currently have.</p>
                 <Divider />
                 <div class="container container-column">

                    <div class="container container-left">
                       <div class="input-label">Address</div>
                       <InputText id="address" type="text" size="50" v-model="person.address" placeholder="Address" />
                    </div>

                    <div class="container container-left">
                       <div class="input-label required" v-tooltip.top="'Required Field'">City</div>
                       <InputText id="city" type="text" size="50" v-model="person.city" placeholder="City" />
                    </div>

                    <div class="container container-left">
                       <div class="input-label required" v-tooltip.top="'Required Field'">Province/State</div>
                       <InputText id="region" type="text" size="50" v-model="person.region" placeholder="Region" />
                    </div>

                    <div class="container container-left">
                       <div class="input-label required" v-tooltip.top="'Required Field'">Country</div>
                       <InputText id="country" type="text" size="50" v-model="person.country" placeholder="Country" />
                    </div>

                    <div class="container container-left">
                       <div class="input-label">Postal Code</div>
                       <InputText id="postal_code" type="text" size="10" v-model="person.postal_code" placeholder="Postal Code" />
                    </div>

                    <Divider />
                    <div class="container container-center">
                       <Button @click="updateContactAddress" :disabled="addressDisabled" class="button-text">
                          Update Address Details<i class="fa-solid fa-save button-icon"></i>
                       </Button>
                    </div>
                 </div>
              </TabPanel>

              <TabPanel header="Customer Profile Survey">
                 <p>Update your personal interests. We have pre-filled the form based on the information we currently have.</p>
                 <Divider />
                 <div class="container container-column-wrap">
                    <div v-for="(product, idx) in product_types" :key="idx" class="container container-row container-box-30 container-left container-align-center">
                       <Checkbox :inputId="'ID' + idx" :name="'product_'+idx" :value="product.id" v-model="user_interests" />
                       <label :for="'ID' + idx" class="product_name" v-tooltip.top="product.tooltip">{{product.name}}</label>
                    </div>
                 </div>

                 <div class="container container-center">
                    <Button @click="saveCustomerInterests" class="button-text">
                       Update Personal Interests<i class="fa-solid fa-save button-icon"></i>
                    </Button>
                 </div>
              </TabPanel>

              <TabPanel header="Account Avatar">
                 <UserAvatar v-if="person.avatar"  :image="person.avatar" url="/file/avatar/" v-on:load="uploadComplete" />
              </TabPanel>

              <TabPanel header="Tour">
 Give the that tour.
              </TabPanel>
           </TabView>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted, computed } from 'vue';
   import { useRouter } from 'vue-router';
   import { RestGet, RestPost, RestPut } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';
   import { loginStatusStore } from '@/stores/login.js'

   import LeftNavigation from './LeftNavigation.vue'
   import UserAvatar from './Lib/UserAvatar.vue'
   import NickName from './Lib/NickName'

   import InputMask from 'primevue/inputmask';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import TabView from 'primevue/tabview';
   import TabPanel from 'primevue/tabpanel';
   import Divider from 'primevue/divider';
   import Checkbox from 'primevue/checkbox';
   import { useToast } from "primevue/usetoast";

   const profile = userProfileStore();
   const router = useRouter();
   const toast = useToast();
   const product_types = ref ([]);
   let person = ref ({first_name: '', last_name: '', email: '', phone: '', nickname: '',
                      address: '', city: '', region: '', country: '', postal_code: ''});
   const active = ref (0);
   const user_interests = ref ([]);
   const render = ref (false);

   //
   // Disables the navigation menus/buttons
   //
   const contactDisabled = computed(() => !person.value.first_name || !person.value.last_name || !person.value.email || !person.value.phone || !person.value.nickname);
   const addressDisabled = computed(() => !person.value.address || !person.value.city || !person.value.region || !person.value.country || !person.value.postal_code);

   onMounted (() =>
   {
      var email = profile.get ('email');
      getContactDetails (email);
   })

   const close = function()
   {
      router.push ("/home?fresh=1");
      return;
   }

   //
   // This is called when the user clicks on the tab.
   //
   const tabCallback = function ()
   {
      if (active.value == 0 || active.value == 1)
      {
         getContactDetails (person.value.email);
      }
      else if (active.value == 2)
      {
         getContactPersonalInterests (person.value.id);
      }
   }

   //
   // This is called when the avatar image is uploaded.
   //
   const uploadComplete = function (response)
   {
      var url = "/assets/img/avatar/" + response.file;
      profile.add ("userAvatar", url);
      loginStatusStore.refresh = true;
   }

   const getContactDetails = function (email)
   {
      var uri = "/customer/email/" + encodeURIComponent(email);

      person.value = {};
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var profile = response[0];

            person.value.id = profile.id;
            person.value.uid = profile.uid;
            person.value.first_name = profile.first_name;
            person.value.last_name = profile.last_name;
            person.value.email = profile.email;
            person.value.phone = profile.phone;
            person.value.nickname = profile.nickname;
            person.value.is_admin = profile.is_admin;
            person.value.avatar = profile.avatar;

            if (! person.value.avatar)
            {
               person.value.avatar = require (`@/assets/img/avatar/undefined.png`);
            }

            person.value.address_id = profile.address_id;
            person.value.address = profile.address;
            person.value.city = profile.city;
            person.value.region = profile.region;
            person.value.country = profile.country;
            person.value.postal_code = profile.postal_code;

            render.value = true;
console.log ("PERSON: ", person.value);
         }
      });
   }

   const updateContactDetails = function ()
   {
      var json = {uid: person.value.uid,
                  first_name: person.value.first_name,
                  last_name: person.value.last_name,
                  nickname: person.value.nickname,
                  email: person.value.email,
                  phone: person.value.phone};

      var uri = "/customer/" + person.value.id;
      RestPut (uri, profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var mesg = "Your personal details have been updated.";
            toast.add ({severity: 'success', summary: "Account Updated", detail: mesg, life: 3000});
         }
      });
   }

   const updateContactAddress = function ()
   {
      var id = profile.get ("id");
      var json = {customer_id: id,
                  address: person.value.address,
                  city: person.value.city,
                  region: person.value.region,
                  country: person.value.country,
                  postal_code: person.value.postal_code};

      var uri = "/address/" + person.value.address_id;
      RestPut (uri, profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var mesg = "Your personal address details have been updated.";
            toast.add ({severity: 'success', summary: "Account Address Details Updated", detail: mesg, life: 3000});
         }
      });
   }

   const getContactPersonalInterests = function (id)
   {
      user_interests.value = [];

      var uri = "/customer/interests/customer/" + id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            var arr = [];

            for (let i = 0; i < response.length; i++)
            {
               arr.push (response[i].product_id);
            }

            user_interests.value = arr;
         }

         getProductTypes();
      });
   }

   const getProductTypes = function ()
   {
      product_types.value = [];

      RestGet ("/product/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product_types.value = response;
         }
      });
   }

   const saveCustomerInterests = function ()
   {
      var json = {customer_id: person.value.id, product_id_list: user_interests.value};

      RestPost ("/customer/interests/", profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
              var mesg = "Your personal interests have been updated.";
              toast.add ({severity: 'success', summary: "Account Interests Updated", detail: mesg, life: 3000});
              return;
         }
      });
   }
</script>

<style scoped>

.required {color: red; }

.p-tabview
{
   width: 100%;
}

p { font-size: 1.3em; }

.avatar-title
{
   font-size: 1.1em;
   font-weight: bold;
   font-variant: small-caps;
   text-shadow: 2px 2px 3px rgba(0,0,0,.5);
   margin-bottom: 10px;
}
.avatar-image
{
   display: flex;
   justify-content: center;
   padding: 12px;
   height: 90px;
   width: 90px;
   border-radius: 45px;
   border: 2px solid var(--text-color);
}
.avatar-image img
{
   height: 60px;
   width: 60px;
}
.avatar-upload-button
{
   background: #A5B4FC;
   text-align: center;
   line-height: 50px;
   height: 50px;
   width: 120px;
   font-weight: bold;
   color: #000000;
   font-size: 1em;
   margin: auto;
   border-radius: 15px;
   border: 1px solid #828EC9;
   white-space: pre-line;
}
.avatar-upload-button:hover
{
   background: #828EC9 !important;
   cursor: pointer;
}

.button-text
{
   font-weight: bold;
   font-size: 1em;
}

.button-icon
{
   font-size: 1.8em;
   padding-left: 10px;
}
 
.nickname_checkmark
{
   height: 30px;
   margin-top: 5px;
   margin-left: 10px;
   text-shadow: 2px 2px 3px rgba(0,0,0,.5);
}

.product_name
{
   padding-left: 20px;
   font-size: 1.2em;
   font-weight: bold;
   font-variant: small-caps;
}

</style>
