import {createRouter, createWebHistory} from 'vue-router'
import { userSessionStore } from '@/stores/session';

//
// Admin page
//
import Admin from '../components/Admin/AdminPage.vue';
import PackagingTypes from '../components/Admin/PackagingTypes.vue';
import InventoryManager from '../components/Admin/InventoryPage.vue';
import OrderManager from '../components/Admin/OrderManager.vue';

import ProductPage from '../components/Admin/ProductPage.vue';
import ProductTypes from '../components/Admin/Product/TypePage.vue';
import ProductSizes from '../components/Admin/Product/SizePage.vue';
import ProductWorkflowPage from '../components/Admin/Product/WorkflowPage.vue';
import ProductWorkflowEditor from '../components/Admin/Product/WorkflowEditor.vue';
import ProductFormulas from '../components/Admin/Product/FormulaPage.vue';
import ProductFormulaEditor from '../components/Admin/Product/FormulaEditor.vue';

import IngredientPage from '../components/Admin/IngredientPage.vue';
import IngredientInventoryPage from '../components/Admin/Ingredient/InventoryPage.vue';
import IngredientLandingPage from '../components/Admin/Ingredient/LandingPage.vue';
import IngredientTypes from '../components/Admin/Ingredient/TypePage.vue';
import IngredientMapping from '../components/Admin/Ingredient/MappingPage.vue';
import IngredientInventory from '../components/Admin/Ingredient/InventoryPage.vue';
import IngredientAttributes from '../components/Admin/Ingredient/AttributePage.vue';

import ContactUs from '../components/ContactUs.vue';
import StorePage from '../components/StorePage.vue';
//import ProductPage from '../components/ProductPage.vue';
import CreateRecipe from '../components/CreateRecipe.vue';
import LandingPage from '../components/LandingPage.vue';
import UserHome from '../components/UserHome.vue';
import Login from '../components/LoginUser.vue';
import Logout from '../components/LogoutUser.vue';
import Register from '../components/RegisterUser.vue';
import Dashboard from '../components/UserDashboard.vue';
import Settings from '../components/UserSettings.vue';
import Onboard from '../components/UserOnboard.vue';
import Cart from '../components/UserCart.vue';
import Orders from '../components/UserOrders.vue';
import Recipes from '../components/UserRecipes.vue';
import ProfileSurvey from '../components/ProfileSurvey.vue';
import DYOSTour from '../components/DYOSTour.vue';

const routes = [{path: '/', component: LandingPage},
                {path: '/login', component: Login},
                {path: '/logout', component: Logout},
                {path: '/register', component: Register},
                {path: '/home', component: UserHome},
                {path: '/admin', component: Admin},
                {path: '/dashboard', component: Dashboard},
                {path: '/settings', component: Settings},
                {path: '/cart', component: Cart},
                {path: '/orders', component: Orders},
                {path: '/recipes', component: Recipes},
                {path: '/onboard', component: Onboard},
                {path: '/tour', component: DYOSTour},
                {path: '/survey/profile', component: ProfileSurvey},
                {path: '/contact', component: ContactUs},
                {path: '/store/:id:', component: StorePage},
                {path: '/product/:id:', component: ProductPage},
                {path: '/recipe/:id:', component: CreateRecipe},
                {path: '/admin/products', component: ProductPage},
                {path: '/admin/product/types', component: ProductTypes},
                {path: '/admin/product/sizes', component: ProductSizes},
                {path: '/admin/product/formulas', component: ProductFormulas},
                {path: '/admin/product/formulas/:id:', component: ProductFormulaEditor},
                {path: '/admin/product/workflows', component: ProductWorkflowPage},
                {path: '/admin/product/workflow/:id:', component: ProductWorkflowEditor},
                {path: '/admin/orders', component: OrderManager},
                {path: '/admin/inventory', component: InventoryManager},
                {path: '/admin/packaging/types', component: PackagingTypes},
                {path: '/admin/ingredient/types', component: IngredientTypes},
                {path: '/admin/ingredients', component: IngredientLandingPage},
                {path: '/admin/ingredient/:id:', component: IngredientPage},
                {path: '/admin/ingredient/inventory', component: IngredientInventory},
                {path: '/admin/ingredient/inventory/:id:', component: IngredientInventoryPage},
                {path: '/admin/ingredient/attributes', component: IngredientAttributes},
                {path: '/admin/ingredient/mapping', component: IngredientMapping},
               ];

const router = createRouter ({history: createWebHistory(), routes})

//
// If the user is NOT an admin and they try to force their way in, we will swat them back.
//
router.beforeEach((to) =>
{
   var profile = JSON.parse (window.localStorage.getItem ("userProfile"));

   if (! profile)
   {
      //
      // We need to allow people to navigate to the following links regardless of 
      // registration or login status.
      //
      if (to.path == "/" || to.path == "/login" || to.path == "/register" || to.path == "/store" || to.path == "/contact")
      {
          return true;
      }

      return false;
   }

   //
   // If they are an admin, nothing else to do.
   //
   if (profile.is_admin)
   {
      return true;
   }

   //
   // Get the index of '/admin' in the link we are heading to.
   // If the path does start with '/admin' the result will be zero.
   // If it does not, the result will be -1.
   //
   var idx = to.path.indexOf ("/admin");
   if (idx == -1)
   {
      return true;
   }

   //
   // If we are here it means we are not an admin and we are trying to go to /admin
   //
   return false
})

//
// This is a global router hook. We set use this to ensure we are 
// managing idle sessions correctly.
//
router.beforeResolve(async to => 
{
   window.localStorage.setItem ("lastURI", to);

   const session = userSessionStore();
   session.set ();
})

export default router
