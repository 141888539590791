-<template>
   <ToastTip />

   <Dialog position="top" :header="header" v-model:visible="displayRecipeEditor" :style="{width: '75vw'}">
      <DataTable ref="table" :value="ingredients" responsiveLayout="scroll" dataKey="id">
         <Column field="name" header="Name" sortable style="min-width: 14rem">
            <template #body="{data}">{{data.name}}</template>
         </Column>

         <Column field="description" header="Description">
            <template #body="{data}">{{data.description}}</template>
         </Column>

         <Column field="actions" header="Required">
            <template #body="{index}">
               <div class="container container-left">
               <Checkbox name="required" value="1" v-model="required_ingredients[index]" v-on:change="checkRequiredIngredient (index, 'required')" binary />
               </div>
            </template>
         </Column>

         <Column field="actions" header="Optional">
            <template #body="{index}">
               <div class="container container-left">
               <Checkbox name="optional" value="1" v-model="optional_ingredients[index]" v-on:change="checkRequiredIngredient (index, 'selected')" binary />
               </div>
            </template>
         </Column>

         <template #footer>
            <div class="container container-right">
               <Button label="Close" icon="pi pi-times" class="p-button-text" v-on:click="displayRecipeEditor = false"/>
               <Button label="Save" icon="pi pi-check" autofocus v-on:click="saveWorkflowRecipe"/>
            </div>
         </template>
      </DataTable>
   </Dialog>

   <div class="page">
      <AdminNavigation />

      <div class="content">
         <div class="title" v-if="product">Define {{product.name}} Workflow and Recipe</div>

         <br />
         <Button label="Close" icon="pi pi-times" @click="save (false)" class="p-button-text"/>
         <Button label="Save" icon="pi pi-save" class="p-button-text" v-on:click="save (true)" :disabled="percent != 100" />

         <div class="container container-column">
            <div class="workflow-container">
               <div class="header" style="width: 20vw;" v-tooltip.top="'Ingredient Type'">Ingredient Type</div>
               <div class="header" style="width: 7vw;" v-tooltip.top="'Percent of Recipe'">Percent</div>
               <div class="header" style="width: 11vw;" v-tooltip.top="'Minimum Selection for Customer'">Minimum</div>
               <div class="header" style="width: 11vw;" v-tooltip.top="'Maximum Selection for Customer'">Maximum</div>
               <div class="header" style="width: 6vw;" v-tooltip.top="'Included In Recipe Workflow'">Included</div>
               <div class="header" style="width: 6vw;">Actions</div>
            </div>

            <div v-for="(record, idx) in workflow_items" v-bind:key="idx" class="workflow-container container-gap-5">
               <div class="text-cell" style="width: 20vw;">{{record.name}}</div>

               <InputText type="number" min="0" max="100" style="width: 5vw;" v-model="record.percent" v-on:change="calcPercent" />

               <Dropdown v-model="record.minimum" :options="min_selections" optionLabel="name" optionValue="value" placeholder="Minimum Ingredients" style="width: 11vw;" />

               <Dropdown v-model="record.maximum" :options="max_selections" optionLabel="name" optionValue="value" placeholder="Maximum Ingredients" style="width: 11vw;" />

               <ToggleButton v-model="record.state" onLabel="Yes" offLabel="No" onIcon="pi pi-check" offIcon="pi pi-times" style="width: 6vw;" />

               <Button class="p-button-rounded action-icon" @click="openRecipeDialog (record, 'base')" v-tooltip.top="'Edit Recipe'" :disabled="!record.state"><i class="fa-solid fa-cog"></i></Button>
            </div>

            <div class="workflow-container">
               <div class="header" style="width: 20vw;">Total</div>
               <div class="header" style="width: 8vw;" :class="{red: percent > 100, green: percent == 100}">&nbsp;{{percent}}&nbsp;%</div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { useRoute, useRouter } from 'vue-router'
   import { RestGet, RestPost } from '@/plugins/webapi';
   import { userProfileStore } from '@/stores/profile';

   import AdminNavigation from '../AdminNavigation.vue'
   import ToggleButton from 'primevue/togglebutton';
   import Dialog from 'primevue/dialog';
   import DataTable from 'primevue/datatable';
   import Column from 'primevue/column';
   import Dropdown from 'primevue/dropdown';
   import InputText from 'primevue/inputtext';
   import Button from 'primevue/button';
   import Checkbox from 'primevue/checkbox';

   const required_ingredients = ref ([]);
   const optional_ingredients = ref ([]);
   const recipe = ref([]);
   const profile = userProfileStore();
   const router = useRouter();
   const route = useRoute();
   const product_id = route.params.id;
   const ingredient_type_id = ref();
   const workflow_stages = ref ();
   const workflow_items = ref ([]);
   const ingredients = ref([]);
   const product = ref ();
   const table = ref ();
   const percent = ref (0);
   const displayRecipeEditor = ref (false);
   const header = ref ("");
   const userRecipe = ref (false);

   const min_selections = ref ([{name: 'No Minimum', value: 0},
                                {name: '1 Ingredient', value: 1},
                                {name: '2 Ingredients', value: 2},
                                {name: '3 Ingredients', value: 3},
                                {name: '4 Ingredients', value: 4}]);
   const max_selections = ref ([{name: '1 Ingredient', value: 1},
                                {name: '2 Ingredients', value: 2},
                                {name: '3 Ingredients', value: 3},
                                {name: '4 Ingredients', value: 4},
                                {name: '5 Ingredients', value: 5}]);

   onMounted(() =>
   {
      getProductDetails();
   })

   const checkRequiredIngredient = function (idx, checkbox)
   {
      if (checkbox == 'required')
      {
         if (required_ingredients.value[idx] && optional_ingredients.value[idx])
         {
            optional_ingredients.value[idx] = false;
         }
      }
      else
      {
         if (required_ingredients.value[idx] && optional_ingredients.value[idx])
         {
            required_ingredients.value[idx] = false;
         }
      }
   }

   const openRecipeDialog = function (record, type)
   {
      header.value = "Edit Customer Default Ingredients :: " + record.name;
      userRecipe.value = true;

      if (type == 'base')
      {
         header.value = "Edit Base Ingredients :: " + record.name;
         userRecipe.value = false;
      }

      displayRecipeEditor.value = true;

      ingredient_type_id.value = record.id;
      getIngredients (record.id);
   }

   const calcPercent = function()
   {
      percent.value = 0;

      for (var idx in workflow_items.value)
      {
         var rec = workflow_items.value[idx];
         percent.value += parseInt (rec.percent);
      }
   }

   const saveWorkflowRecipe = function ()
   {
      displayRecipeEditor.value = false;

      var json = {ingredient_type_id: ingredient_type_id.value, 
                  product_id: product_id,
                  ingredient_list: []};

      for (var idx in ingredients.value)
      {
         if (optional_ingredients.value[idx])
         {
            json.ingredient_list.push ({ingredient_id: ingredients.value[idx].id});
         }
         else if (required_ingredients.value[idx])
         {
            json.ingredient_list.push ({ingredient_id: ingredients.value[idx].id, is_required: 1});
         }
      }
      
      RestPost ("/product/recipe/", profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            displayRecipeEditor.value = false;
         }
      });
   }

   const save = function (saveData)
   {
      if (saveData === false)
      {
         router.push ("/admin/product/workflows");
         return;
      }

      var json = {product_id: product_id, stage_list: []};
      for (const idx in workflow_items.value)
      {
         var record = workflow_items.value[idx];

         if (record.state == true)
         {
            json.stage_list.push ({"stage": idx,
                                   "code": record.code, 
                                   "percent": record.percent,
                                   "minimum": record.minimum,
                                   "maximum": record.maximum});
         }
      }

      RestPost ("/product/workflow/", profile.token(), json, function (status)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            router.push ("/admin/product/workflows");
         }
      });
   }

   const getIngredients = function (id)
   {
      ingredients.value = [];

      var uri = "/ingredient/list/" + id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredients.value = response;

            for (var idx in ingredients.value)
            {
               optional_ingredients.value[idx] = false;
               required_ingredients.value[idx] = false;
            }

            getProductRecipe (id);
         }
      });
   }

   const getProductRecipe = function (id)
   {
      recipe.value = [];

      var uri = "/product/recipe/" + product_id + "/ingredient_type/" + id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            recipe.value = response;

            for (var idx in ingredients.value)
            {
               var rec = ingredients.value[idx];

               for (var x in recipe.value)
               {
console.log ("ING ID: ", rec.id, "  RECIPE ING ID: ", recipe.value[x].ingredient_id);
                  if (rec.id == recipe.value[x].ingredient_id)
                  {
                     optional_ingredients.value[idx] = true;
                     if (recipe.value[x].is_required)
                     {
                        required_ingredients.value[idx] = true;
                        optional_ingredients.value[idx] = false;
                     }

                     break;
                  }
               }
            }

console.log ("RECIPE: ", recipe.value);
         }
      });
   }

   const getProductDetails = function ()
   {
      product.value = [];

      var uri = "/product/" + product_id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            product.value = (response)[0];

            getWorkflowStages();
         }
      });
   }

   const getWorkflowStages = function ()
   {
      workflow_stages.value = {};
      workflow_items.value = {};

      RestGet ("/ingredient/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            workflow_stages.value = response;

            for (var x = 0; x < workflow_stages.value.length; x++)
            {
               workflow_items.value[x] = {'id': workflow_stages.value[x].id,
                                          'name': workflow_stages.value[x].name,
                                          'code': workflow_stages.value[x].code,
                                          'stage': workflow_stages.value[x].stage,
                                          'content': workflow_stages.value[x].description,
                                          'percent': workflow_stages.value[x].percent,
                                          'minimum': workflow_stages.value[x].minimum_selections,
                                          'maximum': workflow_stages.value[x].maximum_selections,
                                          'state': false};
            }

            getProductWorkflow();
         }
      });
   }

   const getProductWorkflow = function ()
   {
      var uri = "/product/workflow/product/" + product_id;
      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }
console.log ("DDDDDDDDDD: ", response);

         if (status.ok)
         {
            for (var x =0; x < response.length; x++)
            {
               var code = response[x].code;
               for (const idx in workflow_items.value)
               {
                  var record = workflow_items.value[idx];
                  if (record.code == code)
                  {
                     workflow_items.value[idx].state = true;

                     if (response[x].percent)
                     {
                        workflow_items.value[idx].percent = response[x].percent;
                     }

                     if (response[x].minimum_selections)
                     {
                        workflow_items.value[idx].minimum = response[x].minimum_selections;
                     }

                     if (response[x].maximum_selections)
                     {
                        workflow_items.value[idx].maximum = response[x].maximum_selections;
                     }
                     break;
                  }
               }
            }
         }

         calcPercent();
      });
   }
</script>

<style scoped>
   .red {color: red; }
   .green {color: #33D61B; }
   .title
   {
      font-size: 1.4em;
      font-weight: bold;
      font-variant: small-caps;
      text-shadow: 2px 2px 3px #111111;
   }

   .workflow-container
   {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border-radius: 2px;
      font-size: .8em;
      border: 1px solid var(--header-border-color);
      padding: 2px 0px 2px 5px;
      border-bottom: 1px solid var(--header-border-color);
   }

   .ingredient-column
   {
      flex-direction: column;
   }

   .header
   {
      font-size: 1em;
      font-weight: bold;
      font-variant: small-caps;
      width: 15vw;
      height: 30px;
      line-height: 30px;
      background: #484482;
   }

   .text-cell
   {
      border-right: 1px solid #484482;
      width: 15vw;
      max-height: 35px;
      line-height: 35px;
   }

   .action-icon
   {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-text: center;
      font-size: 1.2em;
      margin: 2px 2px 2px 2px;
   }
</style>
