<template>
  <ToastTip />

  <Dialog header="Login Error" v-model:visible="showErrorDialog" :style="{width: '55vw'}">
      <div class="message">
     {{errorMessage}}
      </div>

      <template #footer>
         <div v-if="redirectToLogin">
            <Button label="Let Me Login" icon="pi pi-check" v-on:click="redirectUser" autofocus />
         </div>
         <div v-else>
            <Button label="Close" icon="pi pi-check" v-on:click="showErrorDialog = false" autofocus />
         </div>
      </template>
  </Dialog>

  <div class="page">
     <div class="container container-column container-center container-full">
        <div class="card" style="margin-top: 40px">
           <h1>Create an Account Using Your Email</h1>
           <div class="container container-column">
              <div class="container container-row">
                 <label for="email" class="input-label required" v-tooltip="'Required Field'">Email Addresss</label>
                 <span class="p-input-icon-left">
                    <i class="pi pi-at" />
                    <InputText id="email" type="text" v-tooltip="'Please type in your email.'" v-model="record.email" />
                 </span>
              </div>
        
              <div class="container container-row">
                 <label for="password" class="input-label required" v-tooltip="'Required Field'">Password</label>
                 <span class="p-input-icon-left">
                    <i class="pi pi-key" />
                    <InputText id="password" type="password" v-tooltip="'Please type in your password.'" v-model="record.password" />
                 </span>
              </div>
   
              <div class="container container-row">
                 <label for="record.confirm_password" class="input-label required" v-tooltip="'Required Field'">Confirm Password</label>
                 <span class="p-input-icon-left">
                    <i class="pi pi-key" />
                    <InputText id="record.confirm_password" type="password" v-tooltip="'Please re-type in your password.'" v-model="record.confirm_password" />
                 </span>
              </div>
   
              <div class="container container-row">
                 <Button @click="register" :disabled="disabled">Submit</Button>
              </div>
           </div>
        </div>
   
        <div class="container container-center container-full">
           <div class="or">Or</div>
        </div>
   
        <div class="card">
           <h1>Create an Account Using A Social Media Account</h1>
           <div class="container container-column">
              <div class="container">
                 <Button class="google p-0" aria-label="Google" v-on:click="signInWithGoogle">
                    <i class="pi pi-google px-2"></i>
                    <span class="px-3">Google</span>
                 </Button>
                 <Button class="facebook p-0" aria-label="Facebook" v-on:click="signInWithFacebook">
                    <i class="pi pi-facebook px-2"></i>
                    <span class="px-3">Facebook</span>
                 </Button>
   
                <!--
                 <Button class="twitter p-0" aria-label="Twitter" v-on:click="signInWithTwitter">
                    <i class="pi pi-twitter px-2"></i>
                    <span class="px-3">Twitter</span>
                 </Button>
                 -->
              </div>
           </div>
        </div>
     </div>
  </div>
</template>

<script setup>
   import { ref, computed } from 'vue'
   import { getAuth, createUserWithEmailAndPassword, FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
   import { getDownloadURL, getStorage, ref as storageRef } from "firebase/storage";
   import { userProfileStore } from '@/stores/profile';
   import { loginStatusStore } from '@/stores/login.js'
   import { useRouter } from 'vue-router'
   import Button from 'primevue/button';
   import Dialog from 'primevue/dialog';
   import InputText from 'primevue/inputtext';
   import { useToast } from "primevue/usetoast";

   const storage= getStorage();
   const profile = userProfileStore();
   const errorMessage = ref('')
   const record = ref ({email: '', password: '', confirm_password: ''});
   const router = useRouter()
   const toast = useToast();
   const showErrorDialog = ref (false);
   const redirectToLogin = ref (false);

   //
   // Disables the submit button if the email or passwords have not been provided.
   //
   const disabled = computed(() => !record.value.email || !record.value.password || (record.value.password != record.value.confirm_password));

   //
   // This shows the error toast.
   //
   const showInfoToast = function (summary, message)
   {
      showToast ("info", summary, message);
   }
   const showToast = function (severity, summary, message)
   {
      toast.add ({severity: severity, summary: summary, detail:message, life: 5000});
   }

   const redirectUser = function ()
   {
      showErrorDialog.value = false;
      profile.clear();
      loginStatusStore.loggedIn = false;

      router.push ("/login");
   }

   const checkLoginStatusCode = function (error)
   {
      switch (error.code)
      {
        case 'auth/email-already-in-use':
            errorMessage.value = 'Account already exists. Try logging in.'
            redirectToLogin.value = true;
            break
        case 'auth/account-exists-with-different-credential':
            errorMessage.value = 'Account exists with different credntials'
            break
        case 'auth/invalid-email':
            errorMessage.value = 'Invalid email format.'
            break
        case 'auth/user-not-found':
            errorMessage.value = 'It appears you have not registered with us yet. Would you like to?'
            break
        case 'auth/wrong-password':
            errorMessage.value = 'Email or password was incorrect, or you used another method to register with us.'
            break
        case 'auth/user-disabled':
            errorMessage.value = 'Your account has been disabled. Please contact us.'
            break
        default:
            console.error ("Unknown error: ", error);
            errorMessage.value = 'Unknown error. Please contact us.'
            break
      }

      showErrorDialog.value = true;

      console.error (error);
   }

   const saveProfile = function (data)
   {
      profile.create();

      //
      // We have a successful account creation
      //
      profile.add ('uid', data.user.uid);
      profile.add ('token', data.user.accessToken);

      if (data.user['email'])
      {
         profile.add ('email', record.value.email);
      }

      if (data.user['phoneNumber'])
      {
         profile.add ('phone', record.value.phoneNumber);
      }

      if (data.user['displayName'])
      {
         profile.add ('displayName', record.value.displayName);
      }

      if (data.user['photoURL'])
      {
         profile.add ('avatar', data.user.photoURL);
         loginStatusStore.loggedIn = true;
         router.push('/onboard');
         return;
      }
      else
      {
         //
         // This is the default avatar logo in Firebase stroage.
         //
         var path = "/images/avatar.png";
         var avatarStorageRef = storageRef(storage, path)
         getDownloadURL (avatarStorageRef, path).then ((url) =>
         {
            profile.add ("avatar", url);

            loginStatusStore.loggedIn = true;

            router.push('/onboard');
         });
      }
   };
 
   const register = () =>
   {
      const auth = getAuth();
 
      createUserWithEmailAndPassword (auth, record.value.email, record.value.password).then(function (data)
      {
         showInfoToast ("Success", "Account created.");
 
         saveProfile (data);
      })
      .catch (function (error)
      {
         checkLoginStatusCode (error);
      });
   };

   const signInWithFacebook = function ()
   {
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
 
      signInWithPopup (auth, provider).then (function (data)
      {
         showInfoToast ("Success", "Account created.");

         saveProfile (data);
      })
      .catch (function (error)
      {
         checkLoginStatusCode (error);
      });
   };
 
   const signInWithGoogle = function ()
   {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
 
      signInWithPopup (auth, provider).then (function (data)
      {
         showInfoToast ("Success", "Account created.");

         saveProfile (data);
      })
      .catch (function (error)
      {
         checkLoginStatusCode (error);
      });
   };
</script>

<style scoped>
.required {color: red;}
.or
{
   text-align: center;
   font-size: 1.1em;
   font-weight: bold;
   font-variant: small-caps;
   margin-top: 20px;
   margin-bottom: 30px;
   display: flex;
   flex-direction: row;
   width: 50%;
}
.or:before, .or:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--header-border-color);
  margin: auto;
}
.or:before {
  margin-right: 15px
}
.or:after {
  margin-left: 15px
}

.p-button i
{
   line-height: 2.25rem;
}
.p-button.google
{
   background: linear-gradient(to left, var(--purple-600) 50%, var(--purple-700) 50%);
   background-size: 200% 100%;
   background-position: right bottom;
   transition: background-position 0.5s ease-out;
   color: #fff;
   border: 1px solid var(--purple-900);
}
.p-button.google:hover
{
   background-position: left bottom;
   background: var(--purple-700);
   box-shadow: 2px 2px 5px var(--blacke-400);
}
.p-button.google i {
    background-color: var(--purple-700);
}
.p-button.google:focus {
    box-shadow: 2px 2px 5px var(--purple-400);
}

.p-button.facebook
{
    background: linear-gradient(to left, var(--indigo-600) 50%, var(--indigo-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--indigo-800);
}
.p-button.facebook:hover
{
    background-position: left bottom;
    background: var(--indigo-700);
}
.p-button.facebook i
{
    background-color: var(--indigo-700);
}
.p-button.facebook:focus
{
    box-shadow: 0 0 0 1px var(--indigo-400);
}
.p-button.twitter
{
    background: linear-gradient(to left, var(--blue-400) 50%, var(--blue-500) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--blue-700);
}
.p-button.twitter:hover
{
    background-position: left bottom;
    background: var(--blue-500);
}
.template .p-button.twitter i
{
    background-color: var(--blue-700);
}
.p-button.twitter:focus
{
    box-shadow: 0 3 3 3px var(--blue-200);
}

</style>
