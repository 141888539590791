import { defineStore } from 'pinia'

export const recipeStore = defineStore('recipe', {
  id: 'recipe',
  state: () => ({
     storageName: "userRecipe",
     recipe: null,
  }),

  getters: 
  {
  },

  actions:
  {
     get ()
     {
        return JSON.parse (window.localStorage.getItem (this.storageName));
     },

     clear()
     {
        //this.recipe = null;
        //window.localStorage.removeItem (this.storageName);
     },

     startRecipe (product, sizes)
     {
        this.clear();

        var tmp = {};
        tmp.small = "N/A";
        tmp.regular = "N/A";
        tmp.large = "N/A";
        for (var x=0; x < sizes.length; x++)
        {
           if (sizes[x].name == "Small")
           {
              tmp.small = sizes[x];
           }
           if (sizes[x].name == "Regular")
           {
              tmp.regular = sizes[x];
           }
           if (sizes[x].name == "Large")
           {
              tmp.large = sizes[x];
           }
        }

        this.recipe = {"product": product, "sizes": tmp, ingredients: []};
        window.localStorage.setItem (this.storageName, JSON.stringify (this.recipe));
     },

     addIngredient (ingredient_type_code, ingredient_type, ingredients)
     {
        var found = false;
        this.recipe = this.get();

        //
        // Look to see if this ingredient type has already been added. If so, swap the old
        // ingredient array with the new one. This is how we will edit the recipe.
        //
        for (var idx in this.recipe.ingredients)
        {
           if (this.recipe.ingredients[idx].ingredient_type_code == ingredient_type_code)
           {
              this.recipe.ingredients[idx].ingredients = ingredients;
              found = true;
              break;
           }
        }

        //
        // If the variable found is false, it means the search above did not find the 
        // ingredient type and we will now add it. 
        //
        if (! found)
        {
           var json = {'ingredient_type_code': ingredient_type_code,
                       'ingredient_type': ingredient_type,
                       'ingredients': ingredients};
           this.recipe.ingredients.push (json);
        }

        //
        // Save te recipe.
        //
        window.localStorage.setItem (this.storageName, JSON.stringify (this.recipe));
     },

     dump (message)
     {
        this.get();

        console.log ("SESSION DUMP: ", message);
        console.log( JSON.stringify(this.recipe, null, 2) );
     },
  },
})
