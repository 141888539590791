<template>
   <div class="header">
      <div class="logo">
         <router-link to="/">
            <img class="logo-image" src="../assets/img/logo.png" v-tooltip="'Security Assessment Toolkit'" />
         </router-link>
      </div>
   </div>

   <div class="body">
XZXXXXXX
   </div>
</template>

<script setup>
   import { onMounted } from 'vue'

   onMounted(() =>
   {
console.log ("XXXXXX");
   });

</script>

//
//
//  https://css-tricks.com/snippets/css/a-guide-to-flexbox/
//
//
<style scoped>
   .header
   {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100px;
      flex-direction: row;
      background: #0b0a24;
      padding: 0px;
      border-bottom: 2px solid var(--header-border-color);
   }

   .logo
   {
      display: inline-block;
      width: 30%;
      align-items: center;
   }
   .logo img
   {
      margin: auto;
      height: 95px;
      vertical-align: middle;
   }

   .logo-image { height: 70px !important; }
   .logo-image img {height: auto;}
</style>
