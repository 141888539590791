<template>
   <div class="card card-large">
      <div class="selector-title">Color Selector</div>

      <SelectorTemplate :buttons=buttons :icon="ingredient_type_icon" :text=text v-on:change="change" v-on:save="save" :save_button="props.save_button" :next_button="props.next_button" small />
   </div>
</template>

<script setup>
   import { ref, defineEmits, defineProps, onMounted } from 'vue';
   import { useRouter } from 'vue-router'
   import { userProfileStore } from '@/stores/profile';
   import { RestGet } from '@/plugins/webapi';
   import SelectorTemplate from './SelectorTemplate';

   const profile = userProfileStore();
   const router = useRouter();

   const props = defineProps ({modelValue: [Object], save_button: [Boolean], next_button: [Boolean]});

   var buttons = ref ([]);
   const ingredient_type_icon = require (`@/assets/icons/misc/brush.png`);
   var text = ref (["Pick one of the colours shown below. All colours are natural in origin, which should have a minimal effect on potential irritants."]);

   onMounted(() =>
   {
      getColors();
   })

   const emit = defineEmits(['save', 'change']);
   const change = function (obj) { emit ('change', obj); }
   const save = function (obj) { emit ('save', obj); }

   const getColors = function()
   {
      RestGet ("/ingredient/code/CL", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            buttons.value = [];

            buttons.value.push ({id: -1, name: "No Colour", description: "Do not add any colors to my product.", icon: require (`@/assets/icons/misc/no.png`)});

            for (var x= 0; x < response.length; x++)
            {
               var icon = response[x].icon;

               buttons.value.push ({id: response[x].id,
                                 name: response[x].name,
                                 description: response[x].tooltip, 
                                 icon: icon,
                                 image: require (`@/assets/icons/misc/${icon}`)});
            }
         }
      });
   }
</script>

<style scoped>
.selector-title
{
   font-size: .8em;
   font-weight: bold;
   font-variant: small-caps;
}
</style>
