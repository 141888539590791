<template>
   <div id="information-box" class="navigation-item-information-box"></div>
   <div class="navigation-menu">
      <div class="navigation-menu-title">Craft Your Own</div>

      <div v-for="product in products" :key="product.id">
         <div v-if="props.inactive">
            <div :id="'craft_menuitem_'+product.id" class="inactive-navigation-menu-item" @mouseover="productDetails ('show', 'craft_menuitem_', product.id)" @mouseout="productDetails ('hide', 'craft_menuitem_', product.id)">
               <div class="navigation-icon"><img :id="'craft_menuitem_icon_' + product.id" :src="product.image"></div>
               <div class="navigation-text">{{product.name}}</div>
            </div>
         </div>
         <div v-else>
            <router-link :to="'/product/' + product.id">
               <div :id="'craft_menuitem_'+product.id" class="navigation-menu-item" @mouseover="productDetails ('show', 'craft_menuitem_', product.id)" @mouseout="productDetails ('hide', 'craft_menuitem_', product.id)">
                  <div class="navigation-icon"><img :id="'craft_menuitem_icon_' + product.id" :src="product.image"></div>
                  <div class="navigation-text">{{product.name}}</div>
               </div>
            </router-link>
         </div>
      </div>

      <hr />

      <div class="navigation-menu-title">Our Store</div>
      <div v-for="product in products" :key="product.id">
         <div v-if="props.inactive">
            <div :id="'store_menuitem_'+product.id" class="inactive-navigation-menu-item" @mouseover="productDetails ('show', 'store_menuitem_', product.id)" @mouseout="productDetails ('hide', 'store_menuitem_', product.id)">
               <div class="navigation-icon"><img :id="'store_menuitem_icon_' + product.id" :src="product.image"></div>
               <div class="navigation-text">{{product.name}}</div>
            </div>
         </div>
         <div v-else>
            <router-link :to="'/store/' + product.id">
               <div :id="'store_menuitem_'+product.id" class="navigation-menu-item" @mouseover="productDetails ('show', 'store_menuitem_', product.id)" @mouseout="productDetails ('hide', 'store_menuitem_', product.id)">
                  <div class="navigation-icon"><img :id="'store_menuitem_icon_' + product.id" :src="product.image"></div>
                  <div class="navigation-text">{{product.name}}</div>
               </div>
            </router-link>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ref, defineProps, onMounted } from 'vue'
   import { userProfileStore } from '@/stores/profile'
   import { RestGet } from '@/plugins/webapi'
   import { useRouter } from 'vue-router'

   const products = ref ([]);
   const profile = userProfileStore();
   const router = useRouter();

   //
   // This reads properties sent to us. In this case "inactive" will turn off all the items
   // We do this during onboarding and ordering.
   //
   const props = defineProps({inactive: Boolean});

   onMounted(() =>
   {
      getProducts();
   })

   const getProducts = function ()
   {
      RestGet ("/product/type/", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            products.value = response;
            for (var x = 0; x < products.value.length; x++)
            {
               var icon = products.value[x].icon;
               products.value[x].image = require (`@/assets/icons/misc/${icon}`);
               products.value[x].is_available = products.value[x].is_available == 1 ? true : false;
            }
         }
       });
   }

   const productDetails = function (action, lead, id)
   {
      const infobox = document.getElementById ("information-box");

      //
      // If the menu was set as inactive, we wont show product information
      //
      if (props.inactive)
      {
         return;
      }

      //
      // Hide the information box.
      //
      if (action == "hide")
      {
         infobox.style.opacity = 0;
         infobox.style.zIndex = -1;

         const el_icon = document.getElementById (lead + "icon_" + id);
         if (el_icon)
         {
            el_icon.style.opacity = .6;
         }

         return;
      }

      //
      // Show the information box.
      //
      const el = document.getElementById(lead + id);
      if (!el)
      {
         return;
      }

      const el_icon = document.getElementById (lead + "icon_" + id);
      if (el_icon)
      {
         el_icon.style.opacity = 1;
      }

      var rect = el.getBoundingClientRect();
      let top = rect.top;
      let left = rect.width + 20;
      infobox.style.zIndex = 5;
      infobox.style.left = left + "px";
      infobox.style.top = top + "px";
      infobox.style.opacity = 1;

      var product = {};
      for (var x=0; x < products.value.length; x++)
      {
         if (products.value[x].id == id)
         {
            product = products.value[x];
         }
      }

      if (product.tooltip)
      {
         infobox.textContent = product.tooltip;
      }
      else
      {
         infobox.textContent = "Product has no description.";
      }
   }
</script>

<style scoped>
   @import '@/assets/fonts/fontawesome6/css/all.css';
   @import '@/assets/fonts/DYOS/style.css';

   .navigation-menu
   {
      min-width: 20%;
      max-width: 20%;
      height: 100vh;
      padding-top: 10px;
   }
   .navigation-menu:hover
   {
      border-right: 1px solid var(--header-border-color);
   }
   .navigation-menu-title
   {
      padding-right: 3px;
      padding-left: 3px;
      margin-left: 57px;
   }
   .navigation-menu-item
   {
      display: flex;
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
   }
   .navigation-menu-item:hover
   {
      background-color: var(--menu-item-hover-color);
      border-radius: 5px;
      cursor: pointer;
   }

   .inactive-navigation-menu-item
   {
      display: flex;
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
      color: var(--bluegray-500);
   }
   .inactive-navigation-menu-item:hover
   {
      background-color: var(--menu-item-hover-color);
      border-radius: 5px;
      cursor: not-allowed
   }

   .navigation-icon
   {
      height: 30px;
      border-radius: 5px;
      background: #545098;
      color: var(--text-color);
      border: 2px solid #484482;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 2px 3px 2px 3px;
      padding: 2px 10px 2px 10px;
   }

   .navigation-icon img
   {
      height: 20px;
      width: 20px;
      opacity: .6;
   }

   .navigation-item-information-box
   {
      position: absolute;
      top: 0px;
      left: 0px;
      height: auto;
      width: fit-content;
      max-width: 500px;
      z-index: 5;
      opacity: 0;
      padding: 5px;
      font-size: .8em;
      padding-right: 10px;
      padding-left: 10px;
      transition: all 0.5s ease-in-out;
      background-color: var(--menu-item-hover-color);
      border: 1px solid var(--header-border-color);
      border-right: 5px solid var(--header-border-color);
      border-left: 5px solid var(--header-border-color);
      border-radius: 10px;
   }
</style>
