<template>
   <div class="card card-large">
      <div class="selector-title">Vegan and Nut Free Selector</div>

      <SelectorTemplate :buttons=buttons :icon="ingredient_type_icon" :text=text v-on:change="change" v-on:save="save" :save_button="props.save_button" :next_button="props.next_button" small />
   </div>
</template>

<script setup>
   import { ref, defineEmits, defineProps } from 'vue';
   import SelectorTemplate from "./SelectorTemplate"

   const props = defineProps ({save_button: [Boolean], next_button: [Boolean]});
   const ingredient_type_icon = require (`@/assets/icons/ingredient/type/18.png`);
   const buttons = ref ([{name: "Regular", value: "regular", description: "I have no preference for the oil base.", icon: "dyos2.png", image: require ('@/assets/icons/misc/dyos2.png')},
                         {name: "Fruit Free", value: "fruitfree", description: "I can not have any type of fruits in my products.", icon: "fruit-free.png", image: require ('@/assets/icons/misc/fruit-free.png')},
                         {name: "Nut Free", value: "nutfree", description: "I can not have any type of nut product in my products.", icon: "nut-free.png", image: require (`@/assets/icons/misc/nut-free.png`)},
                         {name: "Fruit + Nut Free", value: "fruitnutfree", description: "I can not have any type of nut or fruit product in my products.", icon: "fruit-nut-free.png", image: require (`@/assets/icons/misc/nut-fruit-free.png`)},
                         {name: "Vegan", value: "vegan", description: "I need a vegan product.", icon: "carrot.png", image: require (`@/assets/icons/misc/carrot.png`)}]);
   const text = ref (["We offer vegan and nut-free base oil options for our customers who want or need these considerations.\
                       If vegan or nut-free has been selected, then all options moving forward will respect that selection.",
                       "If you do not need or want a vegan or nut-free option, then just select 'Regular'"]);

   const emit = defineEmits(['save', 'change']);
   const change = function (obj) { emit ('change', obj); }
   const save = function (obj) { emit ('save', obj); }
</script>

<style scoped>
hr {margin: 15px 0px 10px 0px;}
.selector-description {font-size: .8em;}
.saveButton 
{
   width: 500px !important;
   background: orange;
}
.selector-title
{
   font-size: .8em;
   font-weight: bold;
   font-variant: small-caps;
}
</style>
