<template>
   <div class="page">
      <LeftNavigation />

      <div class="content content-top">
TOUR PAGE
      </div>
   </div>
</template>

<script setup>
   import { onMounted } from "vue";
   import LeftNavigation from './LeftNavigation.vue'
   //import { userProfileStore } from '@/stores/profile';
   import { useRoute } from 'vue-router'

   //const profile = userProfileStore();
   const route = useRoute();

   onMounted (() =>
   {
      //
      // This is a hacky way to force a page refresh.
      //
      if (route.query.fresh)
      {
         window.location.replace ("/home");
      }
   })
</script>

<style>

</style>
