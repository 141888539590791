import { createApp } from 'vue'
import App from './App.vue'
import routes from "./routes"

import { createPinia } from 'pinia'

import {initializeApp} from "firebase/app"
import { getFirestore } from "firebase/firestore";

import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'

const firebaseConfig = {
  apiKey: "AIzaSyCy2OU4xh8UjGUj3K1Ac1or9kq8YhRcgmc",
  authDomain: "design-your-own-soap.firebaseapp.com",
  projectId: "design-your-own-soap",
  storageBucket: "design-your-own-soap.appspot.com",
  messagingSenderId: "421223905544",
  appId: "1:421223905544:web:845863372485893c0d14bd",
  measurementId: "G-GFT5W4J1RR"
};

const fbapp = initializeApp (firebaseConfig);
getFirestore(fbapp);

const app = createApp (App)

const pinia = createPinia()

app.use (pinia)
app.use (routes)
app.use(PrimeVue, {inputStyle: 'filled'});
app.use(ToastService);

app.component('ToastTip', Toast);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);

//
// This is the name of the local storage name for session management
//
app.provide('session', 'lastUserAction');

app.mount('#app')
