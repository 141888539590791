<template>
   <div class="card card-large">
      <h1 v-if="! no_header">Icon Selector</h1>

      <SelectorTemplate :buttons=buttons :icon="default_icon" :default="default_button_list" :text=text v-on:change="change" v-on:save="save" :save_button="props.save_button" :next_button="props.next_button" :no_header=props.no_header scroll=300 small :key=updateSelector />
   </div>
</template>

<script setup>
   import { ref, defineEmits, defineProps, onMounted } from 'vue';
   import SelectorTemplate from './SelectorTemplate';

   const props = defineProps ({icon: [String], default: [Object], save_button: [Boolean], next_button: [Boolean], ok_button: [Boolean], no_header: [Boolean], no_text: [Boolean]});

   var buttons = ref ([]);
   var default_icon = "brush.png";
   var text = ref (["Select an Icon"]);
   var default_button_list = ref ([]);
   var updateSelector = ref (0);

   onMounted(() =>
   {
      init();
   })

   const emit = defineEmits(['save', 'change']);
   const change = function (obj) { emit ('change', obj); }
   const save = function (obj) { emit ('save', obj); }

   const init = function ()
   {
      loadImages (require.context ('@/assets/icons/misc/', true, /\.png$/));

      if (props.default)
      {
         default_button_list.value = [];
         for (var x = 0; x < props.default.length; x++)
         {
            for (var y = 0; y < buttons.value.length; y++)
            {
console.log ("XXXXXXXXXX:", props.default[x], buttons.value[y].filename);
               if (props.default[x] == buttons.value[y].filename)
               {
                  default_button_list.value.push (y);
                  break;
               }
            }
         }

         updateSelector.value++;
      }

      if (props.icon)
      {
        default_icon = props.icon;
      }
   }

   const loadImages = function (file_list)
   {
      var x = 0;

      file_list.keys().forEach (key =>
      {
         var basename = key.slice (2);
         var rootname = basename.slice (0, -4);
         var desc = rootname[0].toUpperCase() + rootname.slice(1);

         buttons.value[x++] = {id: x, label: desc, description: desc, filename: basename, icon: basename, image: require ('@/assets/icons/misc/' + basename)};
      });
   }

</script>

<style scoped>
   .scroll-icons
   {
      height: 300px;
      max-height: 300px;
      overflow-y: scroll;
   }
</style>
