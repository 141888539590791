<template>
   <div class="card card-large">
      <div class="selector-title">Exfoliant Selector</div>

      <SelectorTemplate :buttons=buttons :icon="ingredient_type_icon" :text=text v-on:change="change" v-on:save="save" :save_button="props.save_button" :next_button="props.next_button" :button_state="button_state" small />

      <div v-if="show" class="container container-left container-full button-container">
         <ButtonGroup :buttons=ingredient_buttons :key="buttonGroupKey" v-on:change="turnOnButton" small />
      </div>
   </div>
</template>

<script setup>
   import { ref, defineEmits, defineProps, onMounted } from 'vue';
   import { RestGet } from '@/plugins/webapi';
   import { useRouter } from 'vue-router'
   import { userProfileStore } from '@/stores/profile';
   import SelectorTemplate from "./SelectorTemplate";
   import ButtonGroup from "../ButtonGroup";

   const profile = userProfileStore();
   const router = useRouter();

   const show = ref (false);
   const button_state = ref (false);
   const props = defineProps ({save_button: [Boolean], next_button: [Boolean]});
   const ingredient_type_icon = require (`@/assets/icons/misc/mitten.png`);
   const text = ref (["Exfoliating is the process of removing dead skin cells from the surface of your skin using a chemical, granular substance, or exfoliation tool.",
                      "Your skin naturally sheds dead skin cells to make room for new cells every 30 days or so.",
                      "Sometimes, dead cells don’t shed completely. This can result in dry, flaky patches and clogged pores. Exfoliating can help prevent this."]);
   var ingredient_type_id = ref (0);
   var ingredient_buttons = ref ([]);
   var buttons = ref ([]);
   var buttonGroupKey = ref (0);

   onMounted(() =>
   {
      getExfoliators();
   })

   const emit = defineEmits(['save', 'change']);
   const save = function (obj) { emit ('save', obj); }
   const turnOnButton = function () {console.log ("TURN ON"); button_state.value = true;}

   const change = function (obj)
   {
      if (obj)
      {
         show.value = true;
         button_state.value = false;

         emit ('change', obj);

         getIngredients (obj.id);
      }
   }

   const getExfoliators = function()
   {
      RestGet ("/ingredient/subtype/code/EX", profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            buttons.value = [];
            for (var x= 0; x < response.length; x++)
            {
               var rec = response[x];
               var icon = rec.icon;

               ingredient_type_id.value = rec.ingredient_type_id;

               buttons.value.push ({name: rec.name, description: rec.description, id: rec.id, image: require (`@/assets/icons/misc/${icon}`)});
            }
         }
      });
   }

   const getIngredients = function (id)
   {
      var uri = "/ingredient/subtype/" + id;

      RestGet (uri, profile.token(), {}, function (status, response)
      {
         if (status.timeout)
         {
            router.push ("/logout?type=timeout");
            return;
         }
         else if (status.disabled)
         {
            router.push ("/logout?type=disabled");
            return;
         }

         if (status.ok)
         {
            ingredient_buttons.value = [];
for (var y=0; y < 3; y++)
{
            for (var x= 0; x < response.length; x++)
            {
               var rec = response[x];
               var icon = rec.icon;

               ingredient_buttons.value.push ({name: rec.name, description: rec.description, id: rec.id, image: require (`@/assets/icons/misc/${icon}`)});
            }
}

            buttonGroupKey.value++;
         }
      });
   }
</script>

<style scoped>
hr {margin: 15px 0px 10px 0px;}
.selector-description {font-size: .8em;}
.selector-title
{
   font-size: .8em;
   font-weight: bold;
   font-variant: small-caps;
}
.button-container
{
   border-top: 1px solid gray;
   padding: 0px;
}
</style>
