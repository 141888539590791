<template>
   <ToastTip />

   <div class="page">
      <AdminNavigation />

      <div class="content content-top">
ORER MANAGER
      </div>
   </div>
</template>

<script setup>
   import { ref, onMounted } from "vue";
   import { useToast } from "primevue/usetoast";

   const toast = useToast();
   const orders = ref ();

   onMounted(() =>
   {
      getOrders();
   })

   const getOrders = function ()
   {
      var requestOptions = {method: "GET",
                            headers: { "Content-Type": "application/json" }};
      var uri = "http://localhost:9000/orders/";

      orders.value = {};

      fetch (uri, requestOptions)
           .then (function (response) { return response.json()})
           .then (function (data)
           {
              if (data.status_code == 200)
              {
                 if (data.data.length > 0)
                 {
                    orders.value = JSON.parse (data.data);
                 }
                 return;
              }

              
              toast.add ({severity:'error', summary: 'Order Data', detail: data.message, life: 5000});
              console.error (data);
          });
   }
</script>

<style>
</style>
